import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { ComplianceDocType } from './slice';
import { errorToast } from '@/utils/toastMessage';

export interface ComplianceDocs {
  countryCode: string;
  complianceDocVersion: string;
  complianceDocType: string;
  complianceDocUrl: string;
}

export const addTermsAndConditions = createAsyncThunk(
  'complianceDocs.termsAndConditions',
  async ({ body }: { body: ComplianceDocs }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.post<{ data: ComplianceDocs }>('/compliancedocs', { ...body });
      return data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const addPrivacyPolicy = createAsyncThunk(
  'complianceDocs.privacyPolicy',
  async ({ body }: { body: ComplianceDocs }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.post<{ data: ComplianceDocs }>('/compliancedocs', { ...body });
      return data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const getComplianceDocs = createAsyncThunk(
  'complianceDocs.complianceDocs',
  async ({ countryCode }: { countryCode: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<{ data: ComplianceDocs[] }>(`compliancedocs/${countryCode}`);
      const termsAndCondition = data.data.find(item => item.complianceDocType === ComplianceDocType.TermsAndConditions);
      const policy = data.data.find(item => item.complianceDocType === ComplianceDocType.PrivacyPolicy);
      return { termsAndCondition, policy };
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
