import styles from '@/components/customer/transaction-history/filters/TransactionHistoryFilters.module.scss';
import FilterChip from '@/components/common/chip/Chip';
import {
  useCountryFilter,
  useCustomerIdFilter,
  useDateRangeFilter,
  usePhoneNumberFilter,
  usePspTransactionIdFilter,
  useSlipIdFilter,
  useTransactionIdFilter,
  useTransactionStatusFilter,
  useTransactionTypeFilter,
} from '@/components/customer/transaction-history/filters/filterHooks';
import { useTranslation } from 'react-i18next';
import countries from '@/i18n/countriesConfig';
import i18n from '@/i18n/config';
export default function TransactionHistoryFilterChips(): JSX.Element {
  const [transactionIdFilter, setTransactionIdFilter] = useTransactionIdFilter();
  const [pspTransactionIdFilter, setPspTransactionIdFilter] = usePspTransactionIdFilter();
  const [slipIdFilter, setSlipIdFilter] = useSlipIdFilter();
  const [transactionTypes, setTransactionTypes] = useTransactionTypeFilter();
  const [transactionStatuses, setTransactionStatuses] = useTransactionStatusFilter();
  const [dates, setDates] = useDateRangeFilter();
  const [country, setCountry] = useCountryFilter();
  const [phoneNumber, setPhoneNumber] = usePhoneNumberFilter();
  const [customerId, setCustomerId] = useCustomerIdFilter();

  const { t } = useTranslation(['customerOverview', 'global']);
  return (
    <div className={styles.filterItems}>
      {country && (
        <FilterChip
          label={
            t('widgets.transaction-history.filters.country-id-label') + ': ' + countries.getName(country, i18n.language)
          }
          onDelete={() => setCountry('')}
        />
      )}
      {phoneNumber && (
        <FilterChip
          label={t('widgets.transaction-history.filters.phone-number-label') + ': ' + phoneNumber}
          onDelete={() => setPhoneNumber('')}
        />
      )}
      {customerId && (
        <FilterChip
          label={t('widgets.transaction-history.filters.customer-id-label') + ': ' + customerId}
          onDelete={() => setCustomerId('')}
        />
      )}
      {transactionTypes.map(item => (
        <FilterChip
          key={item}
          label={t('widgets.transaction-history.filters.transaction-type-label') + ': ' + item}
          onDelete={() => setTransactionTypes(transactionTypes.filter(x => x !== item))}
        />
      ))}
      {dates[0] && (
        <FilterChip
          label={t('widgets.transaction-history.filters.time-range-from-label') + ': ' + dates[0]}
          onDelete={() => setDates(['', dates[1]])}
        />
      )}
      {dates[1] && (
        <FilterChip
          label={t('widgets.transaction-history.filters.time-range-to-label') + ': ' + dates[1]}
          onDelete={() => setDates([dates[0], ''])}
        />
      )}
      {transactionStatuses.map(item => (
        <FilterChip
          key={item}
          label={t('widgets.transaction-history.filters.transaction-status-label') + ': ' + item}
          onDelete={() => setTransactionStatuses(transactionStatuses.filter(x => x !== item))}
        />
      ))}
      {transactionIdFilter && (
        <FilterChip
          label={t('widgets.transaction-history.filters.transaction-id-label') + ': ' + transactionIdFilter}
          onDelete={() => setTransactionIdFilter('')}
        />
      )}
      {slipIdFilter && (
        <FilterChip
          label={t('widgets.transaction-history.filters.slip-id-label') + ': ' + slipIdFilter}
          onDelete={() => setSlipIdFilter('')}
        />
      )}
      {pspTransactionIdFilter && (
        <FilterChip
          label={t('widgets.transaction-history.filters.psp-transaction-id-label') + ': ' + pspTransactionIdFilter}
          onDelete={() => setPspTransactionIdFilter('')}
        />
      )}
    </div>
  );
}
