import { useRef, useEffect } from 'react';
import { marine } from '@/utils/colors';

const HighlightingLabel = ({ label }: { label?: string }): JSX.Element => {
  const ref = useRef() as React.MutableRefObject<HTMLSpanElement>;

  useEffect(() => {
    if (label) {
      ref.current.style.color = marine.cssColor;
      const timeout = setTimeout(() => {
        ref.current.style.color = 'initial';
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [label]);

  return <span ref={ref}>{label}</span>;
};

export default HighlightingLabel;
