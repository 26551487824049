import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { type Permissions } from '@/types';
import DebounceCache from '@/utils/cache/DebounceCache';
import { type AxiosResponse } from 'axios';
interface UserPermissionsParams {
  permission: Permissions;
}

export interface UserPermissionsResponse {
  permission: Permissions;
  success: boolean;
}

const cache = new DebounceCache<Permissions, AxiosResponse<{ data: UserPermissionsParams }>>();

export const getUserPermissions = createAsyncThunk<
  UserPermissionsResponse,
  UserPermissionsParams,
  { rejectValue: any }
>('userPermissions/getUserPermissions', async ({ permission }: UserPermissionsParams) => {
  try {
    const response = await cache.getResult(
      permission,
      async () =>
        await authAxios.get<{ data: UserPermissionsParams }>('/boiam/users/user-has-permission', {
          headers: { 'x-bo-permission': permission },
        }),
    );
    const isSuccess = response.status === 200;

    return { permission, success: isSuccess };
  } catch (err: any) {
    return { permission, success: false };
  }
});
