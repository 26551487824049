import { walletRefunds } from './action';
import { createSlice } from '@reduxjs/toolkit';

interface WalletRefundsState {
  loading: boolean;
  success: boolean;
  error?: string;
}

const initialState: WalletRefundsState = {
  loading: false,
  success: false,
};

export const walletRefundsSlice = createSlice({
  name: 'walletRefunds',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(walletRefunds.pending, state => {
      state.success = false;
      state.loading = true;
      state.error = undefined;
    });

    builder.addCase(walletRefunds.fulfilled, state => {
      state.success = true;
      state.loading = false;
    });

    builder.addCase(walletRefunds.rejected, (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = action.error?.message ?? 'Failed adjustment details';
    });
  },
});

export const { reset } = walletRefundsSlice.actions;
