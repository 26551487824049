import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import globalEn from './locales/en/global.json';
import documentReviewQueueEn from './locales/en/document-review-queue.json';
import businessAccountsEn from './locales/en/business-accounts.json';
import customerOverviewEn from './locales/en/customer-overview.json';
import accessManagementEn from './locales/en/access-management.json';
import configurationsEn from './locales/en/configurations.json';

import globalEs from './locales/es/global.json';
import documentReviewQueueEs from './locales/es/document-review-queue.json';
import businessAccountsEs from './locales/es/business-accounts.json';
import customerOverviewEs from './locales/es/customer-overview.json';
import accessManagementEs from './locales/es/access-management.json';
import configurationsEs from './locales/es/configurations.json';

import globalPt from './locales/pt/global.json';
import documentReviewQueuePt from './locales/pt/document-review-queue.json';
import businessAccountsPt from './locales/pt/business-accounts.json';
import customerOverviewPt from './locales/pt/customer-overview.json';
import accessManagementPt from './locales/pt/access-management.json';
import configurationsPt from './locales/pt/configurations.json';

export const resources = {
  en: {
    global: globalEn,
    documentReviewQueue: documentReviewQueueEn,
    businessAccounts: businessAccountsEn,
    customerOverview: customerOverviewEn,
    accessManagement: accessManagementEn,
    configurations: configurationsEn,
  },
  es: {
    global: globalEs,
    documentReviewQueue: documentReviewQueueEs,
    businessAccounts: businessAccountsEs,
    customerOverview: customerOverviewEs,
    accessManagement: accessManagementEs,
    configurations: configurationsEs,
  },
  pt: {
    global: globalPt,
    documentReviewQueue: documentReviewQueuePt,
    businessAccounts: businessAccountsPt,
    customerOverview: customerOverviewPt,
    accessManagement: accessManagementPt,
    configurations: configurationsPt,
  },
};

export const defaultNs = 'global';

void i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    defaultNS: defaultNs,
    fallbackNS: defaultNs,
    detection: { order: ['localStorage'], lookupLocalStorage: 'i18nextLng', caches: ['localStorage'] },
  });

export default i18n;
