import { useMemo } from 'react';
import ImagesBox from '@/components/customer/documents/id-docs/ImagesBox';
import { type RootState, useAppSelector } from '@/lib/redux';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';

export default function SelfiePage(): React.JSX.Element | null {
  const { customer } = useAppSelector((state: RootState) => state.customer);
  const { identityDocs } = useAppSelector((state: RootState) => state.identityDocs);
  const { t } = useTranslation('documentReviewQueue');

  useDocumentTitle(
    customer && identityDocs
      ? t('document-review-page.page-title-selfie', {
        customerFullName: `${customer.firstName} ${customer.lastName}`,
      })
      : '',
  );

  const images = useMemo(() => {
    if (!identityDocs) {
      return [];
    } else {
      return [
        {
          alt: t('document-review-page.selfie-img'),
          url: `/identity-docs/approved/${identityDocs.identityDocId}/${identityDocs.selfieImageId}`,
          config: {
            headers: {
              'x-customer-id': customer?.customerId,
            },
          },
        },
      ];
    }
  }, [customer?.customerId, identityDocs, t]);

  if (customer?.customerId && identityDocs?.selfieImageId) {
    return <ImagesBox images={images} />;
  } else {
    return null;
  }
}
