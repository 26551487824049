import { useRef, type ChangeEvent } from 'react';
import TransparentCrossIcon from '@/components/icons/TransparentCrossIcon';
import styles from './TextArea.module.scss';

export interface TextAreaProps {
  value: string;
  onChange: (newValue: string) => void;
  rows?: number;
  minLength?: number;
  maxLength?: number;
}

export default function TextArea({
  value,
  onChange,
  minLength,
  maxLength,
  rows = 1,
}: Readonly<TextAreaProps>): React.JSX.Element {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClearInput: (event: React.MouseEvent) => void = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onChange('');
  };

  return (
    <div
      ref={wrapperRef}
      className={styles.wrapper}
    >
      <textarea
        rows={rows}
        className={styles.textArea}
        value={value}
        minLength={minLength}
        maxLength={maxLength}
        onChange={(e: ChangeEvent) => onChange((e.target as HTMLInputElement).value)}
        onFocus={() => {
          wrapperRef.current?.classList.add(styles['wrapper--focused']);
        }}
        onBlur={() => {
          wrapperRef.current?.classList.remove(styles['wrapper--focused']);
        }}
      />
      <button
        className={styles['clear-icon']}
        onClick={handleClearInput}
        onMouseDown={event => event.preventDefault()}
      >
        <TransparentCrossIcon />
      </button>
    </div>
  );
}
