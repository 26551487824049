import classNames from 'classnames';
import type { Color } from '@/utils/colors';

import styles from './Logo.module.scss';

function Logo(props: Readonly<{ color: Color }>): React.JSX.Element {
  const logoClasses = classNames(styles.logo, styles[`logo${props.color.modifier}`]);

  return (
    <svg
      className={logoClasses}
      viewBox='0 0 89 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37.1094 6.52642H34.5921C34.2054 6.52642 33.9468 6.79227 33.9468 7.18505V15.4263C33.9468 17.6178 32.5945 18.9805 30.4196 18.9805C28.2446 18.9805 27.2101 17.7183 27.2101 15.4263V7.18745C27.2101 6.79227 26.9515 6.52882 26.5648 6.52882H24.0154C23.6287 6.52882 23.3676 6.79467 23.3676 7.18745V16.049C23.3676 20.0104 25.6066 22.4725 29.2077 22.4725C31.7004 22.4725 33.1512 21.3228 33.9788 20.178L34.0428 21.4258C34.0699 21.7396 34.1709 22.1012 34.7202 22.1012H37.1094C37.4838 22.1012 37.7892 21.8066 37.7892 21.4402V7.18745C37.7892 6.82341 37.4863 6.52882 37.1119 6.52882L37.1094 6.52642Z'
        fill='#00EAD0'
      />
      <path
        d='M71.5586 0.0215552H69.0093C68.6226 0.0215552 68.3615 0.287402 68.3615 0.68258V8.38735C67.1497 6.924 65.3811 6.1552 63.2087 6.1552C58.7356 6.1552 55.4892 9.58486 55.4892 14.3126C55.4892 19.0404 58.7356 22.4701 63.2087 22.4701C65.4821 22.4701 67.3122 21.6294 68.5389 20.0319L68.5832 21.4378C68.5832 21.833 68.8443 22.0964 69.231 22.0964H71.5562C71.9429 22.0964 72.204 21.8306 72.204 21.4378V0.680185C72.204 0.285007 71.9429 0.0191601 71.5562 0.0191601L71.5586 0.0215552ZM68.396 14.3438C68.396 17.1364 66.5807 19.0141 63.8811 19.0141C61.1815 19.0141 59.2381 17.0813 59.2381 14.3126C59.2381 11.544 61.147 9.61121 63.8811 9.61121C66.6152 9.61121 68.396 11.5129 68.396 14.3414V14.3438Z'
        fill='#00EAD0'
      />
      <path
        d='M88.3251 6.52642H85.8078C85.4211 6.52642 85.16 6.79227 85.16 7.18505V15.4263C85.16 17.6178 83.8077 18.9805 81.6328 18.9805C79.4579 18.9805 78.4234 17.7183 78.4234 15.4263V7.18745C78.4234 6.79227 78.1647 6.52882 77.778 6.52882H75.2287C74.842 6.52882 74.5809 6.79467 74.5809 7.18745V16.049C74.5809 19.9098 76.7607 22.3096 80.4136 22.4701C81.9924 22.5395 83.7757 22.415 85.1945 21.0474V22.1348C85.1945 24.7693 83.559 26.3404 80.82 26.3404C79.0564 26.3404 78.1229 25.6794 77.2977 25.095L77.2755 25.0806C76.9948 24.8435 76.5908 24.8603 76.3272 25.1285L74.7952 26.8434C74.5119 27.1236 74.5095 27.478 74.7976 27.8038C75.8494 28.8049 77.7657 30 80.9234 30C85.9802 30 89 27.0374 89 22.0749V7.18745C89 6.82341 88.697 6.52882 88.3226 6.52882L88.3251 6.52642Z'
        fill='#00EAD0'
      />
      <path
        d='M54.5188 19.9553C54.5188 19.7182 54.3636 19.5074 54.132 19.426C53.6222 19.244 53.3759 18.8943 53.3759 18.1375V12.0781C53.3759 10.1716 52.765 8.68913 51.5581 7.67603C50.361 6.67013 48.7871 6.15999 46.8781 6.15999C44.9692 6.15999 43.4421 6.66534 42.277 7.66166C41.2056 8.57896 40.5528 9.7621 40.3386 11.1799C40.3164 11.3308 40.3607 11.4865 40.4642 11.6015C40.5676 11.7164 40.7154 11.7835 40.8731 11.7835H43.3928C43.6465 11.7835 43.8657 11.6087 43.9199 11.3668C44.2697 9.83395 45.2402 9.0891 46.8806 9.0891C47.8067 9.0891 48.526 9.33818 49.0186 9.82676C49.5137 10.3177 49.7625 11.0339 49.7625 11.9535V12.5403L45.1293 12.981C43.4987 13.1271 42.1711 13.6157 41.1785 14.4348C40.1711 15.2658 39.6587 16.4179 39.6587 17.8597C39.6587 19.3015 40.1834 20.5253 41.2154 21.3037C42.2327 22.0725 43.5776 22.4629 45.2106 22.4629C47.4718 22.4629 49.2157 21.7587 50.4053 20.3672C50.9792 21.6581 52.1739 22.209 53.9596 22.209C53.9596 22.209 53.9621 22.209 53.9646 22.209C54.27 22.209 54.5138 21.9599 54.5138 21.6605V19.9529L54.5188 19.9553ZM49.7797 16.0179C49.7797 17.225 49.3856 18.0896 48.5752 18.6668C47.755 19.2488 46.7919 19.5434 45.7131 19.5434C44.0948 19.5434 43.3091 18.9278 43.3091 17.6633C43.3091 17.0118 43.5603 16.5137 44.0751 16.1424C44.6072 15.7592 45.3436 15.5245 46.2673 15.4479L49.7797 15.103V16.0179Z'
        fill='#00EAD0'
      />
      <path
        d='M19.2863 22.0893H21.5203C21.9587 22.0893 22.2987 21.7156 22.2494 21.2917C21.7617 17.2369 19.8774 13.5965 17.0768 10.8351C19.2025 7.97062 20.5153 4.50024 20.6927 0.740061C20.7124 0.337698 20.3774 0 19.9611 0H17.7099C17.3182 0 16.998 0.299377 16.9783 0.680185C16.8207 3.59013 15.8206 6.27974 14.2098 8.52866C12.6407 7.50599 10.8993 6.71084 9.0372 6.19591V0.713715C9.0372 0.320932 8.7096 0 8.30319 0H6.00017C5.59622 0 5.26616 0.318537 5.26616 0.713715V5.77439C2.28578 6.20549 0 8.7035 0 11.7212C0 14.7389 2.28578 17.2369 5.26616 17.668V21.3755C5.26616 21.7683 5.59375 22.0893 6.00017 22.0893H8.30319C8.70714 22.0893 9.0372 21.7707 9.0372 21.3755V17.1172C11.121 16.2454 13.0127 15.0287 14.6408 13.539C16.7221 15.6658 18.1384 18.4177 18.5596 21.4737C18.6089 21.8282 18.9217 22.0893 19.2887 22.0893H19.2863ZM3.62572 11.7212C3.62572 10.6818 4.31046 9.79802 5.26616 9.47469V13.9677C4.31046 13.642 3.62572 12.7607 3.62572 11.7212ZM9.03473 9.96088C9.98057 10.2986 10.8821 10.7249 11.7245 11.235C10.8993 11.9416 10.0003 12.5667 9.03473 13.0936V9.96088Z'
        fill='#00EAD0'
      />
    </svg>
  );
}

export default Logo;
