import styled from 'styled-components';
import styles from './Button.module.scss';
import { type TextTransform } from '@/utils/interfaces';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'third';
  textTransform?: TextTransform;
}

const StyledButton = styled.button<{ $textTransform: TextTransform }>`
  text-transform: ${({ $textTransform }) => $textTransform};
`;

export default function Button({
  children,
  variant = 'primary',
  textTransform = 'none',
  className,
  ...rest
}: Readonly<ButtonProps>): React.JSX.Element {
  return (
    <StyledButton
      className={`${styles.button} ${styles[variant]} ${className ?? ''}`}
      {...rest}
      $textTransform={textTransform}
    >
      {children}
    </StyledButton>
  );
}
