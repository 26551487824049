import { lightGrey, white } from '@/utils/colors';

export default function TransparentCrossIcon({
  width = `${10 / 16}rem`,
  height = `${10 / 16}rem`,
  color = white.cssColor,
  background = lightGrey.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
  background?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='5'
        cy='5'
        r='5'
        fill={background}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.57154 2.5716C2.74276 2.40037 3.02038 2.40037 3.19161 2.5716L7.49655 6.87654C7.66778 7.04777 7.66778 7.32538 7.49655 7.49661C7.32532 7.66784 7.04771 7.66784 6.87648 7.49661L2.57154 3.19167C2.40031 3.02044 2.40031 2.74283 2.57154 2.5716Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.80836 2.50342C6.97959 2.33219 7.25721 2.33219 7.42844 2.50342C7.59966 2.67465 7.59967 2.95226 7.42844 3.12349L3.12349 7.42844C2.95227 7.59966 2.67465 7.59966 2.50342 7.42844C2.33219 7.25721 2.33219 6.97959 2.50342 6.80836L6.80836 2.50342Z'
        fill={color}
      />
    </svg>
  );
}
