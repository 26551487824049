import BackButton from '@/components/common/button/implementations/BackButton';
import Spinner from '@/components/common/spinners/Spinner';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { fetchCardById } from '@/lib/redux/slices/customer-card-details/actions';
import useBackButtonCallback from '@/utils/hooks/useBackButtonCallback';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PhysicalCardView from './PhysicalCardView';
import VirtualCardView from './VirtualCardView';
import styles from './CardDetailsWidget.module.scss';

export default function CardDetailsWidget(): React.JSX.Element {
  const { cardId } = useParams<{ cardId: string }>();
  const { t } = useTranslation(['customerOverview', 'global']);
  const { card, isLoading, error } = useAppSelector(state => state.customerCardDetails);
  const customer = useAppSelector((state: RootState) => state.customer.customer);
  const dispatch = useAppDispatch();
  const goBackCb = useBackButtonCallback();

  useEffect(() => {
    if (customer?.customerId && cardId) {
      void dispatch(fetchCardById({ customerId: customer?.customerId, cardId }));
    }
  }, [dispatch, cardId, customer]);

  const detailsWidget = useMemo(() => {
    if (card) {
      const type = card.cardType.toLowerCase();
      if (type === 'physical') {
        return <PhysicalCardView card={card} />;
      } else if (type === 'virtual') {
        return <VirtualCardView />;
      }
    }
    return null;
  }, [card]);

  return (
    <>
      <div className={styles.buttons}>
        <BackButton onClick={goBackCb}>{t('global:buttons.back')}</BackButton>
      </div>
      {isLoading && <Spinner />}
      {!isLoading && !error && detailsWidget}
    </>
  );
}
