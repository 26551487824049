import React, { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '@/components/layout/Layout';
import Spinner from '@/components/common/spinners/Spinner';
import { type RootState, useAppDispatch } from '@/lib/redux';
import { reset } from '@/lib/redux/slices/customer/slice';
import { fetchCustomer } from '@/lib/redux/slices/customer/actions';
import { AppUrls } from '@/utils/constants';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';

function CustomerLayout(): React.JSX.Element {
  const { loading, customer } = useSelector((state: RootState) => state.customer);
  const { customerId } = useParams();
  const dispatch = useAppDispatch();
  const hasCustomer = !loading && customer !== null && customer !== undefined;
  useDocumentTitle(hasCustomer ? `${customer.firstName} ${customer.lastName}` : '');

  useEffect(() => {
    if (customerId !== undefined) {
      void dispatch(fetchCustomer({ id: customerId }));
    }

    return () => {
      dispatch(reset());
    };
  }, [customerId, dispatch]);

  if (loading) {
    return <Spinner />;
  }

  if (!hasCustomer) {
    return <Navigate to={AppUrls.NotFoundUrl} />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default CustomerLayout;
