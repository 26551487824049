import authAxios from '@/auth/axios';
import { type UserPermission, type UserRole } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchRolesAndPermissions = createAsyncThunk('fetchRolesAndPermissions', async (_, { rejectWithValue }) => {
  try {
    const { data: rolesData } = await authAxios.get<{ data: { data: UserRole[]; total: number } }>(
      '/roles?includePermissions=true&limit=100&offset=0&includeTotal=true',
    );

    const { data: permissionsData } = await authAxios.get<{ data: { data: UserPermission[]; total: number } }>(
      '/permissions?limit=100&offset=0&includeTotal=true',
    );

    return {
      roles: rolesData.data.data ?? [],
      permissions: permissionsData.data.data ?? [],
    };
  } catch (err: any) {
    return rejectWithValue(err);
  }
});
