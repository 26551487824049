import styled from 'styled-components';

const StyledHorizontalLayout = styled.div<{ $cols?: number; $colsTemplate?: string; $gap?: string }>`
  display: grid;
  grid-template-columns: ${({ $colsTemplate, $cols }) => $colsTemplate ?? `repeat(${$cols}, auto)`};
  gap: ${({ $gap }) => $gap ?? 'initial'};
  width: 100%;

  @media only screen and (max-width: 961px) {
    grid-template-columns: 100%;
  }
`;

interface ColsType {
  cols: number;
  colsTemplate?: never;
  gap?: string;
  children: React.ReactNode;
}

interface ColsTemplateType {
  cols?: never;
  colsTemplate: string;
  gap?: string;
  children: React.ReactNode;
}

type TemplateProps = ColsType | ColsTemplateType;

const HorizontalLayout = ({ cols, colsTemplate, gap, children }: TemplateProps): React.JSX.Element => {
  return (
    <StyledHorizontalLayout
      $cols={cols}
      $colsTemplate={colsTemplate}
      $gap={gap}
    >
      {children}
    </StyledHorizontalLayout>
  );
};

export default HorizontalLayout;
