import { chargebackReversals } from './actions';
import { createSlice } from '@reduxjs/toolkit';

interface ChargebackReversalsState {
  success: boolean;
  isLoading: boolean;
  error?: string;
}

const initialState: ChargebackReversalsState = {
  isLoading: false,
  success: false,
};

export const chargebackReversalsSlice = createSlice({
  name: 'chargebackReversal',
  initialState,
  reducers: {
    resetChargebackReversals() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(chargebackReversals.pending, state => {
      state.isLoading = true;
      state.success = false;
    });

    builder.addCase(chargebackReversals.fulfilled, state => {
      state.success = true;
      state.isLoading = false;
    });

    builder.addCase(chargebackReversals.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed transfer details';
    });
  },
});

export const { resetChargebackReversals } = chargebackReversalsSlice.actions;
