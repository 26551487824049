import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Transaction } from '@/types';
import {
  fetchSingleTransaction,
  transactionAdjustment,
  type SingleTransactionResponse,
} from '@/lib/redux/slices/transaction-history-detail/actions';

interface SingleTransactionState {
  transaction?: Transaction;
  isLoading: boolean;
  error?: string;
  adjustmentSuccess?: boolean;
  adjustmentLoading: boolean;
}

const initialState: SingleTransactionState = {
  isLoading: false,
  adjustmentLoading: false,
};
export const singleTransactionHistorySlice = createSlice({
  name: 'detailTransactionHistorySlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSingleTransaction.pending, state => {
      state.isLoading = true;
      state.error = undefined;
    });

    builder.addCase(fetchSingleTransaction.fulfilled, (state, action: PayloadAction<SingleTransactionResponse>) => {
      state.isLoading = false;
      state.transaction = action.payload.transaction;
      state.error = undefined;
    });

    builder.addCase(fetchSingleTransaction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed to fetch transaction';
    });

    builder.addCase(transactionAdjustment.pending, state => {
      state.error = undefined;
      state.adjustmentSuccess = false;
      state.adjustmentLoading = true;
    });

    builder.addCase(transactionAdjustment.fulfilled, state => {
      state.adjustmentSuccess = true;
      state.error = undefined;
      state.adjustmentLoading = false;
    });

    builder.addCase(transactionAdjustment.rejected, (state, action) => {
      state.adjustmentSuccess = false;
      state.adjustmentLoading = false;
      state.error = action.error?.message ?? 'Failed adjustment transaction';
    });
  },
});

export const { reset } = singleTransactionHistorySlice.actions;
