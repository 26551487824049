import { marine } from '@/utils/colors';

export default function ThreeDotsIcon({
  width = `${3 / 16}rem`,
  height = `${14 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 3 14'
      fill='none'
    >
      <path
        d='M0 1.125C0 1.42337 0.118526 1.70952 0.329505 1.9205C0.540483 2.13147 0.826631 2.25 1.125 2.25C1.42337 2.25 1.70952 2.13147 1.92049 1.9205C2.13147 1.70952 2.25 1.42337 2.25 1.125C2.25 0.826632 2.13147 0.540483 1.92049 0.329505C1.70952 0.118527 1.42337 0 1.125 0C0.826631 0 0.540483 0.118527 0.329505 0.329505C0.118526 0.540483 0 0.826632 0 1.125ZM0 6.75C0 7.04837 0.118526 7.33452 0.329505 7.5455C0.540483 7.75647 0.826631 7.875 1.125 7.875C1.42337 7.875 1.70952 7.75647 1.92049 7.5455C2.13147 7.33452 2.25 7.04837 2.25 6.75C2.25 6.45163 2.13147 6.16548 1.92049 5.9545C1.70952 5.74353 1.42337 5.625 1.125 5.625C0.826631 5.625 0.540483 5.74353 0.329505 5.9545C0.118526 6.16548 0 6.45163 0 6.75ZM0 12.375C0 12.6734 0.118526 12.9595 0.329505 13.1705C0.540483 13.3815 0.826631 13.5 1.125 13.5C1.42337 13.5 1.70952 13.3815 1.92049 13.1705C2.13147 12.9595 2.25 12.6734 2.25 12.375C2.25 12.0766 2.13147 11.7905 1.92049 11.5795C1.70952 11.3685 1.42337 11.25 1.125 11.25C0.826631 11.25 0.540483 11.3685 0.329505 11.5795C0.118526 11.7905 0 12.0766 0 12.375Z'
        fill={color}
      />
    </svg>
  );
}
