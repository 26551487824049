import { type Color } from '.';

const thinBlack: Color = {
  class: 'text-thin-black',
  modifier: '--thin-black',
  cssColor: 'var(--color-black-100)',
};

const black: Color = {
  class: 'text-black',
  modifier: '--black',
  cssColor: 'var(--color-black-900)',
};

export { thinBlack, black };
