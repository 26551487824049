import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NavigationTabs from '@/components/common/navigation-tabs/NavigationTabs';
import Layout from '@/components/layout/Layout';

import { type TabInfo } from '@/utils/interfaces';

import styles from './ConfigurationsLayout.module.scss';
import { FeatureFlagKeys, featureFlagsData } from '@/types';

export default function ConfigurationsLayout(): React.JSX.Element {
  const { t } = useTranslation('configurations');

  const tabs: TabInfo[] = useMemo(() => {
    return [
      { linkText: t('countries-tab'), linkPath: 'countries' },
      ...(featureFlagsData[FeatureFlagKeys.FFConfigs]
        ? [{ linkText: 'Feature flags', linkPath: 'feature-flags' }]
        : []),
    ];
  }, [t]);

  return (
    <Layout>
      <div className={styles['configurations-layout']}>
        <div className={styles['configurations-layout__header']}>
          <h1>{t('header-title')}</h1>
          <NavigationTabs tabs={tabs} />
        </div>
        <Outlet />
      </div>
    </Layout>
  );
}
