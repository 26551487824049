import React from 'react';
import { NumericFormat, type OnValueChange, type NumberFormatValues } from 'react-number-format';
import styles from './Input.module.scss';
import Typography from '../typography/Typography';
import { mediumPurple } from '@/utils/colors';

export type Locale = 'en-US' | 'bg-BG';

export interface NumberInputCustomProps {
  value: string;
  onChange: OnValueChange;
  isAllowed?: (values: NumberFormatValues) => boolean;
  hint?: string;
  locale?: Locale;
}

const localeData = {
  'en-US': { thousandSeparator: ',', decimalSeparator: '.' },
  'bg-BG': { thousandSeparator: ' ', decimalSeparator: ',' },
};

const NumberInput: React.FC<NumberInputCustomProps> = ({ value, onChange, isAllowed, hint, locale = 'en-US' }) => {
  const format = localeData[locale];

  return (
    <div>
      <NumericFormat
        value={value}
        aria-label='numeric'
        allowNegative={false}
        thousandSeparator={format.thousandSeparator}
        decimalSeparator={format.decimalSeparator}
        isAllowed={isAllowed}
        onValueChange={onChange}
        decimalScale={2}
        className={styles.input}
      />
      {hint && (
        <Typography
          color={mediumPurple.cssColor}
          variant='p2'
        >
          {hint}
        </Typography>
      )}
    </div>
  );
};

export default NumberInput;
