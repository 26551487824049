import React from 'react';

import Layout from '@/components/layout/Layout';

import styles from '@/pages/error/error-404/Error404.module.scss';
import ErrorIcon from '@/components/icons/ErrorIcon';
import DartIcon from '@/components/icons/DartIcon';
import DartShadowIcon from '@/components/icons/DartShadowIcon';

export default function Error404(): React.JSX.Element {
  return (
    <Layout>
      <div className={styles.main}>
        <div className={styles.main__content}>
          <div className={styles['main__icon-container']}>
            <ErrorIcon />
            <div className={styles['main__icon-dart']}>
              <DartIcon />
            </div>
            <div className={styles['main__icon-dart-shadow']}>
              <DartShadowIcon />
            </div>
          </div>
          <div className={styles['main__text-container']}>
            <div className={styles['main__title-text']}>OOPS!</div>
            <div className={styles['main__secondary-text']}>404 error page</div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
