function DartIcon(): React.JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='81'
      height='100'
      viewBox='0 0 81 100'
      fill='none'
    >
      <path
        d='M49.2971 45.2084L68.8293 35.9491C69.5287 35.6911 70.1199 35.2468 70.5453 34.6591L80.2934 20.763C81.5768 19 81.0432 16.4271 79.0965 15.0296L73.5808 12.1845C72.2902 11.2528 70.5669 11.3245 69.5359 12.3421L53.8972 26.2669C53.7746 26.3744 53.6881 26.5106 53.6304 26.6683L47.783 43.7966C47.4874 44.6566 48.4319 45.5237 49.3043 45.2012L49.2971 45.2084Z'
        fill='#310D86'
      />
      <path
        d='M1.92663 99.6962C1.63101 99.6962 1.3354 99.6102 1.08305 99.4239C0.434135 98.958 0.289935 98.0622 0.758592 97.4244L4.99813 91.5979C5.46679 90.96 6.36806 90.8096 7.00976 91.2754C7.65867 91.7412 7.80287 92.6371 7.33422 93.2749L3.09466 99.1014C2.81347 99.4884 2.37366 99.6962 1.92663 99.6962Z'
        fill='#310D86'
      />
      <path
        d='M54.0414 26.6755C56.1539 28.1948 41.8995 60.3014 35.0499 69.7112C21.5598 88.2442 10.5499 93.87 6.42575 94.4075C5.66147 94.5078 5.2577 94.2857 4.99093 94.085C4.7602 93.913 4.42133 93.4758 4.3276 92.5012C3.89499 88.151 5.79125 76.0179 18.856 58.0654C25.7128 48.6484 51.9288 25.149 54.0414 26.6755Z'
        fill='#00EAD0'
      />
      <path
        d='M41.2001 38.1563C40.508 38.7798 39.3111 38.0775 39.4913 37.1601L43.3848 17.2942C43.4785 16.7997 43.702 16.3124 44.0121 15.8752L54.2144 1.86438C55.1661 0.560052 56.7524 0.0870518 57.7546 0.810884L63.3352 4.82421C64.2365 5.46921 64.3735 6.88104 63.6669 8.1352L54.1207 26.5463C54.0702 26.6395 54.0053 26.7183 53.9332 26.79L41.2146 38.1706L41.2001 38.1563Z'
        fill='#310D86'
      />
    </svg>
  );
}

export default DartIcon;
