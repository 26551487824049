export const EnvironmentConstants = {
  API_URL: process.env.REACT_APP_API_URL,
  API_SUBSCRIPTION_KEY: process.env.REACT_APP_API_SUBSCRIPTION_KEY,

  MSAL_CLIENT_ID: process.env.REACT_APP_MSAL_CLIENT_ID,
  MSAL_TENANT_NAME: process.env.REACT_APP_MSAL_TENANT_NAME,
  MSAL_AUTH_FLOW: process.env.REACT_APP_MSAL_AUTH_FLOW,
  MSAL_AUTH_SCOPE: process.env.REACT_APP_MSAL_AUTH_SCOPE,

  RUNTIME_ENV: process.env.REACT_APP_RUNTIME_ENV as RuntimeEnvironments,
};

export enum RuntimeEnvironments {
  Development = 'dev',
  Staging = 'stg',
  Production = 'pro',
}

export const AppUrls = {
  NotFoundUrl: '/not-found',
};

export enum ServerErrors {
  USER_ALREADY_EXISTS = 'err_user_already_exists',
}
