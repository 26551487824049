import CopyButton from '@/components/common/button/CopyButton';
import { type PaymentCardInfo } from '@/types';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import { truncateText } from '@/utils/helperFunctions';
import { POMELO_ID_MAX_TRUNCATED_LENGTH } from '../overview/payment-cards/card/Card';
import styles from './CardRow.module.scss';
import Typography from '@/components/common/typography/Typography';
import { useMemo } from 'react';
import ClickableRow from '@/components/common/tables/ClickableRow';
import { type ClickOptions } from '@/utils/hooks/useMouseClicks';

export default function CardRow({
  card,
  onClick,
}: Readonly<{
  card: PaymentCardInfo;
  onClick: (id: string, options?: ClickOptions) => void;
}>): React.JSX.Element {
  const cells = useMemo(
    () => [
      { content: <Typography variant='p3Bold'>{`**** **** **** ${card.lastFour}`}</Typography>, key: 'lastFour' },
      { content: card.cardType, key: 'cardType' },
      { content: card.status, key: 'cardStatus' },
      { content: toUtcDateString(card.activatedAt), key: 'activatedAt' },
      { content: toUtcDateString(card.createdAt), key: 'createdAt' },
      {
        content: (
          <div className={styles.pomeloIdWrapper}>
            <span>{truncateText(card.providerCardId, POMELO_ID_MAX_TRUNCATED_LENGTH)}</span>
            <span
              onMouseDown={e => e.stopPropagation()}
              onMouseUp={e => e.stopPropagation()}
            >
              <CopyButton textToCopy={card.providerCardId} />
            </span>
          </div>
        ),
        key: 'pomeloid',
      },
      { content: <div></div>, key: 'actions' },
    ],
    [card],
  );
  return (
    <ClickableRow
      onClick={opt => {
        onClick(card.id ?? '', opt);
      }}
      cells={cells}
    />
  );
}
