import React, { useMemo, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CustomerPiiField.module.scss';

export interface CustomerPiiFieldProps {
  fieldName?: string;
  children?: ReactNode;
}

export default function CustomerPiiField({ fieldName, children }: Readonly<CustomerPiiFieldProps>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');

  const fieldValue = useMemo(() => {
    const filteredChildren = React.Children.toArray(children).filter(child => {
      return !(typeof child === 'string' && child.trim() === '');
    });

    if (filteredChildren.length === 0) {
      return t('widgets.customer-pii.n-a');
    }

    return filteredChildren;
  }, [children, t]);

  return (
    <div className={styles['customer-field']}>
      {fieldName !== undefined && <div className={styles['customer-field__label']}>{fieldName}:&nbsp;</div>}
      <div className={styles['customer-field__value']}>{fieldValue}</div>
    </div>
  );
}
