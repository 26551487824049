import { type MouseEvent, useCallback, useState } from 'react';

export interface ClickOptions {
  isCtrlDown?: boolean;
  isMiddleMouseKey?: boolean;
}

export default function useMouseClicks(onClick: (options?: ClickOptions) => void): {
  onMouseDown: (event: MouseEvent<HTMLDivElement>) => void;
  onMouseUp: (event: MouseEvent<HTMLDivElement>) => void;
} {
  const [mouseDownTime, setMouseDownTime] = useState(0);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const pxTolerance = 2;
  const msTolerance = 500;

  const onMouseDown: (event: MouseEvent<HTMLDivElement>) => void = useCallback(event => {
    if (event.button === 0) {
      setMouseDownTime(Date.now());
      setStartPos({ x: event.clientX, y: event.clientY });
    }
  }, []);

  const onMouseUp: (event: MouseEvent<HTMLDivElement>) => void = useCallback(
    event => {
      if (event.button === 0) {
        const currentTime = Date.now();
        const timeDiff = currentTime - mouseDownTime;
        const moveDiff = Math.sqrt(Math.pow(event.clientX - startPos.x, 2) + Math.pow(event.clientY - startPos.y, 2));

        if (timeDiff < msTolerance && moveDiff <= pxTolerance) {
          onClick({ isCtrlDown: event.ctrlKey });
        }
      } else if (event.button === 1) {
        onClick({ isMiddleMouseKey: true, isCtrlDown: event.ctrlKey });
      }
    },
    [mouseDownTime, onClick, startPos],
  );

  return { onMouseDown, onMouseUp };
}
