import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { savePaymentMethodTopup } from './actions';
import type PaymentMethod from '@/types/PaymentMethod';

export interface PaymentMethodTopupState {
  paymentMethod?: PaymentMethod;
  success: boolean;
  loading: boolean;
}

const initialState: PaymentMethodTopupState = {
  loading: false,
  success: false,
};

export const paymentMethodTopupSlice = createSlice({
  name: 'paymentCards',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(savePaymentMethodTopup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(savePaymentMethodTopup.fulfilled, (state, action: PayloadAction<PaymentMethod>) => {
      state.paymentMethod = action.payload;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(savePaymentMethodTopup.rejected, state => {
      state.loading = false;
      state.success = false;
    });
  },
});

export const { reset } = paymentMethodTopupSlice.actions;
