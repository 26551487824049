import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AccountLimitsWidget.module.scss';
import authAxios from '@/auth/axios';
import type { AxiosError } from 'axios';
import { useParams } from 'react-router';
import { LimitType, MoneyFlowType } from '@/types/AccountLimits';
import type { LimitsResponse } from '@/types/AccountLimits';
import type { CurrencyEnum } from '@/types/Currencies';
import Spinner from '@/components/common/spinners/Spinner';
import WidgetBox from '../../widget-box/WidgetBox';

export interface AccountLimitsProps {
  path: string;
  currencyCode: CurrencyEnum.USD;
}

const AccountLimitsWidget: React.FC<AccountLimitsProps> = ({ path, currencyCode }) => {
  const [moneyInLimit, setMoneyInLimit] = useState<number>(0);
  const [moneyOutLimit, setMoneyOutLimit] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation('customerOverview');

  const { customerId } = useParams();

  useEffect(() => {
    authAxios
      .get<LimitsResponse>(`/${path}/${currencyCode}`, {
        headers: {
          'x-customer-id': customerId,
        },
      })
      .then(response => {
        const limitData = response.data;

        const amountLimits = limitData.data.limits.filter(limit => limit.limitType === LimitType.Amount);
        const moneyInLimitValue = amountLimits.find(limit => limit.moneyFlowType === MoneyFlowType.MoneyIn)?.value ?? 0;
        const moneyOutLimitValue =
          amountLimits.find(limit => limit.moneyFlowType === MoneyFlowType.MoneyOut)?.value ?? 0;

        setMoneyInLimit(moneyInLimitValue);
        setMoneyOutLimit(moneyOutLimitValue);
      })
      .catch((exception: AxiosError) => {
        const errorMessage = exception.message;
        setError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currencyCode, customerId, path]);

  if (loading) {
    return <Spinner />;
  }
  if (error !== '') {
    return <p>Error: {error}</p>;
  }

  return (
    <WidgetBox
      title={t('widgets.limits.header-title')}
      linkPath={`/customer/${customerId}/limits`}
      linkLabel={t('widgets.limits.view-all')}
      linkTarget='_self'
    >
      <div className={styles['float-container']}>
        <div className={styles['float-child']}>
          <div>
            <p className={styles.subTitle}>{t('widgets.limits.money-in')}</p>
          </div>
          <div>
            <p>
              <span className={styles.text}>{t('widgets.limits.total')}: </span>
              <span className={styles.moneyAmount}>
                {currencyCode} {moneyInLimit}
              </span>
            </p>
          </div>
        </div>
        <div className={`${styles['float-child']} ${styles.secondColumn}`}>
          <div>
            <p className={styles.subTitle}>{t('widgets.limits.money-out')}</p>
          </div>
          <div>
            <p>
              <span className={styles.text}>{t('widgets.limits.total')}: </span>
              <span className={styles.moneyAmount}>
                {currencyCode} {moneyOutLimit}
              </span>
            </p>
          </div>
        </div>
      </div>
    </WidgetBox>
  );
};

export default AccountLimitsWidget;
