import type { DeviceInfoType } from '@/types';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchDeviceInfo } from './actions';

export interface DeviceInfoSliceState {
  deviceInfo?: DeviceInfoType | null;
  loading: boolean;
}

const initialState: DeviceInfoSliceState = {
  deviceInfo: undefined,
  loading: false,
};

export const deviceInfoSlice = createSlice({
  name: 'deviceInfo',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDeviceInfo.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchDeviceInfo.fulfilled, (state, action: PayloadAction<DeviceInfoType>) => {
      state.loading = false;
      state.deviceInfo = action.payload;
    });
    builder.addCase(fetchDeviceInfo.rejected, state => {
      state.loading = false;
      state.deviceInfo = null;
    });
  },
});

export const { reset } = deviceInfoSlice.actions;
