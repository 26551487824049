import { type CountriesEnum } from './Countries';
import { type CurrencyEnum } from './Currencies';
import { type TransactionStatus } from '@/types/Transaction';

export interface BusinessAccounts {
  accountId: string;
  countryCode: CountriesEnum;
  currencyCode: CurrencyEnum;
  availableBalance: number;
  IconComponent?: React.ComponentType;
  countryName?: string;
}

export interface TransactionBA {
  createdOn?: Date | string;
  updatedOn?: Date | string;
  id: string;
  transactionType?: TransactionTypeBA;
  currency?: CurrencyEnum;
  amount?: number;
  status?: TransactionStatus;
  reasonCode?: string;
  providerId?: string;
  originEntityName?: string;
  pspTransactionId?: string;
  description?: string;
  customerId?: string;
  country?: CountriesEnum;
}

export enum TransactionTypeBA {
  PaymentToCustomer = 'PaymentToCustomer',
  PaymentFromCustomer = 'PaymentFromCustomer',
  MerchantWithdrawal = 'MerchantWithdrawal',
  MerchantTopup = 'MerchantTopup',
}
