import React from 'react';

import type { StatusVariants } from '@/utils/statusVariants';

import styles from './StatusStamp.module.scss';
import classNames from 'classnames';

interface StatusStampProps {
  status: string;
  variant?: StatusVariants;
  noBorder?: boolean;
}

function StatusStamp({ status, variant = 'errorDark', noBorder }: Readonly<StatusStampProps>): React.JSX.Element {
  const className = classNames(styles['status-stamp'], styles[variant], {
    [styles.border]: !noBorder,
  });
  return <span className={className}>{status}</span>;
}

export default StatusStamp;
