import { type CustomerDocStatus } from '@/types';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateStatus } from './actions';

export interface IdDocReviewSliceState {
  selectedStatus?: CustomerDocStatus;
  confirmedStatus?: CustomerDocStatus;
  documentId?: string;
  loading: boolean;
}

const initialState: IdDocReviewSliceState = {
  loading: false,
};

export const docReviewSlice = createSlice({
  name: 'confirm-document-review',
  initialState,
  reducers: {
    setSelectedDocument(state, action: PayloadAction<{ documentId: string; status: CustomerDocStatus } | undefined>) {
      state.selectedStatus = action.payload?.status;
      state.documentId = action.payload?.documentId;
    },
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(updateStatus.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.confirmedStatus = action.payload;
      state.selectedStatus = undefined;
    });
    builder.addCase(updateStatus.rejected, () => {
      return { ...initialState };
    });
  },
});

export const { setSelectedDocument, reset } = docReviewSlice.actions;
