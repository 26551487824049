import styles from '@/components/customer/transaction-history/filters/TransactionHistoryFilters.module.scss';
import Button from '@/components/common/button/Button';
import TransactionHistoryFilterChips
  from '@/components/customer/transaction-history/filters/TransactionHistoryFilterChips';

interface Props {
  disabledClearAll: boolean;
  onClick: () => void;
  label: string;
}

export default function TransactionHistoryFiltersHeader({ disabledClearAll, onClick, label }: Props): JSX.Element {
  return <div className={styles.filterContent}>
        <Button
            className={styles.filterButton}
            textTransform="uppercase"
            variant={disabledClearAll ? 'primary' : 'third'}
            onClick={onClick}
        >
            {label}
        </Button>
        {!disabledClearAll && <TransactionHistoryFilterChips/>}
    </div>;
}
