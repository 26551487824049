import { useMemo, useState } from 'react';
import NavigationTabs from '@/components/common/navigation-tabs/NavigationTabs';
import CustomerPii from '@/components/customer/customer-pii/CustomerPii';
import { type RootState } from '@/lib/redux';
import { type TabInfo } from '@/utils/interfaces';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import styles from './CustomerInfoLayout.module.scss';
import { FeatureFlagKeys, Permissions, featureFlagsData } from '@/types';
import Breadcrumbs from '@/components/common/breadcrumbs/Breadcrumbs';

import EditCustomer from '@/components/customer/edit-customer/EditCustomer';
import { useTranslation } from 'react-i18next';

export default function CustomerInfoLayout(): React.JSX.Element | null {
  const { customer } = useSelector((state: RootState) => state.customer);
  const [showCustomerEdit, setShowCustomerEdit] = useState(false);
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation('customerOverview');

  const tabs: TabInfo[] = useMemo(() => {
    const result = [
      { linkText: t('navigation-tabs.overview'), linkPath: 'overview' },
      {
        linkText: t('navigation-tabs.transaction-history'),
        linkPath: 'transaction-history',
        permission: Permissions.transactionsOverview,
      },
      { linkText: t('navigation-tabs.kyc-risk'), linkPath: 'kyc-risk' },
      {
        linkText: t('navigation-tabs.cards'),
        linkPath: 'cards',
        permission: Permissions.cardOverviewRead,
      },
    ];
    if (featureFlagsData[FeatureFlagKeys.FeatureDailyLimits]) {
      result.push({ linkText: t('navigation-tabs.limits'), linkPath: 'limits' });
    }
    return result;
  }, [t]);

  const breadcrumbNameMap = useMemo(() => {
    return {
      '/customer/:customerId': t('navigation-tabs.overview'),
      '/customer/:customerId/transaction-history': t('navigation-tabs.transaction-history'),
      '/customer/:customerId/kyc-risk': t('navigation-tabs.kyc-risk'),
      '/customer/:customerId/limits': t('navigation-tabs.limits'),
      '/customer/:customerId/cards': t('navigation-tabs.cards'),
    };
  }, [t]);

  const handleCustomerEdit = (): void => {
    setShowCustomerEdit(true);
    setEditing(true);
  };

  const handleCancel = (): void => {
    setEditing(false);
    setShowCustomerEdit(false);
  };

  if (customer !== undefined && customer !== null) {
    return (
      <div className={styles['customer-page']}>
        <div className={styles['customer-info']}>
          <div className={styles['breadcrumbs-wrapper']}>
            <Breadcrumbs urlToLabel={breadcrumbNameMap} />
          </div>
          <div className={styles['customer-wrapper']}>
            <h2 className={styles['customer-name']}>
              {customer.firstName} {customer.lastName}
            </h2>
            {!showCustomerEdit && <NavigationTabs tabs={tabs} />}
          </div>
        </div>
        <div className={styles['customer-details']}>
          <CustomerPii
            editing={editing}
            onEdit={handleCustomerEdit}
            onCancel={handleCancel}
          />
          {!showCustomerEdit && (
            <div className={styles['customer-widgets']}>
              <Outlet />
            </div>
          )}
          {showCustomerEdit && (
            <EditCustomer
              onCancel={handleCancel}
              customer={customer}
            />
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
