import { type User } from '@/types';
import { fetchUsers, deleteUser } from './actions';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UsersSliceState {
  users: User[];
  page: number;
  itemsPerPage: number;
  count: number;
  isLoading: boolean;
  deleteSuccess: boolean;
  deleteLoading: boolean;
}

const initialState: UsersSliceState = {
  users: [],
  page: -1,
  count: 0,
  itemsPerPage: -1,
  isLoading: false,
  deleteSuccess: false,
  deleteLoading: false,
};

export const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    insertUser(state: UsersSliceState, action: PayloadAction<User>) {
      state.users = [action.payload, ...state.users];
      state.count = state.count + 1;
    },
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUsers.pending, state => {
      state.isLoading = true;
      state.deleteSuccess = false;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload.users;
      state.count = action.payload.count;
      state.page = action.payload.page;
      state.itemsPerPage = action.payload.itemsPerPage;
    });
    builder.addCase(fetchUsers.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(deleteUser.pending, state => {
      state.deleteSuccess = false;
      state.deleteLoading = true;
    });
    builder.addCase(deleteUser.fulfilled, state => {
      state.deleteSuccess = true;
      state.deleteLoading = false;
    });
    builder.addCase(deleteUser.rejected, state => {
      state.deleteSuccess = false;
      state.deleteLoading = false;
    });
  },
});

export const { insertUser, reset } = usersSlice.actions;
