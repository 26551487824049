import { marine } from '@/utils/colors';

export default function CopyIcon({
  width = '1rem',
  height = `${17 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 8.95747L14 5.17153C14 3.51466 12.6568 2.17151 11 2.17153L7.25 2.17157M9.33325 14.9894L4.83325 14.9894C4.00482 14.9894 3.33325 14.3142 3.33325 13.4814V6.94684C3.33325 6.114 4.00482 5.43886 4.83325 5.43886L9.33325 5.43886C10.1617 5.43886 10.8332 6.114 10.8332 6.94684L10.8333 13.4814C10.8333 14.3142 10.1617 14.9894 9.33325 14.9894Z'
        stroke={color}
        strokeWidth='1.8'
        strokeLinecap='round'
      />
    </svg>
  );
}
