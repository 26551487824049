import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import type { Exchange } from '@/types/Exchange';
import type { AxiosResponse } from 'axios';

export interface ExchangeTransactionRequest {
  transactionId: string;
  customerId: string;
}

interface ExchangeTransactionResponse {
  data: Exchange;
}

export const fetchTransactionExchange = createAsyncThunk<Exchange, ExchangeTransactionRequest>(
  'detailExchangeTransactionHistory/fetch',
  async ({ transactionId, customerId }, thunkApi) => {
    try {
      const response: AxiosResponse<ExchangeTransactionResponse> = await authAxios.get<ExchangeTransactionResponse>(
        `/exchanges/${transactionId}`,
        {
          headers: { 'x-customer-id': customerId },
        },
      );
      const exchange = response.data.data;
      return exchange;
    } catch (err: any) {
      return thunkApi.rejectWithValue(err);
    }
  },
);
