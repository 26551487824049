import { marine } from '@/utils/colors';

export default function DeleteIcon({
  width = `${14 / 16}rem`,
  height = `${15 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.4167 3.6H12.1833V2.2C12.1833 1.48557 11.618 0.9 10.9167 0.9H5.08333C4.38198 0.9 3.81667 1.48557 3.81667 2.2V3.6H1.58333C1.20281 3.6 0.9 3.91557 0.9 4.3V4.9C0.9 5.03505 1.00776 5.15 1.14583 5.15H2.15136L2.59724 14.8608C2.59724 14.8608 2.59724 14.8608 2.59724 14.8608C2.62874 15.5511 3.18444 16.1 3.86198 16.1H12.138C12.8174 16.1 13.3713 15.553 13.4028 14.8608C13.4028 14.8608 13.4028 14.8608 13.4028 14.8608L13.8486 5.15H14.8542C14.9922 5.15 15.1 5.03505 15.1 4.9V4.3C15.1 3.91557 14.7972 3.6 14.4167 3.6ZM5.32917 2.45H10.6708V3.6H5.32917V2.45ZM12.3341 5.15L11.9021 14.55H4.09785L3.6659 5.15H12.3341Z'
        fill='#00EAD0'
        stroke={color}
        strokeWidth='0.2'
      />
    </svg>
  );
}
