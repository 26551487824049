import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import type { ChargebackReversal } from '@/types/ChargebackReversal';
import type { AxiosResponse } from 'axios';
import { errorToast } from '@/utils/toastMessage';

export interface ChargebackReversalTransactionRequest {
  transactionId: string;
}

interface ChargebackReversalTransactionResponse {
  data: ChargebackReversal;
}

export const fetchTransactionChargebackReversal = createAsyncThunk<
  ChargebackReversal,
  ChargebackReversalTransactionRequest
>('detailChargebackReversalTransactionHistory/fetch', async ({ transactionId }, thunkApi) => {
  try {
    const response: AxiosResponse<ChargebackReversalTransactionResponse> =
      await authAxios.get<ChargebackReversalTransactionResponse>(`/chargeback-reversals/${transactionId}`);
    const chargebackReversal = response.data.data;
    return chargebackReversal;
  } catch (err: any) {
    errorToast();
    return thunkApi.rejectWithValue(err);
  }
});
