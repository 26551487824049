import { type Country } from '@/types';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { addCountry, countryEdit, getCountry } from './actions';
import { isProdEnvironment } from '@/utils/helperFunctions';

export interface CountryState {
  isLoading: boolean;
  successfulCountryCreation: boolean;
  getCountryLoading: boolean;
  country: Country;
}

const initialState: CountryState = {
  isLoading: false,
  getCountryLoading: true,
  successfulCountryCreation: false,
  country: {
    countryCode: '',
    countryName: '',
    internationalCallingCode: 0,
    isEnabled: false,
    isTesting: !isProdEnvironment(),
    minPhoneLength: 0,
    maxPhoneLength: 0,
    currencies: [],
  },
};

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    turnGetCountryLoadingOff(state: CountryState) {
      state.getCountryLoading = false;
    },
    resetCountry() {
      return { ...initialState };
    },
    resetSucces(state) {
      state.successfulCountryCreation = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(addCountry.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(addCountry.fulfilled, (state, action: PayloadAction<Country>) => {
      state.isLoading = false;
      state.successfulCountryCreation = true;
      state.country = action.payload;
    });
    builder.addCase(addCountry.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getCountry.pending, state => {
      state.getCountryLoading = true;
    });
    builder.addCase(getCountry.fulfilled, (state, action: PayloadAction<Country>) => {
      state.getCountryLoading = false;
      state.country = action.payload;
    });
    builder.addCase(getCountry.rejected, state => {
      state.getCountryLoading = false;
    });

    builder.addCase(countryEdit.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(countryEdit.fulfilled, (state, action: PayloadAction<Country>) => {
      state.isLoading = false;
      state.country = action.payload;
    });
    builder.addCase(countryEdit.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { resetCountry, turnGetCountryLoadingOff, resetSucces } = countrySlice.actions;
