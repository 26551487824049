import { type UserPermission, type UserRole } from '@/types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchRolesAndPermissions } from './actions';

interface RolesAndPermissionsSliceState {
  roles: UserRole[];
  permissions: UserPermission[];
  isLoading: boolean;
}

const initialState: RolesAndPermissionsSliceState = {
  roles: [],
  permissions: [],
  isLoading: false,
};

export const rolesAndPermissionsSlice = createSlice({
  name: 'rolesAndPermissionsSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRolesAndPermissions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchRolesAndPermissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.roles = action.payload.roles;
      state.permissions = action.payload.permissions;
    });
    builder.addCase(fetchRolesAndPermissions.rejected, state => {
      state.isLoading = false;
    });
  },
});
