import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionStatus, TransactionType } from '@/types';
import {
  type ActionProps,
  TransactionActionsContext,
  TransactionActionType,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';
import ActionInput from '@/components/customer/transaction-history/transaction-actions/ActionInput';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { transactionCreateReversal } from '@/lib/redux/slices/transaction-reversal/actions';
import { reset as reversalReset } from '@/lib/redux/slices/transaction-reversal/slice';
import ActionButton from '@/components/customer/transaction-history/transaction-actions/ActionButton';
import { useParams } from 'react-router-dom';
import { fetchReversalActionEmptyResult } from '@/lib/redux/slices/transaction-actions/actions';
import { isAmountAllowed } from '@/utils/helperFunctions';

const ReversalAction: React.FC<ActionProps> = ({ onClose, transaction, setAccessibility }) => {
  const { t } = useTranslation(['customerOverview', 'global']);
  const { selectedTransactionAction } = useContext(TransactionActionsContext);
  const { isLoading, success } = useAppSelector((state: RootState) => state.transactionReversalSlice);
  const { isReversalEmtpy } = useAppSelector((state: RootState) => state.transactionActionsSlice);
  const dispatch = useAppDispatch();
  const { customerId } = useParams<{ customerId: string }>();

  useEffect(() => {
    if (success) {
      void dispatch(reversalReset());
      onClose({ success: true });
    }
  }, [success, dispatch, onClose]);

  const reversalAllowed =
    transaction?.transactionType === TransactionType.Transfer && transaction.status === TransactionStatus.Approved;

  useEffect(() => {
    if (
      !selectedTransactionAction &&
      isReversalEmtpy === undefined &&
      transaction?.slipId &&
      customerId &&
      reversalAllowed
    ) {
      void dispatch(
        fetchReversalActionEmptyResult({
          customerId,
          slipId: transaction.slipId,
        }),
      );
    } else if (!isReversalEmtpy) {
      setAccessibility(false);
    }
  }, [
    selectedTransactionAction,
    customerId,
    dispatch,
    transaction,
    reversalAllowed,
    isReversalEmtpy,
    setAccessibility,
  ]);

  const handleConfirm = (amount: number, reason: string): void => {
    if (transaction?.slipId) {
      void dispatch(
        transactionCreateReversal({
          slipId: transaction.slipId,
          amount,
          reason,
        }),
      );
    }
  };

  const requestedAmount = transaction?.requestedAmount ?? 0;

  let amount = `${requestedAmount}`;

  useEffect(() => {
    if (isReversalEmtpy !== undefined) {
      setAccessibility(isReversalEmtpy);
    }
    return () => {
      setAccessibility(undefined);
    };
  }, [isReversalEmtpy, setAccessibility]);

  if (transaction?.requestedAmount && transaction?.requestedAmountCurrency) {
    amount = `${transaction.requestedAmount} ${transaction.requestedAmountCurrency}`;
  }

  if (selectedTransactionAction === undefined) {
    return (
      <ActionButton
        type={TransactionActionType.Reversal}
        hide={!(isReversalEmtpy && reversalAllowed)}
        label='Reversal'
      />
    );
  }
  if (selectedTransactionAction === TransactionActionType.Reversal) {
    return (
      <ActionInput
        disabledButton={isLoading}
        onConfirm={handleConfirm}
        onClose={onClose}
        isAllowed={values => isAmountAllowed(values, requestedAmount)}
        inputLabel={t('widgets.transaction-history.modal-input-amount')}
        hint={t('widgets.transaction-history.information-text-amount', {
          amount,
        })}
      ></ActionInput>
    );
  }

  return null;
};

export default ReversalAction;
