import styles from './CardsTab.module.scss';
import { Outlet } from 'react-router-dom';

export default function CardsTab(): React.JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.widget}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
