import React from 'react';
import Typography from '@/components/common/typography/Typography';
import { CardWrapper, CardContent, CardDetails, CardIfo, ActionCard, Info } from './Styles';
import type { PaymentCardInfo } from '@/types';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import Actions from '../actions/Actions';
import { truncateText } from '@/utils/helperFunctions';
import CopyButton from '@/components/common/button/CopyButton';
import CardStatusStamp from './CardStatusStamp';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { Permissions } from '@/types';

interface CardProps {
  customerId: string;
  card?: PaymentCardInfo;
}

export const POMELO_ID_MAX_TRUNCATED_LENGTH = 8;

const Card: React.FC<CardProps> = props => {
  const { customerId, card } = props;
  const isCardPresent = card !== undefined;

  return (
    <CardWrapper>
      <CardContent>
        {isCardPresent && (
          <>
            <CardDetails>
              <Typography variant='p2Semibold'>**** **** **** {card.lastFour}</Typography>
              {card.status && <CardStatusStamp status={card.status} />}
            </CardDetails>
            <CardIfo>
              <Typography variant='p2Semibold'>{card.cardHolder}</Typography>
              {card.expirationDate && (
                <Info>
                  <Typography variant='p2'>Exp. date</Typography>
                  <Typography variant='p2'>{toUtcDateString(card.expirationDate)}</Typography>
                </Info>
              )}
              {!card.expirationDate && (
                <Info>
                  <Typography variant='p2'></Typography>
                  <Typography variant='p2'></Typography>
                </Info>
              )}
              <Info>
                <Typography variant='p2'>PomeloID</Typography>
                <Typography
                  variant='p2'
                  title={card.providerCardId}
                >
                  {truncateText(card.providerCardId, POMELO_ID_MAX_TRUNCATED_LENGTH)}
                </Typography>
                <CopyButton textToCopy={card.providerCardId} />
              </Info>
            </CardIfo>
            <UserPermissionsGuard permission={Permissions.customerManage}>
              <ActionCard>
                {card.status && (
                  <Actions
                    customerId={customerId}
                    status={card.status}
                    cardId={card.id}
                    cardNumber={card.lastFour}
                  />
                )}
              </ActionCard>
            </UserPermissionsGuard>
          </>
        )}
      </CardContent>
    </CardWrapper>
  );
};

export default Card;
