import { Link } from 'react-router-dom';
import style from './LinkButton.module.scss';
import classNames from 'classnames';

type ButtonActionProps =
  | { href: string; target?: React.HTMLAttributeAnchorTarget; className?: string; onClick?: never }
  | { onClick: () => void; href?: never; target?: never };
type LinkButtonProps = { children: React.ReactNode; disabled?: boolean; className?: string } & ButtonActionProps;

export default function LinkButton({
  children,
  href,
  target,
  onClick,
  disabled,
  className,
}: Readonly<LinkButtonProps>): React.JSX.Element {
  const primaryClass = classNames(style.link, {
    [style.disabled]: disabled,
  });
  if (onClick !== undefined) {
    return (
      <button
        className={primaryClass}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }

  if (disabled) {
    return <div className={primaryClass}>{children}</div>;
  }

  return (
    <Link
      className={className ?? primaryClass}
      to={href}
      target={target}
    >
      {children}
    </Link>
  );
}
