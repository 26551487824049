import { useCallback, useEffect, useMemo, useState } from 'react';
import { type SetURLSearchParams, useSearchParams } from 'react-router-dom';

let updateQueue: Array<{ key: string; value?: string | string[] }> = [];

function flushUpdateQueue(searchParamsProp: URLSearchParams, setSearchParams: SetURLSearchParams): void {
  if (updateQueue.length > 0) {
    const searchParams = new URLSearchParams(searchParamsProp);
    updateQueue.forEach(update => {
      searchParams.delete(update.key);
      if (Array.isArray(update.value)) {
        update.value.forEach((value: string, index) => {
          if (index === 0) {
            searchParams.set(update.key, value);
          } else {
            searchParams.append(update.key, value);
          }
        });
      } else if (update.value) {
        searchParams.set(update.key, update.value);
      }
    });
    setSearchParams(searchParams);
    updateQueue = [];
  }
}

export function useUrlState(key: string): [string, (value: string) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const [, trigger] = useState(0);
  const value = searchParams.get(key);

  useEffect(() => {
    flushUpdateQueue(searchParams, setSearchParams);
  });

  const setValue = useCallback(
    (value: string): void => {
      updateQueue.push({ key, value });
      trigger(n => n + 1);
    },
    [key],
  );

  const result = useMemo(
    () => [value === null ? '' : value, setValue] as [string, (value: string) => void],
    [value, setValue],
  );
  return result;
}

const areArraysEqual = (a: string[], b: string[]): boolean => {
  if (a === b) {
    return true;
  }
  if (a.length !== b.length) {
    return false;
  }
  return a.every(item => b.includes(item));
};

export function useUrlStateArray(key: string): [string[], (value: string[]) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const [, trigger] = useState(0);
  const [value, setInternalValue] = useState(searchParams.getAll(key));

  useEffect(() => {
    flushUpdateQueue(searchParams, setSearchParams);
  });

  useEffect(() => {
    const urlValue = searchParams.getAll(key);

    if (!areArraysEqual(value, urlValue)) {
      setInternalValue(urlValue);
    }
  }, [searchParams, key, value]);

  const setValue = useCallback(
    (value: string[]): void => {
      updateQueue.push({ key, value });
      trigger(n => n + 1);
    },
    [key],
  );

  return [value, setValue];
}
