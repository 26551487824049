import { useTranslation } from 'react-i18next';

import CustomerPiiField from './CustomerPiiField';
import { StyledHeader } from '../CustomerPii';

import { type CustomerAcceptedDoc } from '@/types/Customer';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import KuadyCardsData from '@/components/customer/customer-pii/data/KuadyCardsData';

export function AcceptedDocs({
  acceptedDocs,
  customerId,
}: Readonly<{
  acceptedDocs: CustomerAcceptedDoc[];
  customerId: string;
}>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');

  const docTypeMapping: Record<string, string> = {
    'terms-and-conditions': 'Kuady app',
    'privacy-policy': 'Kuady app',
  };

  const createViewData = (acceptedDocs: CustomerAcceptedDoc[]): Record<string, string> => {
    const result: Record<string, string> = {};
    result['Kuady app'] = t('widgets.customer-pii.n-a');

    for (const doc of acceptedDocs) {
      const key = doc.acceptedDocType;
      const value = doc.acceptedDocTime;

      const mapping = docTypeMapping[key];
      if (mapping !== undefined) {
        result[mapping] = toUtcDateString(value);
      }
    }

    return result;
  };

  const viewData: Record<string, string> = createViewData(acceptedDocs);

  return (
    <div>
      <StyledHeader>{t('widgets.customer-pii.accepted-docs.header-title')}</StyledHeader>
      {Object.entries(viewData).map(acceptedDoc => (
        <CustomerPiiField
          key={acceptedDoc[0]}
          fieldName={acceptedDoc[0]}
        >
          {acceptedDoc[1]}
        </CustomerPiiField>
      ))}
      <KuadyCardsData customerId={customerId} />
    </div>
  );
}
