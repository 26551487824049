import { useCallback, useEffect, useState } from 'react';
import { useUrlState } from './useUrlState';

const DEFAULT_IPP = 20;
const MAX_IPP = 100;

function getValue(value: string | number): number {
  if (typeof value === 'string') {
    value = parseInt(value);
  }
  if (isNaN(value) || value < 1) {
    return DEFAULT_IPP;
  }
  if (value > MAX_IPP) {
    return MAX_IPP;
  }
  return value;
}

export default function useItemsPerPage(dependentPageProp?: number): [number, (p: number) => void] {
  const [ippUrl, setIppUrl] = useUrlState('itemsPerPage');
  const [itemsPerPage, setItemsPerPage] = useState(getValue(ippUrl));
  const dependentPage = dependentPageProp ?? -1;
  useEffect(() => {
    if (dependentPage > 0) setIppUrl(`${dependentPage}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependentPage]);

  useEffect(() => {
    const ipp = getValue(ippUrl);
    setItemsPerPage(ipp);
  }, [ippUrl, setIppUrl]);

  const setter = useCallback((newValue: number) => setIppUrl(`${getValue(newValue)}`), [setIppUrl]);

  return [itemsPerPage, setter];
}
