import { marine } from '@/utils/colors';

function PersonIcon({
  width = `${35 / 16}rem`,
  height = `${35 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 35 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='17.5'
        cy='12.5'
        r='5.5'
        stroke={color}
        strokeWidth='2'
      />
      <path
        d='M8 29C8 27 9.12973 23 13.6486 23C18.1676 23 18.2703 23 21.8649 23C24.4324 23 27 25.5 27 29'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}

export default PersonIcon;
