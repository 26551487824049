import { type Color } from '.';

const lightShadeGrey: Color = {
  class: 'text-light-shade-grey',
  modifier: '--light-shade-grey',
  cssColor: 'var(--color-grey-100)',
};

const extraLightGrey: Color = {
  class: 'text-extra-light-grey',
  modifier: '--extra-light-grey',
  cssColor: 'var(--color-grey-200)',
};
const lightGrey: Color = {
  class: 'text-light-grey',
  modifier: '--light-grey',
  cssColor: 'var(--color-grey-300)',
};
const normalGrey: Color = {
  class: 'text-normal-grey',
  modifier: '--normal-grey',
  cssColor: 'var(--color-grey-400)',
};
const mediumGrey: Color = {
  class: 'text-medium-grey',
  modifier: '--medium-grey',
  cssColor: 'var(--color-grey-500)',
};
const boldGrey: Color = {
  class: 'text-dark-grey',
  modifier: '--dark-grey',
  cssColor: 'var(--color-grey-700)',
};

export { extraLightGrey, lightGrey, normalGrey, mediumGrey, boldGrey, lightShadeGrey };
