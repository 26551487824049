import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionStatus, TransactionType } from '@/types';
import {
  type ActionProps,
  TransactionActionsContext,
  TransactionActionType,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';
import ActionInput from '@/components/customer/transaction-history/transaction-actions/ActionInput';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { transactionCreateReversal } from '@/lib/redux/slices/transaction-reversal/actions';
import { reset as reversalReset } from '@/lib/redux/slices/transaction-reversal/slice';
import ActionButton from '@/components/customer/transaction-history/transaction-actions/ActionButton';
import { useParams } from 'react-router-dom';
import { fetchMerchantPaymentReversalActionEmptyResult } from '@/lib/redux/slices/transaction-actions/actions';
import { isAmountAllowed } from '@/utils/helperFunctions';

const MerchantPaymentReversalAction: React.FC<ActionProps> = ({ onClose, transaction, setAccessibility }) => {
  const { t } = useTranslation(['customerOverview', 'global']);
  const { selectedTransactionAction } = useContext(TransactionActionsContext);
  const { isLoading, success } = useAppSelector((state: RootState) => state.transactionReversalSlice);
  const { isMerchantReversalEmtpy } = useAppSelector((state: RootState) => state.transactionActionsSlice);
  const { customerId } = useParams<{ customerId: string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (success) {
      void dispatch(reversalReset());
      onClose({ success: true });
    }
  }, [success, dispatch, onClose]);

  const merchantPaymentReversalAllowed =
    transaction?.transactionType === TransactionType.PaymentFromCustomer &&
    transaction.status === TransactionStatus.Approved;

  useEffect(() => {
    if (
      !selectedTransactionAction &&
      isMerchantReversalEmtpy === undefined &&
      transaction?.slipId &&
      customerId &&
      merchantPaymentReversalAllowed
    ) {
      void dispatch(
        fetchMerchantPaymentReversalActionEmptyResult({
          customerId,
          slipId: transaction.slipId,
        }),
      );
    } else if (!isMerchantReversalEmtpy) {
      setAccessibility(false);
    }
  }, [
    selectedTransactionAction,
    customerId,
    dispatch,
    transaction,
    merchantPaymentReversalAllowed,
    isMerchantReversalEmtpy,
    setAccessibility,
  ]);

  const handleConfirm = (amount: number, reason: string): void => {
    if (transaction?.slipId) {
      void dispatch(
        transactionCreateReversal({
          slipId: transaction.slipId,
          amount,
          reason,
        }),
      );
    }
  };

  const requestedAmount = transaction?.requestedAmount ?? 0;

  let amount = `${requestedAmount}`;

  if (transaction?.requestedAmount && transaction?.requestedAmountCurrency) {
    amount = `${transaction.requestedAmount} ${transaction.requestedAmountCurrency}`;
  }

  useEffect(() => {
    if (isMerchantReversalEmtpy !== undefined) {
      setAccessibility(isMerchantReversalEmtpy);
    }
    return () => {
      setAccessibility(undefined);
    };
  }, [isMerchantReversalEmtpy, setAccessibility]);

  if (selectedTransactionAction === undefined) {
    return (
      <ActionButton
        type={TransactionActionType.MerchantPaymentReversal}
        hide={!(isMerchantReversalEmtpy && merchantPaymentReversalAllowed)}
        label='Merchant Payment Reversal'
      />
    );
  }
  if (selectedTransactionAction === TransactionActionType.MerchantPaymentReversal) {
    return (
      <ActionInput
        disabledButton={isLoading}
        onConfirm={handleConfirm}
        onClose={onClose}
        isAllowed={values => isAmountAllowed(values, requestedAmount)}
        inputLabel={t('widgets.transaction-history.modal-input-amount')}
        hint={t('widgets.transaction-history.information-text-amount', {
          amount,
        })}
      ></ActionInput>
    );
  }

  return null;
};

export default MerchantPaymentReversalAction;
