import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import NavigationTabs from '@/components/common/navigation-tabs/NavigationTabs';
import Layout from '@/components/layout/Layout';

import { type TabInfo } from '@/utils/interfaces';

import styles from './AccessManagementPage.module.scss';

export default function AccessManagementPage(): React.JSX.Element {
  const { t } = useTranslation('accessManagement');

  const tabs: TabInfo[] = useMemo(() => {
    return [
      {
        linkText: t('tab-users'),
        linkPath: 'users',
      },
      { linkText: t('tab-roles-and-permissions'), linkPath: 'roles-and-permissions' },
    ];
  }, [t]);

  return (
    <Layout>
      <div className={styles['access-management-page']}>
        <div className={styles['access-management-page__header']}>
          <h1>{t('header-title')}</h1>
          <NavigationTabs tabs={tabs} />
        </div>
        <div className={styles.content}>
          <div className={styles['content-box']}>
            <Outlet />
          </div>
        </div>
      </div>
    </Layout>
  );
}
