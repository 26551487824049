import React from 'react';
import { extraLightWhite, mediumPurple, normalWhite, purple, thinBlack, white } from '@/utils/colors';
import styled from 'styled-components';

const StyledTableWrapper = styled.div`
  overflow: auto;
  display: flex;
`;

const StyledTable = styled.div<{ $cols?: number; $colsTemplate?: string }>`
  display: grid;
  grid-template-columns: ${({ $colsTemplate, $cols }) => $colsTemplate ?? `repeat(${$cols}, auto)`};
  width: 100%;
  overflow: auto;
`;

const StyledHead = styled.div`
  position: sticky;
  top: 0;
  background-color: ${extraLightWhite.cssColor};
  border-bottom-color: ${thinBlack.cssColor};
  border-bottom-style: solid;
  border-bottom-width: 0.063rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  color: ${purple.cssColor};
  white-space: nowrap;
`;

const StyledColumn = styled.div`
  padding: 0.375rem;
  background: ${white.cssColor};
  border-bottom-color: ${normalWhite.cssColor};
  border-bottom-style: solid;
  border-bottom-width: 0.063rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  color: ${mediumPurple.cssColor};
  display: flex;
  align-items: center;
  word-break: break-all;
`;

interface ColsType {
  cols: number;
  colsTemplate?: never;
  children: React.ReactNode;
}
interface ColsTemplateType {
  cols?: never;
  colsTemplate: string;
  children: React.ReactNode;
}
type TableProps = ColsType | ColsTemplateType;

const Table = ({ cols, colsTemplate, children }: Readonly<TableProps>): React.JSX.Element => {
  return (
    <StyledTableWrapper>
      <StyledTable
        $cols={cols}
        $colsTemplate={colsTemplate}
      >
        {children}
      </StyledTable>
    </StyledTableWrapper>
  );
};

const TableHead = ({ children, ...rest }: Readonly<React.HTMLProps<HTMLDivElement>>): React.JSX.Element => (
  <StyledHead {...rest}>{children}</StyledHead>
);
const TableRow = ({ children }: Readonly<{ children: React.ReactNode }>): React.JSX.Element => <>{children}</>;
const TableCell = ({ children, ...rest }: Readonly<React.HTMLProps<HTMLDivElement>>): React.JSX.Element => (
  <StyledColumn {...rest}>{children}</StyledColumn>
);

Table.Head = TableHead;
Table.Row = TableRow;
Table.Cell = TableCell;

export default Table;
