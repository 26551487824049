import React, { useState } from 'react';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import { useTranslation } from 'react-i18next';
import styles from '@/components/customer/transaction-history/transaction-actions/TransactionActions.module.scss';
import { ModalActions } from '@/components/customer/kyc-risk/audit-logs/ActionModal/Styles';
import Button from '@/components/common/button/Button';

export interface ActionInputReasonProps {
  disabledButton: boolean;
  onConfirm: (reason: string) => void;
  onClose: () => void;
  reasonLabel?: string;
}

const ActionInputReason: React.FC<ActionInputReasonProps> = ({ disabledButton, onConfirm, onClose, reasonLabel }) => {
  const { t } = useTranslation(['customerOverview', 'global']);
  const [reason, setReason] = useState('');
  const isSubmitEnabled = reason.length > 0;
  const handleReason = (updatedReason: string): void => {
    setReason(updatedReason);
  };

  return (
    <div>
      <div className={styles.contentWrapper}>
        <InputLabel label={reasonLabel ?? t('widgets.transaction-history.modal-input-reason')}>
          <Input
            value={reason}
            onChange={handleReason}
          />
        </InputLabel>
      </div>
      <ModalActions>
        <Button
          disabled={disabledButton}
          variant='secondary'
          onClick={onClose}
          textTransform='uppercase'
        >
          {t('confirm-modal.cancel', { ns: 'global' })}
        </Button>
        <Button
          disabled={!isSubmitEnabled || disabledButton}
          onClick={() => onConfirm(reason)}
          textTransform='uppercase'
        >
          {t('confirm-modal.confirm', { ns: 'global' })}
        </Button>
      </ModalActions>
    </div>
  );
};

export default ActionInputReason;
