import { createAsyncThunk } from '@reduxjs/toolkit';
import { type Transaction } from '@/types';
import authAxios from '@/auth/axios';
import { errorToast, successToast } from '@/utils/toastMessage';

export interface SingleTransactionRequest {
  transactionId: string;
}

export interface SingleTransactionResponse {
  transaction: Transaction;
}

export interface AdjustmentBody {
  body: {
    slipId: string;
    amount: number;
    transactionType: string;
    reason?: string;
  };
}

export const fetchSingleTransaction = createAsyncThunk<SingleTransactionResponse, SingleTransactionRequest>(
  'detailTransactionHistory/fetch',
  async ({ transactionId }, thunkApi) => {
    try {
      const response = await authAxios.get(`/transactions/${transactionId}`);
      const data = response.data;
      return {
        transaction: data.data,
      };
    } catch (err: any) {
      return thunkApi.rejectWithValue(err);
    }
  },
);

export const transactionAdjustment = createAsyncThunk(
  'detailTransactionHistory/adjustment',
  async ({ body }: AdjustmentBody, thunkApi) => {
    try {
      await authAxios.post('/transactions/adjustments', { ...body });
      successToast('toast-messages.manual-transaction');
    } catch (err: any) {
      errorToast();
      return thunkApi.rejectWithValue(err);
    }
  },
);
