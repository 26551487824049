import { type DocumentReview } from '@/types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchDocuments } from './actions';

interface DocumentReviewQueueState {
  documents: DocumentReview[];
  page: number;
  count: number;
  itemsPerPage: number;
  isLoading: boolean;
  error?: string;
}

const initialState: DocumentReviewQueueState = {
  documents: [],
  page: -1,
  count: 0,
  itemsPerPage: -1,
  isLoading: false,
};

export const documentReviewQueueSlice = createSlice({
  name: 'documentReviewQueueSlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDocuments.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.documents = action.payload.documents;
      state.count = action.payload.count;
      state.page = action.payload.page;
      state.itemsPerPage = action.payload.itemsPerPage;
    });
    builder.addCase(fetchDocuments.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { reset } = documentReviewQueueSlice.actions;
