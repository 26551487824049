import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import NavigationTab from './navigation-tab/NavigationTab';

import type { TabInfo } from '@/utils/interfaces';

import styles from './NavigationTabs.module.scss';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';

interface NavigationTabsProps {
  tabs: TabInfo[];
}

function NavigationTabs({ tabs }: Readonly<NavigationTabsProps>): React.JSX.Element {
  const [selectedTab, setSelectedTab] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    const tab = tabs.find(tab => location.pathname.endsWith(tab.linkPath));
    if (tab !== undefined) {
      setSelectedTab(tab.linkPath);
    }
  }, [tabs, location]);

  return (
    <ul className={styles['navigation-tabs']}>
      {tabs.map(tab =>
        tab.permission ? (
          <UserPermissionsGuard
            permission={tab.permission}
            key={tab.linkPath}
          >
            <NavigationTab
              tab={tab}
              selected={tab.linkPath === selectedTab}
              disabled={tab.disabled}
            />
          </UserPermissionsGuard>
        ) : (
          <NavigationTab
            tab={tab}
            selected={tab.linkPath === selectedTab}
            key={tab.linkPath}
            disabled={tab.disabled}
          />
        ),
      )}
    </ul>
  );
}

export default NavigationTabs;
