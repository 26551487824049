import React, { useContext } from 'react';
import Button from '@/components/common/button/Button';
import {
  TransactionActionsContext,
  type TransactionActionType,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';

interface ActionButtonProps {
  label: string;
  type: TransactionActionType;
  hide: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({ type, label, hide }) => {
  const { setSelectedTransactionAction } = useContext(TransactionActionsContext);

  return (
    <>
      {!hide && (
        <Button
          key={type}
          style={{ alignSelf: 'center' }}
          textTransform='uppercase'
          variant='secondary'
          onClick={() => setSelectedTransactionAction(type)}
        >
          {label}
        </Button>
      )}
    </>
  );
};

export default ActionButton;
