import Button from '@/components/common/button/Button';
import styles from './CountryInformation.module.scss';
import { ComplianceDocs } from '@/components/configurations';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CountryTranslation from '@/components/configurations/country-translation/CountryTranslation';
import CountryParameters from '@/components/configurations/country-parameters/CountryParameters';
import { useState } from 'react';

const CountryInformation = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation('configurations');
  useDocumentTitle(t('new-country.page-title'));

  const [countryCode, setCountryCode] = useState<string>('');

  const handleCancel = (): void => navigate('/configurations/countries');

  return (
    <div>
      <CountryParameters setCountryCode={setCountryCode} />
      <CountryTranslation />
      <ComplianceDocs countryCode={countryCode} />
      <div className={styles.buttons}>
        <Button
          onClick={handleCancel}
          variant='secondary'
          textTransform='uppercase'
        >
          {t('new-country.button-cancel')}
        </Button>
      </div>
    </div>
  );
};

export { CountryInformation };
