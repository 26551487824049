import authAxios from '@/auth/axios';
import type { DeviceInfoType } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchDeviceInfo = createAsyncThunk('risk.deviceInfo', async (phoneNumber: string, { rejectWithValue }) => {
  try {
    const { data } = await authAxios.get<{ data: any }>(`/threatmetrix/${phoneNumber}/last/10`);

    const response = data.data;
    const ipAddresses = response.riskScores
      .map((riskScore: any) => riskScore.riskData?.true_ip)
      .filter((ip: string) => ip !== undefined) as string[];

    const latestRiskScore = response.riskScores[0];
    const deviceInfo: DeviceInfoType = {
      deviceId: response.deviceId,
      phoneNumber: response.phoneNumber,
      riskScore: {
        requestDateTime: latestRiskScore.riskData.requestDateTime,
        riskData: {
          brand: latestRiskScore.riskData.agent_brand,
          model: latestRiskScore.riskData.device_model,
          os: latestRiskScore.riskData.os,
          osVersion: latestRiskScore.riskData.os_version,
          ipAddress: latestRiskScore.riskData.true_ip,
          ipCountry: latestRiskScore.riskData.true_ip_geo,
          trustedDevices: Array.from(new Set<string>(ipAddresses)),
        },
      },
    };

    return deviceInfo;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});
