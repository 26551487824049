import { useTranslation } from 'react-i18next';
import { onlyDateFormattingOptions, toUtcDateString } from '@/utils/dateTimeUtils';
import CustomerPiiField from './CustomerPiiField';
import { type FiscalNumberType } from '@/types';
import { splitCapitalizedWords } from '@/utils/helperFunctions';
import styles from './CustomerPiiField.module.scss';
import { useMemo } from 'react';

interface PersonalDataProps {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date | string;
  fiscalNumber?: string;
  fiscalNumberType?: FiscalNumberType;
}

export function PersonalData({
  firstName,
  lastName,
  dateOfBirth,
  fiscalNumber,
  fiscalNumberType,
}: Readonly<PersonalDataProps>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');

  let formattedDateOfBirth: string | undefined;
  if (dateOfBirth !== undefined) {
    formattedDateOfBirth = toUtcDateString(dateOfBirth, onlyDateFormattingOptions);
  }

  const fiscalNumberLabel = useMemo(() => {
    if (fiscalNumberType?.fiscalDataType) {
      let result = splitCapitalizedWords(fiscalNumberType.fiscalDataType).split(' ');
      result = result.map((value, index) => (index > 0 ? value.toLowerCase() : value));
      return result.join(' ');
    }
    return undefined;
  }, [fiscalNumberType]);

  return (
    <div>
      <CustomerPiiField fieldName={t('widgets.customer-pii.personal-data.first-name')}>{firstName}</CustomerPiiField>
      <CustomerPiiField fieldName={t('widgets.customer-pii.personal-data.last-name')}>{lastName}</CustomerPiiField>
      <CustomerPiiField fieldName={t('widgets.customer-pii.personal-data.dob')}>
        {formattedDateOfBirth}
      </CustomerPiiField>
      {fiscalNumber && fiscalNumberLabel && (
        <div className={styles['customer-field']}>
          <span className={styles['customer-field__label']}>
            {t('widgets.customer-pii.personal-data.fiscal-data')}:&nbsp;
          </span>
          <span className={styles['customer-field__value']}>{fiscalNumberLabel}&nbsp;</span>
          <span className={styles['customer-field__value']}>{fiscalNumber}</span>
        </div>
      )}
      {(!fiscalNumber || !fiscalNumberLabel) && (
        <CustomerPiiField fieldName={t('widgets.customer-pii.personal-data.fiscal-data')} />
      )}
    </div>
  );
}
