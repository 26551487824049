import Button from '@/components/common/button/Button';
import NumberInput from '@/components/common/inputs/NumberInput';
import { type NumberFormatValues } from 'react-number-format';
import Typography from '@/components/common/typography/Typography';
import ActionModal from '@/components/customer/kyc-risk/audit-logs/ActionModal/ActionModal';
import { useAppDispatch } from '@/lib/redux';
import { AdjustmentType, adjustBusinessAccount } from '@/lib/redux/slices/business-accounts/actions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spinner from '@/components/common/spinners/Spinner';

const ModalMessageDiv = styled.div`
  padding-top: 1.25rem;
`;

interface AmountValue {
  valueString: string;
  valueFloat: number | undefined;
}

export enum BusinessAccountActionType {
  Topup = 'topup',
  Withdraw = 'withdraw',
}

export interface BusinessAccountActionButtonProps {
  accountId: string;
  type: BusinessAccountActionType;
  minAmount?: number;
  maxAmount?: number;
  onAction?: (accountId: string, amount: number, type: BusinessAccountActionType) => void;
}

export default function BusinessAccountActionButton({
  accountId,
  type,
  minAmount = undefined,
  maxAmount = undefined,
  onAction = undefined,
}: Readonly<BusinessAccountActionButtonProps>): React.JSX.Element {
  const { t } = useTranslation('businessAccounts');

  const [openActionModal, setOpenActionModal] = useState(false);
  const [amount, setAmount] = useState<AmountValue>({
    valueString: '',
    valueFloat: 0,
  });
  const [isWaiting, setWaiting] = useState(false);

  const dispatch = useAppDispatch();

  const resetInitialAmount = (): void => {
    setAmount({ valueString: '', valueFloat: 0 });
    setWaiting(false);
  };

  const handleActionButtonClick = (): void => {
    resetInitialAmount();
    setOpenActionModal(true);
  };

  const handleActionCloseModal = (): void => {
    setOpenActionModal(false);
    resetInitialAmount();
  };

  const handleAction = async (): Promise<void> => {
    if (isValid() && amount.valueFloat) {
      setWaiting(true);

      const adjustmentType = type === BusinessAccountActionType.Topup ? AdjustmentType.Topup : AdjustmentType.Withdraw;
      const response = await dispatch(
        adjustBusinessAccount({ accountId, amount: amount.valueFloat, type: adjustmentType }),
      );
      setOpenActionModal(false);

      if (response.payload === true && amount.valueFloat && onAction !== undefined) {
        onAction(accountId, amount.valueFloat, type);
      }
    }
  };

  const handleAmountInput = (values: NumberFormatValues): void => {
    const { value, floatValue } = values;
    setAmount(() => ({ valueString: value, valueFloat: floatValue }));
  };

  const isValid = (): boolean => {
    return (
      accountId !== undefined &&
      amount.valueFloat !== undefined &&
      (minAmount === undefined || amount.valueFloat >= minAmount) &&
      (maxAmount === undefined || amount.valueFloat <= maxAmount)
    );
  };

  return (
    <>
      <Button
        onClick={handleActionButtonClick}
        textTransform='uppercase'
      >
        {t(`${type}.button`)}
      </Button>
      {openActionModal && (
        <ActionModal
          title={t(`${type}.modal-title`)}
          disabledButton={!isValid()}
          disabledModal={isWaiting}
          closeButtonLabel={t(`${type}.modal-button-close`)}
          confirmButtonLabel={t(`${type}.modal-button-confirm`)}
          onClose={handleActionCloseModal}
          onConfirm={() => {
            void (async () => {
              await handleAction();
            })();
          }}
        >
          <NumberInput
            value={amount.valueString}
            onChange={handleAmountInput}
          />
          <ModalMessageDiv>
            {!isWaiting && <Typography variant='po'>{t(`${type}.modal-message`, { accountId })}</Typography>}
            {isWaiting && <Spinner />}
          </ModalMessageDiv>
        </ActionModal>
      )}
    </>
  );
}
