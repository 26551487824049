import React from 'react';

import Modal from '@/components/common/modals/Modal';
import Typography from '@/components/common/typography/Typography';
import Button from '@/components/common/button/Button';
import { type Variant } from '@/components/common/typography/Typography';
import { ModalBox, ModalActions } from './Styles';
export interface ActionModalProps extends React.HTMLProps<HTMLDivElement> {
  onClose: () => void;
  onConfirm?: () => void;
  disabledButton: boolean;
  disabledModal?: boolean;
  title?: string;
  closeButtonLabel?: string;
  confirmButtonLabel?: string;
  titleVariant?: Variant;
}

const ActionModal: React.FC<ActionModalProps> = props => {
  const {
    onClose,
    onConfirm,
    title,
    children,
    closeButtonLabel,
    disabledButton,
    disabledModal,
    confirmButtonLabel,
    titleVariant = 'p3',
  } = props;
  return (
    <Modal onClose={onClose}>
      <ModalBox>
        {title && <Typography variant={titleVariant}>{title}</Typography>}
        <div>{children}</div>
        <ModalActions>
          {closeButtonLabel && (
            <Button
              disabled={disabledModal}
              variant='secondary'
              onClick={onClose}
              textTransform='uppercase'
            >
              {closeButtonLabel}
            </Button>
          )}
          {confirmButtonLabel && (
            <Button
              disabled={disabledButton || disabledModal}
              onClick={onConfirm}
              textTransform='uppercase'
            >
              {confirmButtonLabel}
            </Button>
          )}
        </ModalActions>
      </ModalBox>
    </Modal>
  );
};

export default ActionModal;
