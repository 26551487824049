import { lightWhite } from '@/utils/colors';

function CloudsIcon({
  width = `${14 / 16}rem`,
  height = `${15 / 16}rem`,
  color = lightWhite.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 507 160'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M405.018 60C418.825 60 431.049 65.7532 438.548 74.5905C439.597 74.5138 440.658 74.4746 441.73 74.4746C454.962 74.4746 466.552 80.4404 472.976 89.3773C474.703 89.095 476.486 88.9473 478.31 88.9473C493.806 88.9473 506.368 99.6149 506.368 112.774C506.368 125.933 493.806 136.6 478.311 136.601V136.603H357.163V136.602H357.162C337.611 136.602 321.762 123.143 321.762 106.54C321.762 89.9376 337.611 76.4785 357.162 76.4785C361.252 76.4785 365.179 77.0674 368.834 78.1508C375.866 67.3297 389.434 60 405.018 60Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M83.5397 0C97.3464 0 109.571 5.75318 117.07 14.5905C118.118 14.5138 119.179 14.4746 120.251 14.4746C133.483 14.4746 145.074 20.4404 151.498 29.3773C153.224 29.095 155.008 28.9473 156.832 28.9473C172.328 28.9473 184.89 39.6149 184.89 52.774C184.89 65.933 172.328 76.6004 156.832 76.6007V76.6027H35.6844V76.6021H35.6835C16.1325 76.6021 0.283203 63.143 0.283203 46.5403C0.283203 29.9376 16.1325 16.4785 35.6835 16.4785C39.7732 16.4785 43.7009 17.0674 47.3554 18.1508C54.3878 7.32966 67.9557 0 83.5397 0Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M198.286 115C206.321 115 213.436 118.348 217.8 123.491C218.41 123.447 219.028 123.424 219.652 123.424C227.352 123.424 234.097 126.895 237.836 132.096C238.841 131.932 239.878 131.846 240.94 131.846C249.958 131.846 257.269 138.054 257.269 145.712C257.269 153.37 249.959 159.578 240.942 159.579V159.582H170.437V159.58H170.436C159.058 159.58 149.834 151.747 149.834 142.085C149.834 132.423 159.058 124.59 170.436 124.59C172.816 124.59 175.101 124.933 177.228 125.563C181.321 119.266 189.217 115 198.286 115Z'
        fill={color}
      />
    </svg>
  );
}

export default CloudsIcon;
