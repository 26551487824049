import React from 'react';
import styled from 'styled-components';
import { extraLightGrey } from '@/utils/colors';

interface HorizontalLineProps {
  $color?: string;
  $size?: 'small' | 'medium' | 'large';
}

const Line = styled.hr<HorizontalLineProps>`
  border: none;
  border-top: 0.063rem solid ${({ $color }) => $color ?? `${extraLightGrey.cssColor}`};
  margin: auto;
  width: ${({ $size }) => {
    switch ($size) {
      case 'medium':
        return '75%';
      case 'large':
        return '100%';
      default:
        return '50%';
    }
  }};
`;

const HorizontalLine: React.FC<Readonly<HorizontalLineProps>> = ({ $color, $size = 'small' }) => {
  return (
    <Line
      $color={$color}
      $size={$size}
    />
  );
};

export default HorizontalLine;
