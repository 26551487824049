import { type PaymentCardInfo } from '@/types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchCardById } from './actions';

interface CustomerCardDetailsState {
  card?: PaymentCardInfo;
  isLoading: boolean;
  error?: string;
}

const initialState: CustomerCardDetailsState = {
  isLoading: false,
};

export const customerCardDetailsSlice = createSlice({
  name: 'customer-card-details',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCardById.pending, state => {
      state.isLoading = true;
      state.error = undefined;
      state.card = undefined;
    });
    builder.addCase(fetchCardById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.card = action.payload;
    });
    builder.addCase(fetchCardById.rejected, state => {
      state.isLoading = false;
      state.error = 'doesnt matter at this point';
    });
  },
});
