import { useState } from 'react';
import CrossIcon from '@/components/icons/CrossIcon';
import styles from './Modal.module.scss';
import { createPortal } from 'react-dom';
import { mediumGrey } from '@/utils/colors';

export default function Modal({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose: () => void;
}): React.JSX.Element {
  const [clickStartedInside, setClickStartedInside] = useState(false);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (event.target instanceof HTMLElement && event.target.closest(`.${styles.content}`)) {
      setClickStartedInside(true);
    }
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (event.target instanceof HTMLElement && !event.target.closest(`.${styles.content}`) && !clickStartedInside) {
      onClose();
    }
    setClickStartedInside(false);
  };

  return createPortal(
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className={styles.wrapper}
    >
      <div className={styles.content}>
        <button
          className={styles.closeIcon}
          onClick={() => onClose()}
        >
          <CrossIcon
            width={`${20 / 16}rem`}
            height={`${20 / 16}rem`}
            color={mediumGrey.cssColor}
            thickness='1.5'
          />
        </button>
        {children}
      </div>
    </div>,
    document.body,
  );
}
