import { useState } from 'react';
import MenuIcon from '@/components/icons/MenuIcon';
import Drawer from '@/components/common/drawer/Drawer';
import { marine, white } from '@/utils/colors';
import navigationPages from '@/components/home/navigationConfig';
import styles from './Menu.module.scss';
import { HomeIcon } from '@/components/icons/navigation-icons';
import LinkButton from '@/components/common/button/LinkButton';
import { useLocation } from 'react-router-dom';
import StyledIconButton from '@/components/common/button/StyledIconButton';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { getMainPath } from '@/utils/helperFunctions';

const Menu = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleCloseDrawer = (): void => setOpen(false);

  const { pathname } = useLocation();

  const homePage = {
    title: 'Home',
    href: '/search',
    icon: HomeIcon,
  };

  const navigationPagesHome = navigationPages.filter(item => getMainPath(item.href) !== getMainPath(pathname));

  return (
    <div className={styles.menu}>
      <StyledIconButton
        onClick={() => setOpen(true)}
        icon={<MenuIcon />}
      />

      <Drawer
        onClose={handleCloseDrawer}
        open={open}
        color={marine}
      >
        <div className={styles.drawerCard}>
          <LinkButton
            className={styles.link}
            href={homePage.href}
          >
            <homePage.icon
              width='2.25rem'
              height='2.25rem'
              color={white.cssColor}
            />
            <div className={styles.label}>{homePage.title}</div>
          </LinkButton>
          {navigationPagesHome.map(item => {
            const Icon = item.icon;
            return (
              <UserPermissionsGuard
                key={item.title}
                permission={item.permission}
              >
                <LinkButton
                  className={styles.link}
                  href={item.href}
                >
                  <Icon
                    width='2.25rem'
                    height='2.25rem'
                    color={white.cssColor}
                  />
                  <div className={styles.label}>{item.title}</div>
                </LinkButton>
              </UserPermissionsGuard>
            );
          })}
        </div>
      </Drawer>
    </div>
  );
};

export default Menu;
