import { type Countries } from '@/types';
import Table from '@/components/common/tables/Table';
import TableRow from './TableRow';
import { useTranslation } from 'react-i18next';

export default function CountriesTable({ list }: Readonly<{ list: Countries[] }>): React.JSX.Element {
  const { t } = useTranslation('configurations');

  return (
    <Table cols={4}>
      <Table.Head>{t('countries-table.countryCode')}</Table.Head>
      <Table.Head>{t('countries-table.countryName')}</Table.Head>
      <Table.Head>{t('countries-table.enabled')}</Table.Head>
      <Table.Head>{t('countries-table.icc')}</Table.Head>
      {list.map(country => {
        return (
          <TableRow
            key={country.countryCode}
            country={country}
          />
        );
      })}
    </Table>
  );
}
