enum CurrencyEnum {
  MXN = 'MXN',
  COP = 'COP',
  USD = 'USD',
  INR = 'INR',
  BRL = 'BRL',
  EUR = 'EUR',
  CLP = 'CLP',
  PEN = 'PEN',
  ARS = 'ARS',
}

export { CurrencyEnum };
