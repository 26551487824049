import { createAsyncThunk } from '@reduxjs/toolkit';
import addComment from '../../utils/addComment';
import { successToast, errorToast } from '@/utils/toastMessage';

export const addAuditLogsComment = createAsyncThunk(
  'auditLogsComment',
  async ({ customerId, comment }: { comment: string; customerId: string }, thunkApi) => {
    try {
      await addComment(comment, customerId);
      successToast('toast-messages.manual-comment');
    } catch (err: any) {
      errorToast();
      return thunkApi.rejectWithValue(err);
    }
  },
);
