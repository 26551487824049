import React, { useMemo, useState } from 'react';
import Table from '@/components/common/tables/Table';
import Typography from '@/components/common/typography/Typography';
import { type User } from '@/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MoreMenu, { type MenuAction } from '@/components/common/MoreMenu/MoreMenu';
import EditIcon from '@/components/icons/EditIcon';
import DeleteIcon from '@/components/icons/DeleteIcon';
import DeleteUserModal from './actions/DeleteUserModal';
import ChangeRoleModal from './actions/ChangeRoleModal';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { Permissions } from '@/types';

export const ActionsCell = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default function UserRow({
  user,
  currentPage,
}: Readonly<{ user: User; currentPage: number }>): React.JSX.Element {
  const { t } = useTranslation('accessManagement');

  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [openChangeRoleModal, setOpenChangeRoleModal] = useState(false);

  const roles = useMemo(() => (user.roles ? user.roles.map(role => role.roleName).join(', ') : ''), [user]);

  const handleDeleteUser = (user: User): void => {
    setOpenDeleteModal(true);
    setSelectedUser(user);
  };

  const handleCloseDeleteModal = (): void => setOpenDeleteModal(false);

  const handleChangeRole = (user: User): void => {
    setOpenChangeRoleModal(true);
    setSelectedUser(user);
  };

  const handleCloseChangeRoleModal = (): void => setOpenChangeRoleModal(false);

  const iconProps = { width: `${13 / 16}rem`, height: `${13 / 16}rem` };

  const menuActions = (user: User): MenuAction[] => [
    {
      icon: <EditIcon {...iconProps} />,
      label: t('tabs.users.action-change-role'),
      action: () => handleChangeRole(user),
    },
    ...(!user.isDeleted
      ? [
          {
            icon: <DeleteIcon />,
            label: t('tabs.users.action-delete-user'),
            action: () => handleDeleteUser(user),
          },
        ]
      : []),
  ];

  return (
    <>
      {openDeleteModal && (
        <DeleteUserModal
          onClose={handleCloseDeleteModal}
          userName={selectedUser?.userName ?? ''}
          userId={selectedUser?.id ?? ''}
          currentPage={currentPage}
        />
      )}
      {openChangeRoleModal && (
        <ChangeRoleModal
          onClose={handleCloseChangeRoleModal}
          userId={selectedUser?.id ?? ''}
          email={selectedUser?.email ?? ''}
          currentRoles={selectedUser?.roles ?? []}
          currentPage={currentPage}
        />
      )}
      <Table.Row>
        <Table.Cell>
          <Typography variant='p3Bold'>{user.userName}</Typography>
        </Table.Cell>
        <Table.Cell>{user.email}</Table.Cell>
        <Table.Cell>{roles}</Table.Cell>
        <Table.Cell>{user.isDeleted ? t('tabs.users.table.deleted') : t('tabs.users.table.active')}</Table.Cell>
        <Table.Cell>
          <UserPermissionsGuard permission={Permissions.accessManagementEdit}>
            <ActionsCell>
              <MoreMenu
                disabled={user.isDeleted}
                items={menuActions(user)}
              />
            </ActionsCell>
          </UserPermissionsGuard>
        </Table.Cell>
      </Table.Row>
    </>
  );
}
