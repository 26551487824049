import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DebounceInput from './DebounceInput';

export interface Props {
  value: [string, string];
  setValue: (value: [string, string]) => void;
  children: React.ReactNode;
}

interface ContextProps {
  dateFrom: string;
  dateTo: string;
  setDateFrom: (d: string) => void;
  setDateTo: (d: string) => void;
  dateFromError?: string;
  dateToError?: string;
}

const DateRangeInputContext = createContext<ContextProps>({
  dateFrom: '',
  dateTo: '',
  setDateFrom: () => {},
  setDateTo: () => {},
});

const DateRangeInput = ({ children, value, setValue }: Readonly<Props>): React.JSX.Element => {
  const dateFrom = value[0];
  const dateTo = value[1];
  const [dateFromError, setDateFromError] = useState('');
  const [dateToError, setDateToError] = useState('');
  const [dateFromInternal, setDateFromInternal] = useState(dateFrom);
  const [dateToInternal, setDateToInternal] = useState(dateTo);
  const { t } = useTranslation('global');
  const invalidDateLabel = t('date-range-input.invalid-date');

  useEffect(() => {
    setDateFromInternal(dateFrom);
  }, [dateFrom]);

  useEffect(() => {
    setDateToInternal(dateTo);
  }, [dateTo]);

  useEffect(() => {
    if (dateFrom && dateTo) {
      try {
        const from = new Date(dateFrom);
        const to = new Date(dateTo);
        if (from > to) {
          setDateFromError(invalidDateLabel);
          setDateToError(invalidDateLabel);
        }
      } catch (_: any) {}
    }
  }, [dateFrom, dateTo, invalidDateLabel]);

  const mySetDateFrom = useCallback(
    (date: string) => {
      setDateFromInternal(date);
      if (dateToInternal) {
        try {
          const from = new Date(date);
          const to = new Date(dateToInternal);
          if (from > to) {
            setDateFromError(invalidDateLabel);
            return;
          }
        } catch (_: any) {}
      }
      setValue([date, dateToInternal]);
      setDateFromError('');
      setDateToError('');
    },
    [setValue, dateToInternal, invalidDateLabel],
  );

  const mySetDateTo = useCallback(
    (date: string) => {
      setDateToInternal(date);
      if (dateFromInternal) {
        try {
          const from = new Date(dateFromInternal);
          const to = new Date(date);
          if (from > to) {
            setDateToError(invalidDateLabel);
            return;
          }
        } catch (_: any) {}
      }
      setValue([dateFromInternal, date]);
      setDateFromError('');
      setDateToError('');
    },
    [setValue, dateFromInternal, invalidDateLabel],
  );

  const contextValue = useMemo(
    () => ({
      dateFrom: dateFromInternal,
      dateTo: dateToInternal,
      setDateFrom: mySetDateFrom,
      setDateTo: mySetDateTo,
      dateFromError,
      dateToError,
    }),
    [dateFromInternal, dateToInternal, dateFromError, dateToError, mySetDateFrom, mySetDateTo],
  );

  return <DateRangeInputContext.Provider value={contextValue}>{children}</DateRangeInputContext.Provider>;
};

const FromInput = (): React.JSX.Element => {
  const { dateFrom, setDateFrom, dateFromError } = useContext(DateRangeInputContext);
  return (
    <DebounceInput
      onChange={setDateFrom}
      value={dateFrom}
      error={dateFromError}
      type='date'
      delay={1000}
    />
  );
};

const ToInput = (): React.JSX.Element => {
  const { dateTo, setDateTo, dateToError } = useContext(DateRangeInputContext);
  return (
    <DebounceInput
      onChange={setDateTo}
      value={dateTo}
      error={dateToError}
      type='date'
      delay={1000}
    />
  );
};

DateRangeInput.From = FromInput;
DateRangeInput.To = ToInput;

export default DateRangeInput;
