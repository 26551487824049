import React, { useMemo } from 'react';

import Table from '@/components/common/tables/Table';

import { type TransactionBA } from '@/types';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import TransactionStatusStamp from '@/components/common/transaction-status-stamp/TransactionStatusStamp';

export default function TransactionHistoryRow({
  transaction,
}: Readonly<{ transaction: TransactionBA }>): React.JSX.Element {
  const cells = useMemo(() => {
    return [
      { content: transaction.id, key: 'transactionId' },
      { content: transaction.pspTransactionId, key: 'pspTransactionId' },
      { content: <TransactionStatusStamp status={transaction.status} />, key: 'status' },
      { content: transaction.transactionType, key: 'transactionType' },
      { content: transaction.description, key: 'description' },
      { content: toUtcDateString(transaction.createdOn), key: 'createdOn' },
      { content: toUtcDateString(transaction.updatedOn), key: 'updatedOn' },
      { content: transaction.country, key: 'counry' },
      { content: `${transaction.currency} ${transaction.amount}`, key: 'currencyAmount' },
      { content: transaction.customerId, key: 'customerId' },
      { content: transaction.originEntityName, key: 'originEntityName' },
      { content: transaction.providerId, key: 'providerId' },
      { content: transaction.reasonCode, key: 'reasonCode' },
    ];
  }, [transaction]);

  return (
    <Table.Row>
      {cells.map(cell => (
        <Table.Cell key={cell.key}>{cell.content}</Table.Cell>
      ))}
    </Table.Row>
  );
}
