import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { type DocumentReview } from '@/types';
import { type RootState } from '@/lib/redux/store';

export const fetchDocuments = createAsyncThunk(
  'documentReviewQueue/fetch',
  async ({ page, itemsPerPage }: { page: number; itemsPerPage: number }, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const currentPage = rootState.documentReviewQueue.page;
    const currentItemsPerPage = rootState.documentReviewQueue.itemsPerPage;
    if (page === currentPage && currentItemsPerPage > 0 && currentItemsPerPage < itemsPerPage) {
      page = 1;
    }
    const { data } = await authAxios.get<{ data: { result: DocumentReview[]; total: number } }>(
      `/identity-docs?Limit=${itemsPerPage}&Offset=${page - 1}&DocStatus=Review`,
    );

    return {
      documents: data.data.result ?? [],
      count: data.data.total,
      page,
      itemsPerPage,
    };
  },
);
