import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { toQueryParams } from '@/utils/helperFunctions';
import { type Channel } from '@/types/Channel';

interface ChannelsSearchParams {
  transactionType?: 'Topup' | 'Transfer' | 'Withdrawal' | 'Card' | 'PaymentFromCustomer' | 'PaymentToCustomer';
  name?: string;
  limit?: number;
  offset?: number;
  includeTotal?: boolean;
}
export const searchChannels = createAsyncThunk(
  'channels-search.searchChannels',
  async (query: ChannelsSearchParams, { rejectWithValue }) => {
    const queryParams = toQueryParams(query);
    try {
      const { data } = await authAxios.get(`/channels/search${queryParams}`);
      return data.data.data as Channel[];
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
