import React from 'react';
import { useTranslation } from 'react-i18next';

import AccountManagement from '@/components/customer/overview/account-management/AccountManagement';
import AccountLimitsWidget from '@/components/customer/overview/account-limits-widget/AccountLimitsWidget';
import AccountKycWidget from '@/components/customer/account-kyc-widget/AccountKycWidget';
import HorizontalLayout from '@/components/layout/horizontal-layout/HorizontalLayout';
import PaymentCards from '@/components/customer/overview/payment-cards/PaymentCards';

import { useAppDispatch, type RootState, useAppSelector } from '@/lib/redux';
import { updateCustomerStatus } from '@/lib/redux/slices/customer/actions';
import { loginAttempts } from '@/lib/redux/slices/password/actions';
import { CustomerStatusEnum } from '@/types/Customer';
import { CurrencyEnum } from '@/types/Currencies';

import styles from './OverviewTab.module.scss';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import FeatureFlagGuard from '@/components/feature-flag/FeatureFlagGuard';
import { FeatureFlagKeys } from '@/types';

function OverviewTab(): React.JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const customer = useAppSelector((state: RootState) => state.customer.customer!);
  const { t } = useTranslation('customerOverview');
  const dispatch = useAppDispatch();
  useDocumentTitle(
    customer
      ? t('navigation-tabs.overview-page-title', { customerFullName: `${customer.firstName} ${customer.lastName}` })
      : '',
  );

  const onUnblock = (comment: string): void => {
    changeStatus(CustomerStatusEnum.ACTIVATED, comment);
  };

  const onBlock = (comment: string): void => {
    changeStatus(CustomerStatusEnum.SUSPENDED, comment);
  };

  const onCloseAccount = (comment: string): void => {
    changeStatus(CustomerStatusEnum.CLOSED, comment);
  };

  const onResetLogAttempts = (comment: string): void => {
    if (customer.customerId !== undefined && customer.phoneNumber !== undefined) {
      void dispatch(loginAttempts({ customerId: customer.customerId, phoneNumber: customer.phoneNumber, comment }));
    }
  };

  const changeStatus = (newStatus: CustomerStatusEnum, comment?: string): void => {
    if (customer.customerId !== undefined) {
      void dispatch(updateCustomerStatus({ customerId: customer.customerId, newStatus, comment: comment?.trim() }));
    }
  };

  return (
    <div className={styles['overview-tab']}>
      {customer.status !== undefined && (
        <AccountManagement
          customerStatus={customer.status}
          customerId={customer.customerId ?? ''}
          onUnblock={comment => onUnblock(comment)}
          onBlock={comment => onBlock(comment)}
          onCloseAccount={comment => onCloseAccount(comment)}
          onResetLogAttempts={comment => onResetLogAttempts(comment)}
        />
      )}
      <HorizontalLayout
        colsTemplate='1fr 1fr'
        gap='1.25rem'
      >
        <AccountKycWidget
          customerId={customer.customerId ?? ''}
          seeAllUrl={`/customer/${customer.customerId}/kyc-risk`}
        />
        <PaymentCards customerId={customer.customerId ?? ''} />
      </HorizontalLayout>
      <FeatureFlagGuard featureFlag={FeatureFlagKeys.FeatureDailyLimits}>
        <AccountLimitsWidget
          path='limit-configurations'
          currencyCode={CurrencyEnum.USD}
        />
      </FeatureFlagGuard>
    </div>
  );
}

export default OverviewTab;
