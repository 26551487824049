import Table from '@/components/common/tables/Table';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import { type AuditLogs } from '@/types';

const AuditLogsTableRow = ({ log }: { log: AuditLogs }): JSX.Element => {
  return (
    <Table.Row>
      <Table.Cell>{toUtcDateString(log.timestamp)}</Table.Cell>
      <Table.Cell>{log.event}</Table.Cell>
      <Table.Cell>{log.user}</Table.Cell>
      <Table.Cell>{log.source}</Table.Cell>
      <Table.Cell>{log.result}</Table.Cell>
      <Table.Cell>{log?.comment}</Table.Cell>
      <Table.Cell>{log.description}</Table.Cell>
      <Table.Cell>{log.category}</Table.Cell>
      <Table.Cell>{log?.ip}</Table.Cell>
      <Table.Cell>{log.deviceInfo}</Table.Cell>
    </Table.Row>
  );
};

export default AuditLogsTableRow;
