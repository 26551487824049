import TickIcon from '@/components/icons/TickIcon';
import Table from '@/components/common/tables/Table';
import WidgetBox from '../widget-box/WidgetBox';

import styles from './AccountKycWidget.module.scss';
import { useKycApplications } from '@/utils/hooks/useKycApplications';
import { useTranslation } from 'react-i18next';
import TimestampLabel from '@/components/common/timestamp-label/TimestampLabel';

export interface AccountKycWidgetProps {
  customerId: string;
  seeAllUrl?: string;
}

export default function AccountKycWidget({
  customerId,
  seeAllUrl,
}: Readonly<AccountKycWidgetProps>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');
  const kycInfo = useKycApplications(customerId);

  const idvApprovedDate = kycInfo?.idvPiiApprovedDate || kycInfo?.idvDocApprovedDate;
  const addressVerifiedDate =
    kycInfo?.addressEdvApprovedDate || kycInfo?.geolocationApprovedDate || kycInfo?.addressDocApprovedDate;

  const kycData = [
    {
      key: 'id_verif',
      name: 'ID verification',
      date: idvApprovedDate,
      icon: idvApprovedDate && <TickIcon />,
    },
    {
      key: 'addr_verif',
      name: 'Address verification',
      date: addressVerifiedDate,
      icon: addressVerifiedDate && <TickIcon />,
    },
    {
      key: 'face_match',
      name: 'Face Match',
      date: idvApprovedDate,
      icon: idvApprovedDate && <TickIcon />,
    },
    {
      key: 'edv',
      name: 'EDV Complete',
      date: kycInfo?.edvApprovedDate,
      icon: kycInfo?.edvApprovedDate && <TickIcon />,
    },
    {
      key: 'cdd',
      name: <span className={styles['main__emphasized-item']}>CDD Complete</span>,
      date: kycInfo.riskApprovedDate,
      icon: kycInfo?.isApplicationApproved && <TickIcon />,
    },
  ];

  const linkProps = seeAllUrl
    ? {
        linkPath: seeAllUrl,
        linkLabel: t('widgets.kyc-risk.see-all'),
        linkTarget: '_self',
      }
    : {
        seeAllUrl: undefined,
      };

  return (
    <WidgetBox
      title={t('widgets.kyc-risk.header-title')}
      {...linkProps}
    >
      <Table colsTemplate='1fr 3.5rem 1fr'>
        <Table.Head>
          <div />
        </Table.Head>
        <Table.Head>
          <div />
        </Table.Head>
        <Table.Head>{t('widgets.kyc-risk.timestamp-of-completion')}</Table.Head>
        {kycData.map(kyc => (
          <Table.Row key={kyc.key}>
            <Table.Cell>{kyc.name}</Table.Cell>
            <Table.Cell>{kyc.icon}</Table.Cell>
            <Table.Cell>
              <TimestampLabel timestamp={kyc.date} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>
    </WidgetBox>
  );
}
