import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { errorToast, successToast } from '@/utils/toastMessage';

export const transactionExpireCard = createAsyncThunk(
  'transaction/expireCard',
  async (
    { customerId, transactionId, statusReason }: { customerId: string; transactionId: string; statusReason: string },
    thunkApi,
  ) => {
    try {
      await authAxios.patch(`/cards/${transactionId}/expire`, { customerId, statusReason });
      successToast('toast-messages.manual-transaction');
    } catch (err: any) {
      errorToast();
      return thunkApi.rejectWithValue(err);
    }
  },
);
