import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { successToast, errorToast } from '@/utils/toastMessage';

export enum RefundTypes {
  FeeReversal = 'FeeReversal',
  FraudRefund = 'FraudRefund',
}

interface Body {
  customerId: string;
  transactionId: string;
  amount: number;
  cardTransactionType: string;
  transactionReason: string;
}

export const cardRefund = createAsyncThunk('transaction/card-refund', async ({ body }: { body: Body }, thunkApi) => {
  try {
    await authAxios.post(`/card-transactions/${body.transactionId}/refund`, body);
    successToast('toast-messages.manual-transaction');
  } catch (err: any) {
    errorToast();
    return thunkApi.rejectWithValue(err);
  }
});
