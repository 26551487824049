import { purple } from '@/utils/colors';

export default function LockOpenIcon({
  width = `${18 / 16}rem`,
  height = `${18 / 16}rem`,
  color = purple.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1750_2958)'>
        <path
          d='M14.8799 7.77588H3.11994C2.1921 7.77588 1.43994 8.53781 1.43994 9.4777V15.4341C1.43994 16.3739 2.1921 17.1359 3.11994 17.1359H14.8799C15.8078 17.1359 16.5599 16.3739 16.5599 15.4341V9.4777C16.5599 8.53781 15.8078 7.77588 14.8799 7.77588Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.1365 7.70282L12.4909 5.39382C12.6518 4.34524 12.4099 3.27878 11.8184 2.42906C11.2268 1.57934 10.3341 1.01596 9.33659 0.862863C8.33908 0.709766 7.3185 0.979489 6.49935 1.6127C6.01507 1.98705 5.62465 2.47032 5.35312 3.01955'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1750_2958'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
