import { useTranslation } from 'react-i18next';
import CloseAccountIcon from '@/components/icons/CloseAccountIcon';
import IconButton from '../IconButton';
import styles from './IconFill.module.scss';

export default function CloseAccountButton({ onClick }: Readonly<{ onClick?: () => void }>): React.JSX.Element {
  const { t } = useTranslation('global');

  return (
    <IconButton
      icon={<CloseAccountIcon />}
      label={t('buttons.close')}
      className={styles.button}
      onClick={onClick}
      textTransform='uppercase'
    />
  );
}
