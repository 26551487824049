import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AddressDocsTable from '@/components/document-review-queue/AddressDocs/Table';
import TablePagination from '@/components/common/table-pagination/TablePagination';
import Spinner from '@/components/common/spinners/Spinner';

import { type AppDispatch, type RootState } from '@/lib/redux';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { reset } from '@/lib/redux/slices/address-docs/slice';
import { fetchAddressDocuments } from '@/lib/redux/slices/address-docs/actions';

import styles from './AddressDocs.module.scss';
import usePagination from '@/utils/hooks/usePagination';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';

export default function AddressDocs(): React.JSX.Element {
  const { documents, isLoading, count, page } = useSelector((state: RootState) => state.addressDocsSlice);
  const dispatch: AppDispatch = useDispatch();
  const [currentPage, setCurrentPage] = usePagination(page);
  const [itemsPerPage] = useItemsPerPage();
  const { t } = useTranslation('documentReviewQueue');

  useDocumentTitle(t('address-docs-page-title'));

  useEffect(() => {
    void dispatch(fetchAddressDocuments({ page: currentPage, itemsPerPage }));
  }, [itemsPerPage, currentPage, dispatch]);

  useEffect(
    () => () => {
      void dispatch(reset());
    },
    [dispatch],
  );

  return (
    <>
      <div className={styles['table-wrapper']}>
        {isLoading && <Spinner />}
        {!isLoading && <AddressDocsTable documents={documents} />}
      </div>
      <TablePagination
        count={count}
        page={page}
        setPage={setCurrentPage}
      />
    </>
  );
}
