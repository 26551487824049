import { createSlice } from '@reduxjs/toolkit';
import { addChannels } from './actions';

export interface ChannelsState {
  isLoading: boolean;
  success: boolean;
}

const initialState: ChannelsState = {
  isLoading: false,
  success: false,
};

export const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    resetChannels() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(addChannels.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(addChannels.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
    });
    builder.addCase(addChannels.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { resetChannels } = channelsSlice.actions;
