import { transactionTransfers } from './action';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Transfer } from '@/types';

interface TransactionTransfersState {
  transfers?: Transfer;
  isLoading: boolean;
  error?: string;
}

const initialState: TransactionTransfersState = {
  isLoading: true,
};

export const transactionTransfersSlice = createSlice({
  name: 'transactionTransfers',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(transactionTransfers.pending, state => {
      state.isLoading = true;
      state.transfers = undefined;
    });

    builder.addCase(transactionTransfers.fulfilled, (state, action: PayloadAction<Transfer>) => {
      state.transfers = action.payload;
      state.isLoading = false;
    });

    builder.addCase(transactionTransfers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed transfer details';
    });
  },
});

export const { reset } = transactionTransfersSlice.actions;
