import authAxios from '@/auth/axios';
import { type CustomerAddressDoc } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchCustomerAddressDocuments = createAsyncThunk(
  'fetch-id-docs',
  async ({ docsId, customerId, review }: { docsId: string; customerId: string; review?: boolean }, thunkApi) => {
    try {
      const { data } = await authAxios.get<{ data: CustomerAddressDoc }>(
        `/address-docs/${review ? 'review' : 'approved'}/${docsId}`,
        {
          headers: {
            'x-customer-id': customerId,
          },
        },
      );
      return data.data;
    } catch (err: any) {
      return thunkApi.rejectWithValue(err);
    }
  },
);
