import { type FeatureFlag } from '@/types/FeatureFlag';
import styles from './FeatureFlagDetails.module.scss';
import Typography from '@/components/common/typography/Typography';
import { useTranslation } from 'react-i18next';
import Input from '@/components/common/inputs/Input';
import { AccordionTitle } from '@/components/configurations';
import Accordion from '@/components/common/accordion/Accordion';
import { type Key, useState } from 'react';
import LinkButton from '../common/button/LinkButton';

interface Props {
  flag: FeatureFlag;
  key?: Key;
}

export function FeatureFlagDetails({ flag }: Props): JSX.Element {
  const [active, setActive] = useState<boolean>(false);
  const { t } = useTranslation('configurations');

  return (
    <Accordion
      key={flag.id}
      title={<AccordionTitle title={flag.id} />}
      content={
        <div>
          <div className={styles.linkButtonCard}>
            <LinkButton
              target='_blank'
              href={`/feature-flags/history-logs/${flag.id}`}
            >
              {t('feature-flags.button-history-logs')}
            </LinkButton>
          </div>
          <div className={styles.grid}>
            <div />
            <div className={styles.container}>
              <Typography variant='p3Bold'>{t('feature-flags.enabled-phones')}</Typography>
              {flag.phoneNumbers?.map(phoneNumber => (
                <Input
                  key={phoneNumber}
                  value={phoneNumber}
                  disabled
                />
              ))}
            </div>
            <div className={styles.container}>
              <Typography variant='p3Bold'>{t('feature-flags.enabled-countries')}</Typography>
              {flag.countries?.map(country => (
                <Input
                  key={country}
                  value={country}
                  disabled
                />
              ))}
            </div>
          </div>
        </div>
      }
      isActive={active}
      onClick={() => setActive(!active)}
    />
  );
}
