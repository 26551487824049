import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';

export const transactionAdjustment = createAsyncThunk(
  'transaction/adjustment',
  async ({ transactionId }: { transactionId: string }, thunkApi) => {
    try {
      const { data } = await authAxios.get(`/adjustments/${transactionId}`);
      return data.data;
    } catch (err: any) {
      return thunkApi.rejectWithValue(err);
    }
  },
);
