import StatusStamp from '@/components/common/status-stamp/StatusStamp';
import { CustomerStatusEnum } from '@/types';
import { isKeyOfEnum } from '@/utils/helperFunctions';
import { type StatusVariants } from '@/utils/statusVariants';
import { useMemo } from 'react';

interface Props {
  status?: CustomerStatusEnum;
  noBorder?: boolean;
}

export default function CustomerStatusStamp({ status, noBorder }: Readonly<Props>): React.JSX.Element {
  const memoizedValues = useMemo(() => {
    let statusVariant: StatusVariants;
    let statusValue: string = '';
    if (status && isKeyOfEnum(CustomerStatusEnum, status)) {
      statusValue = CustomerStatusEnum[status];
    }

    switch (statusValue) {
      case CustomerStatusEnum.ACTIVATED:
        statusVariant = 'successMedium';
        break;
      case CustomerStatusEnum.PENDING_ONBOARDING:
      case CustomerStatusEnum.UNDER_VERIFICATION:
      case CustomerStatusEnum.SUSPENDED:
        statusVariant = 'warningMedium';
        break;
      case CustomerStatusEnum.VERIFIED:
      case CustomerStatusEnum.ONBOARDED:
        statusVariant = 'warningLight';
        break;
      default:
        statusVariant = 'errorDark';
        break;
    }

    return { statusVariant, status: statusValue.toUpperCase() };
  }, [status]);

  return (
    <StatusStamp
      status={memoizedValues.status}
      variant={memoizedValues.statusVariant}
      noBorder={noBorder}
    />
  );
}
