import Navigation from './navigation/Navigation';

import LogoWrapper from '../../common/logo-wrapper/LogoWrapper';
import { marine } from '@/utils/colors';

import styles from './Header.module.scss';
import { Link } from 'react-router-dom';
import { createContext, useMemo } from 'react';

interface HeaderContextProps {
  hideDocQueue?: boolean;
  hideHamburger?: boolean;
}

export const HeaderContext = createContext<HeaderContextProps>({
  hideDocQueue: false,
  hideHamburger: false,
});

function Header({
  hideDocQueue,
  hideHamburger,
}: Readonly<{ hideDocQueue?: boolean; hideHamburger?: boolean }>): React.JSX.Element {
  const headerContextValue = useMemo(() => {
    return { hideDocQueue, hideHamburger };
  }, [hideDocQueue, hideHamburger]);

  return (
    <HeaderContext.Provider value={headerContextValue}>
      <header className={styles.header}>
        <Link to='/search'>
          <LogoWrapper logoColor={marine} />
        </Link>
        <Navigation />
      </header>
    </HeaderContext.Provider>
  );
}

export default Header;
