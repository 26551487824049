import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionStatus, TransactionType } from '@/types';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import {
  type ActionProps,
  TransactionActionsContext,
  TransactionActionType,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';
import ActionButton from '@/components/customer/transaction-history/transaction-actions/ActionButton';
import { useParams } from 'react-router-dom';
import { reset as expireCardReset } from '@/lib/redux/slices/transaction-expire-card/slice';
import { transactionExpireCard } from '@/lib/redux/slices/transaction-expire-card/actions';
import ActionInputReason from '@/components/customer/transaction-history/transaction-actions/ActionInputReason';

const ExpireCardAction: React.FC<ActionProps> = ({ onClose, transaction, setAccessibility }) => {
  const { selectedTransactionAction } = useContext(TransactionActionsContext);
  const { isLoading, success } = useAppSelector((state: RootState) => state.transactionExpireCardSlice);
  const { t } = useTranslation(['customerOverview', 'global']);
  const dispatch = useAppDispatch();
  const { customerId } = useParams<{ customerId: string }>();

  const isExpireCardAllowed =
    transaction?.transactionType === TransactionType.Card && transaction.status === TransactionStatus.InProgress;

  useEffect(() => {
    if (success) {
      void dispatch(expireCardReset());
      onClose({ success: true, refreshTransaction: true, disableNavigate: true });
    }
  }, [success, dispatch, onClose]);

  useEffect(() => {
    setAccessibility(isExpireCardAllowed);
    return () => {
      setAccessibility(undefined);
    };
  }, [isExpireCardAllowed, setAccessibility]);

  const handleConfirm = (reason: string): void => {
    if (transaction?.slipId) {
      void dispatch(
        transactionExpireCard({
          customerId: customerId ?? '',
          transactionId: transaction.transactionId ?? '',
          statusReason: reason,
        }),
      );
    }
  };

  if (selectedTransactionAction === undefined) {
    return (
      <ActionButton
        type={TransactionActionType.ExpireCard}
        hide={!isExpireCardAllowed}
        label='Expire Card'
      />
    );
  }
  if (selectedTransactionAction === TransactionActionType.ExpireCard) {
    return (
      <ActionInputReason
        disabledButton={isLoading}
        onConfirm={handleConfirm}
        onClose={onClose}
        reasonLabel={t('widgets.transaction-history.modal-input-reason-expire')}
      ></ActionInputReason>
    );
  }

  return null;
};

export default ExpireCardAction;
