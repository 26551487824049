import { Link } from 'react-router-dom';
import style from './BackButton.module.scss';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon';

type ButtonActionProps =
  | { href: string; target?: React.HTMLAttributeAnchorTarget; onClick?: never }
  | { onClick: () => void; href?: never; target?: never };
type BackButtonProps = { children: React.ReactNode } & ButtonActionProps;

export default function BackButton({ children, href, target, onClick }: BackButtonProps): React.JSX.Element {
  if (onClick !== undefined) {
    return (
      <button
        className={style.link}
        onClick={onClick}
      >
        <ArrowLeftIcon /> {children}
      </button>
    );
  } else {
    return (
      <Link
        className={style.link}
        to={href}
        target={target}
      >
        <ArrowLeftIcon /> {children}
      </Link>
    );
  }
}
