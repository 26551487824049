const errorKeyUserNotFound: string = 'error-messages.customer-not-found';
const errorKeyTrnNotFound: string = 'error-messages.transaction-not-found';
const errorKeyPspTrnNotFound: string = 'error-messages.psp-transaction-not-found';
const errorKeySlipNotFound: string = 'error-messages.slip-not-found';
const errorKeyGeneralError: string = 'error-messages.general-error';
const errorKeyInsufficientPermissions: string = 'error-messages.insufficient-permissions';
const errorKeySearchMethodNotSupported: string = 'error-messages.search-method-not-supported';

export {
  errorKeyUserNotFound,
  errorKeyTrnNotFound,
  errorKeySlipNotFound,
  errorKeyGeneralError,
  errorKeyInsufficientPermissions,
  errorKeySearchMethodNotSupported,
  errorKeyPspTrnNotFound,
};
