import { createAsyncThunk } from '@reduxjs/toolkit';
import { type RootState } from '@/lib/redux/store';
import { type Transaction } from '@/types';
import authAxios from '@/auth/axios';
import { errorToast } from '@/utils/toastMessage';

export interface TransactionHistoryResponse {
  transactions: Transaction[];
  page: number;
  count: number;
  itemsPerPage: number;
}

interface TransactionHistoryRequest {
  customerId: string;
  page?: number;
  itemsPerPage: number;
  transactionId?: string;
  pspTransactionId?: string;
  slipId?: string;
  transactionTypes?: string[];
  transactionStatus?: string[];
  dateFrom?: string;
  dateTo?: string;
}

interface TransactionHistoryRejectedValue {
  page: number;
  total: number;
}

export const fetchTransactions = createAsyncThunk<
  TransactionHistoryResponse,
  TransactionHistoryRequest,
  {
    rejectValue: TransactionHistoryRejectedValue;
  }
>(
  'transactionHistory/fetch',
  async (
    {
      customerId,
      page,
      itemsPerPage,
      transactionId,
      slipId,
      transactionTypes,
      transactionStatus,
      dateFrom,
      dateTo,
      pspTransactionId,
    },
    thunkApi,
  ) => {
    const rootState = thunkApi.getState() as RootState;
    try {
      const currentPage = rootState.transactionHistory.page;
      if (page === currentPage) {
        page = 1;
      }
      if (page === undefined) {
        page = currentPage;
      }

      const offset = (page - 1) * itemsPerPage;
      const body: Partial<{
        transactions: string[];
        types: string[];
        statuses: string[];
        dateFrom: string;
        dateTo: string;
        offset: number;
        limit: number;
        includeTotal: boolean;
        showHiddenTransactions: boolean;
        slipId: string;
        pspTransactions: string[];
      }> = { offset, limit: itemsPerPage, slipId, includeTotal: true };

      if (transactionId) {
        body.transactions = [transactionId];
      }

      if (pspTransactionId) {
        body.pspTransactions = [pspTransactionId];
      }

      if (transactionTypes) {
        body.types = transactionTypes;
      }

      if (transactionStatus) {
        body.statuses = transactionStatus;
      }

      if (dateFrom) {
        body.dateFrom = `${dateFrom}T00:00:00.000Z`;
      }

      if (dateTo) {
        body.dateTo = `${dateTo}T23:59:59.999Z`;
      }

      const response = await authAxios.post('/transactions/search', body, {
        headers: {
          'x-customer-id': customerId,
        },
      });

      const data = response.data;

      return {
        transactions: data.data ?? [],
        count: data.total,
        page,
        itemsPerPage,
      };
    } catch (err: any) {
      errorToast();
      let total = rootState.transactionHistory.count;
      if (total < 1) {
        total = (itemsPerPage ?? 0) * (page ?? 1);
      }
      return thunkApi.rejectWithValue({ page: page ?? 1, total });
    }
  },
);
