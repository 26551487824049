import ArrowIcon from '@/components/icons/ArrowIcon';
import { marine, purple } from '@/utils/colors';
import { useMemo } from 'react';
import styled from 'styled-components';

const MainDiv = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
`;

const BaseItem = styled.div`
  width: calc(1.5rem - 2px);
  height: calc(1.5rem - 2px);
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
`;

const ClickableItem = styled(BaseItem)`
  cursor: pointer;
  border-radius: 0.5rem;

  &:hover {
    border-color: ${marine.cssColor};
  }

  &:active {
    background-color: ${purple.cssColor};
    border-color: ${purple.cssColor};
    color: ${marine.cssColor};
  }
`;

const ArrowDiv = styled(ClickableItem).withConfig({
  shouldForwardProp: prop => !['rotation'].includes(prop),
})<{ rotation: string }>`
  > * {
    transform: rotate(${({ rotation }) => `${rotation}deg`});
  }
`;

const NumberDiv = styled(ClickableItem).withConfig({
  shouldForwardProp: prop => !['isSelected'].includes(prop),
})<{ isSelected: boolean }>`
  background-color: ${props => (props.isSelected ? purple.cssColor : 'transparent')};
  color: ${props => (props.isSelected ? marine.cssColor : 'inherit')};
  border: 1px solid ${props => (props.isSelected ? purple.cssColor : 'transparent')};

  &:hover {
    border-color: ${props => (!props.isSelected ? marine.cssColor : 'transparent')};
  }
`;

const DotsDiv = styled(BaseItem)``;

export interface PaginationProps {
  currentPage: number;
  setPage: (page: number) => void;
  count: number;
}
export default function Pagination({ currentPage, setPage, count }: Readonly<PaginationProps>): React.JSX.Element {
  const middleValue = useMemo(() => {
    let value = Math.max(3, currentPage);
    value = Math.min(value, count - 2);
    value = Math.max(value, 3);
    return value;
  }, [currentPage, count]);

  const onLeftArrowClick = (): void => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  };
  const onRightArrowClick = (): void => {
    if (currentPage < count) {
      setPage(currentPage + 1);
    }
  };

  return (
    <MainDiv>
      <ArrowDiv
        rotation='90'
        onClick={onLeftArrowClick}
      >
        <ArrowIcon />
      </ArrowDiv>
      <NumberDiv
        isSelected={currentPage === 1}
        onClick={() => setPage(1)}
      >
        1
      </NumberDiv>
      {count > 1 &&
        (currentPage > 3 && count > 5 ? (
          <DotsDiv>...</DotsDiv>
        ) : (
          <NumberDiv
            isSelected={currentPage === 2}
            onClick={() => setPage(2)}
          >
            2
          </NumberDiv>
        ))}
      {count > 2 && (
        <NumberDiv
          isSelected={currentPage === middleValue}
          onClick={() => setPage(middleValue)}
        >
          {middleValue}
        </NumberDiv>
      )}
      {count > 4 &&
        (currentPage < count - 2 && count > 5 ? (
          <DotsDiv>...</DotsDiv>
        ) : (
          <NumberDiv
            isSelected={currentPage === count - 1}
            onClick={() => setPage(count - 1)}
          >
            {count - 1}
          </NumberDiv>
        ))}
      {count > 3 && (
        <NumberDiv
          isSelected={currentPage === count}
          onClick={() => setPage(count)}
        >
          {count}
        </NumberDiv>
      )}
      <ArrowDiv
        rotation='-90'
        onClick={onRightArrowClick}
      >
        <ArrowIcon
          width='12'
          height='12'
        />
      </ArrowDiv>
    </MainDiv>
  );
}
