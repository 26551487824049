import styles from './FirstOrnament.module.scss';

function FirstOrnament(): React.JSX.Element {
  return (
    <svg
      className={styles['search-page__first-ornament']}
      width='531'
      height='431'
      viewBox='0 0 531 431'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M448.95 549.452C500.655 498.595 575 326 459.938 214.124C344.876 102.248 165 189 127.646 225.789C63.0188 289.437 168.648 346.771 196.361 257.307C224.075 167.843 31.8184 -39.5721 -101.97 21.5599'
        stroke='white'
        strokeWidth='20'
      />
    </svg>
  );
}

export default FirstOrnament;
