import { marine } from '@/utils/colors';
import { ClipLoader } from 'react-spinners';

export default function Spinner(): React.JSX.Element {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
      }}
    >
      <ClipLoader
        color={marine.cssColor}
        loading={true}
        cssOverride={{
          display: 'block',
          margin: 'auto auto',
        }}
      />
    </div>
  );
}
