import { cardRefund } from './actions';
import { createSlice } from '@reduxjs/toolkit';

interface CardRefundState {
  success: boolean;
  isLoading: boolean;
  error?: string;
}

const initialState: CardRefundState = {
  isLoading: false,
  success: false,
};

export const cardRefundSlice = createSlice({
  name: 'cardRefund',
  initialState,
  reducers: {
    resetCardRefund() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(cardRefund.pending, state => {
      state.isLoading = true;
      state.success = false;
    });

    builder.addCase(cardRefund.fulfilled, state => {
      state.success = true;
      state.isLoading = false;
    });

    builder.addCase(cardRefund.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed refund';
    });
  },
});

export const { resetCardRefund } = cardRefundSlice.actions;
