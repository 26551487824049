import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';

export const transactionCustomerPayment = createAsyncThunk(
  'transaction/payments',
  async ({ transactionId, customerId }: { transactionId: string; customerId: string }, thunkApi) => {
    try {
      const { data } = await authAxios.get(`/payments/${transactionId}`, {
        headers: { 'x-customer-id': customerId },
      });
      return data.data;
    } catch (err: any) {
      return thunkApi.rejectWithValue(err);
    }
  },
);
