import classNames from 'classnames';
import type { Color } from '@/utils/colors';

import styles from './VerticalLine.module.scss';

type Size = 'small' | 'medium' | 'large';

interface VerticalLineProps {
  color: Color;
  size?: Size;
}

function VerticalLine({ color, size = 'small' }: Readonly<VerticalLineProps>): React.JSX.Element {
  const verticalLineStyles = classNames(
    styles['vertical-line'],
    styles[`vertical-line${color.modifier}`],
    styles[`vertical-line--${size}`],
  );

  return <div className={verticalLineStyles}></div>;
}

export default VerticalLine;
