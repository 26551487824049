import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { type AuditLogs } from '@/types';
import { type RootState } from '@/lib/redux/store';

interface AuditLogsAction {
  customerId: string;
  page: number;
  itemsPerPage: number;
  order: string;
}

export const getAuditLogs = createAsyncThunk(
  'getAuditLogs/auditLogs',
  async ({ customerId, page, itemsPerPage, order }: AuditLogsAction, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const currentPage = rootState.auditLogsSlice.page;
    const currentItemsPerPage = rootState.auditLogsSlice.itemsPerPage;
    if (page === currentPage && currentItemsPerPage > 0 && currentItemsPerPage < itemsPerPage) {
      page = 1;
    }
    const { data } = await authAxios.get<{ data: { data: AuditLogs[]; total: number } }>(
      `/audit/customer-events?limit=${itemsPerPage}&offset=${page - 1}&order=${order}`,
      { headers: { 'x-customer-id': customerId } },
    );

    return {
      auditLogs: data.data.data ?? [],
      count: data.data.total,
      page,
      itemsPerPage,
    };
  },
);
