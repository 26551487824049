import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '@/components/common/filters/Dropdown';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import NumberInput from '@/components/common/inputs/NumberInput';
import { type RootState, useAppSelector } from '@/lib/redux';
import { TransactionType } from '@/types';
import { type NumberFormatValues } from 'react-number-format';
import { type Refund } from './RefundModal';
import { type Option } from '@/utils/interfaces';
import styles from './RefundModalInputs.module.scss';
import Typography from '@/components/common/typography/Typography';
import { mediumPurple } from '@/utils/colors';
import HighlightingLabel from '@/components/common/highlighting-label/HighlightingLabel';

interface RefundModalInputsProps {
  refund: Refund;
  setRefund: React.Dispatch<React.SetStateAction<Refund>>;
}

const RefundModalInputs: React.FC<RefundModalInputsProps> = ({ refund, setRefund }): JSX.Element => {
  const { t } = useTranslation(['customerOverview', 'global']);

  const { accounts } = useAppSelector((state: RootState) => state.accounts);

  const transactionTypeOptions = [
    {
      text: t('widgets.transaction-history.refund-modal.wallet-refund-debit'),
      value: TransactionType.WalletRefundDebit,
    },
    {
      text: t('widgets.transaction-history.refund-modal.wallet-refund-credit'),
      value: TransactionType.WalletRefundCredit,
    },
  ];

  const currencyOptions = [
    ...(accounts?.primaryAccount
      ? [{ text: accounts.primaryAccount.currencyCode, value: accounts.primaryAccount.accountId }]
      : []),
    ...(accounts?.secondaryAccount
      ? [
          {
            text: accounts.secondaryAccount.currencyCode,
            value: accounts.secondaryAccount.accountId,
          },
        ]
      : []),
  ];

  const handleTransactionTypeSelect = (opt: Option): void => {
    setRefund((prevRefund: Refund) => ({
      ...prevRefund,
      transactionType: { text: opt.text, value: opt?.value },
    }));
  };

  const handleCurrencySelect = (opt: Option): void => {
    setRefund((prevRefund: Refund) => ({
      ...prevRefund,
      currency: { text: opt.text, value: opt?.value },
    }));
  };

  const handleAmount = (values: NumberFormatValues): void => {
    const { value, floatValue } = values;
    setRefund((prevRefund: Refund) => ({
      ...prevRefund,
      requestedAmountNumber: floatValue ?? 0,
      requestedAmountString: value,
    }));
  };

  const handleReasonChange = (newReason: string): void => {
    setRefund((prevRefund: Refund) => ({
      ...prevRefund,
      reason: newReason,
    }));
  };

  return (
    <div className={styles.card}>
      <div className={styles.inputsCard}>
        <div className={styles.dropdownCard}>
          <InputLabel label={t('widgets.transaction-history.refund-modal.transaction-type')}>
            <Dropdown
              options={transactionTypeOptions}
              onSelect={handleTransactionTypeSelect}
              selectedOption={refund.transactionType}
              setSelectedOption={() => {}}
            />
          </InputLabel>
          <InputLabel label={t('widgets.transaction-history.refund-modal.currency')}>
            <Dropdown
              options={currencyOptions}
              onSelect={handleCurrencySelect}
              selectedOption={refund.currency}
              setSelectedOption={() => {}}
            />
          </InputLabel>
        </div>
        <div className={styles.amountInput}>
          <InputLabel label={t('widgets.transaction-history.refund-modal.amount')}>
            <NumberInput
              value={refund.requestedAmountString}
              onChange={handleAmount}
            />
          </InputLabel>
        </div>
      </div>
      <InputLabel label={t('widgets.transaction-history.refund-modal.reason')}>
        <Input
          value={refund.reason}
          onChange={handleReasonChange}
        />
      </InputLabel>
      <div>
        <Typography
          color={mediumPurple.cssColor}
          variant='p3'
        >
          {t('widgets.transaction-history.refund-modal.account-id')}
        </Typography>
        <Typography variant='p2Medium'>
          <HighlightingLabel label={refund.currency.value} />
        </Typography>
      </div>
    </div>
  );
};
export default RefundModalInputs;
