import { Navigate } from 'react-router-dom';

import { featureFlagsData, type FeatureFlagKeys } from '@/types';
import { AppUrls } from '@/utils/constants';

export default function FeatureFlagGuard({
  featureFlag,
  children,
  redirect,
}: Readonly<{
  featureFlag: FeatureFlagKeys;
  children: React.JSX.Element;
  redirect?: string | boolean;
}>): React.JSX.Element | null {
  if (featureFlagsData[featureFlag]) {
    return children;
  }

  const shouldRedirect = redirect !== undefined;
  if (shouldRedirect) {
    return <Navigate to={typeof redirect === 'string' ? redirect : AppUrls.NotFoundUrl} />;
  }

  return null;
}
