import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@/components/common/typography/Typography';
import ActionModal from '@/components/customer/kyc-risk/audit-logs/ActionModal/ActionModal';
import { deleteUser, fetchUsers } from '@/lib/redux/slices/users/actions';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';

interface DeleteUserModalProps {
  userName: string;
  userId: string;
  currentPage: number;
  onClose: () => void;
}

const DeleteUserModal = ({ userName, userId, currentPage, onClose }: DeleteUserModalProps): JSX.Element => {
  const { t } = useTranslation(['accessManagement', 'global']);
  const [itemsPerPage] = useItemsPerPage();

  const { deleteSuccess, deleteLoading } = useAppSelector((state: RootState) => state.users);

  const dispatch = useAppDispatch();

  const handleCloseActionModal = (): void => {
    onClose();
  };

  useEffect(() => {
    if (deleteSuccess) {
      onClose();
      void dispatch(fetchUsers({ page: currentPage, itemsPerPage }));
    }
  }, [deleteSuccess, onClose, currentPage, itemsPerPage, dispatch]);

  const handleConfirm = (): void => {
    void dispatch(deleteUser({ userId }));
  };

  const label: string = t('tabs.users.delete-user-modal.label');

  return (
    <ActionModal
      disabledButton={deleteLoading}
      confirmButtonLabel={t('confirm-modal.yes', { ns: 'global' })}
      closeButtonLabel={t('confirm-modal.no', { ns: 'global' })}
      onClose={handleCloseActionModal}
      onConfirm={handleConfirm}
    >
      <Typography variant='po'>{`${label} ${userName}?`}</Typography>
    </ActionModal>
  );
};

export default DeleteUserModal;
