import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Exchange } from '@/types/Exchange';
import { fetchTransactionExchange } from '@/lib/redux/slices/transaction-exchange/actions';

interface TransactionExchangeState {
  isLoading: boolean;
  error?: string;
  exchange?: Exchange;
}

const initialState: TransactionExchangeState = {
  isLoading: true,
};
export const transactionExchangeSlice = createSlice({
  name: 'detailTransactionExchangeSlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTransactionExchange.pending, state => {
      state.isLoading = true;
      state.error = undefined;
      state.exchange = undefined;
    });

    builder.addCase(fetchTransactionExchange.fulfilled, (state, action: PayloadAction<Exchange>) => {
      return {
        exchange: action.payload,
        isLoading: false,
        error: undefined,
      };
    });

    builder.addCase(fetchTransactionExchange.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed to fetch exchange transaction';
    });
  },
});

export const { reset } = transactionExchangeSlice.actions;
