import authAxios from '@/auth/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { successToast, errorToast } from '@/utils/toastMessage';
import addComment from '../../utils/addComment';

interface LoginAttempts {
  phoneNumber: string;
  customerId: string;
  comment?: string;
}

export const loginAttempts = createAsyncThunk(
  'password.loginAttempts',
  async ({ phoneNumber, customerId, comment }: LoginAttempts, { rejectWithValue }) => {
    try {
      await authAxios.put(`users/${phoneNumber}/login-blocks`,
        { loginAttempts: 10 },
      );
      if (comment) {
        try {
          await addComment(comment, customerId);
        } catch (_: any) {}
      }
      successToast('toast-messages.password-login-attempts-reset');
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
