import { useEffect, useMemo, useRef, useState } from 'react';
import type { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@/components/common/button/Button';

import type { DisplayType, Option, SearchFeaturesSettings } from '@/utils/interfaces';
import {
  errorKeyGeneralError,
  errorKeyInsufficientPermissions,
  errorKeySearchMethodNotSupported,
} from '@/utils/errorMessages';

import styles from './SearchComponent.module.scss';
import SearchDropdown from '../common/search-dropdown/SearchDropdown';
import VerticalLine from '../common/vertical-line/VerticalLine';
import { type Color, extraLightGrey, white, purple, boldGrey } from '@/utils/colors';
import classNames from 'classnames';
import SearchIcon from '../icons/SearchIcon';
import authAxios from '@/auth/axios';
import {
  extractParamsFromResponse,
  formatParameterizedString,
  replaceStringValuesInObject,
} from '@/utils/helperFunctions';
import { SEARCH_SETTINGS } from './configs';

function SearchComponent({
  type = 'block',
  primaryColor = white,
  autoFocus = false,
}: Readonly<{
  type?: DisplayType;
  primaryColor?: Color;
  autoFocus?: boolean;
}>): React.JSX.Element {
  const { t, i18n } = useTranslation('global');

  const dropdownOptions: Option[] = useMemo(() => {
    return [
      { text: t('search-component.email'), value: 'email' },
      { text: t('search-component.phone-number'), value: 'phoneNumber' },
      { text: t('search-component.customer-id'), value: 'customerId' },
      { text: t('search-component.kuady-transation-id'), value: 'kuadyTrnId' },
      { text: t('search-component.slip-id'), value: 'slipId' },
      { text: t('search-component.psp-transaction-id'), value: 'pspTransactionId' },
    ];
  }, [t]);

  const [searchInputValue, setSearchInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(dropdownOptions[0]);
  const [errorKey, setErrorKey] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedOption(prevState => {
      const selectedOption = dropdownOptions.find(dropdownOption => dropdownOption.value === prevState.value);
      return selectedOption ?? dropdownOptions[0];
    });
  }, [dropdownOptions, t, i18n]);

  const inputBoxClasses = classNames(
    styles['search__input-box'],
    styles[`search__input-box${primaryColor.modifier}`],
    styles[`search__input-box--${type}`],
    {
      [styles['search__input-box--error']]: errorKey,
    },
  );

  const inputClasses = classNames(styles['search__input-value'], styles[`search__input-value${primaryColor.modifier}`]);

  const onDropdownSelect = (): void => {
    inputRef.current?.focus();
    setErrorKey('');
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInputValue(event.target.value.trim());
    setErrorKey('');
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === 'Enter') {
      onSearchClick();
    }
  };

  const onSearchClick = (): void => {
    const searchSettings = SEARCH_SETTINGS[selectedOption.value as keyof SearchFeaturesSettings];

    if (searchSettings === undefined) {
      setErrorKey(errorKeySearchMethodNotSupported);
      return;
    }

    let searchValue = searchInputValue;
    if (searchSettings.sanitizeSearchValue) {
      searchValue = searchSettings.sanitizeSearchValue(searchValue);
    }

    if (!searchSettings.validationRegex.test(searchValue)) {
      setErrorKey(searchSettings.validationErrorMessage);
      return;
    }

    setErrorKey('');

    const searchParameters: Record<string, string> = {
      searchValue,
    };

    const requestConfig: AxiosRequestConfig = {
      headers: {},
      params: {},
    };
    if (requestConfig.headers !== undefined) {
      for (const key in searchSettings.headers) {
        requestConfig.headers[key] = formatParameterizedString(searchSettings.headers[key], searchParameters);
      }
    }

    if (requestConfig.params !== undefined) {
      for (const key in searchSettings.queryParams) {
        requestConfig.params[key] = formatParameterizedString(searchSettings.queryParams[key], searchParameters);
      }
    }

    let axiosRequest: Promise<AxiosResponse<any, any>>;
    if (searchSettings.body) {
      axiosRequest = authAxios.post(
        searchSettings.serviceUrl,
        replaceStringValuesInObject(searchSettings.body, searchParameters),
      );
    } else {
      axiosRequest = authAxios.get(
        formatParameterizedString(searchSettings.serviceUrl, searchParameters),
        requestConfig,
      );
    }
    axiosRequest
      .then(response => {
        if (response.status === 200) {
          let params;
          if (searchSettings.extractParamsFromResponse) {
            params = searchSettings.extractParamsFromResponse(
              response.data,
              searchSettings.pathsInResponse,
              extractParamsFromResponse,
            );
          } else {
            params = extractParamsFromResponse(response.data, searchSettings.pathsInResponse);
          }
          if (!params) {
            setErrorKey(searchSettings.notFoundErrorMessage);
          } else {
            navigate(formatParameterizedString(searchSettings.navigationUrl, { searchValue, ...params }));
            setSearchInputValue('');
          }
        } else {
          setErrorKey(searchSettings.notFoundErrorMessage);
        }
      })
      .catch((error: AxiosError) => {
        switch (error.response?.status) {
          case 400:
          case 404:
            setErrorKey(searchSettings.notFoundErrorMessage);
            break;
          case 403:
            setErrorKey(errorKeyInsufficientPermissions);
            break;
          default:
            setErrorKey(errorKeyGeneralError);
        }
      });
  };

  const determineVerticalLineColor = (primaryColor: Color): Color => {
    if (primaryColor === purple) {
      return boldGrey;
    }

    return extraLightGrey;
  };

  return (
    <div
      className={styles.search}
      onKeyDown={event => onKeyDown(event)}
    >
      {type === 'block' && <h1>{t('search-component.header')}</h1>}

      <div className={inputBoxClasses}>
        <SearchDropdown
          primaryColor={primaryColor}
          options={dropdownOptions}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          onSelect={onDropdownSelect}
          displayType={type}
        />

        <VerticalLine color={determineVerticalLineColor(primaryColor)}></VerticalLine>

        <input
          autoFocus={autoFocus}
          ref={inputRef}
          className={inputClasses}
          value={searchInputValue}
          onChange={event => onInputChange(event)}
        />

        {type === 'inline' && (
          <button
            className={styles.search__icon}
            onClick={onSearchClick}
            onKeyDown={event => onKeyDown(event)}
          >
            <SearchIcon />
          </button>
        )}
      </div>

      {type === 'block' && errorKey && (
        <span className={styles['search__error-message']}>{t(errorKey as keyof typeof i18n.options.defaultNS)}</span>
      )}

      {type === 'block' && (
        <Button
          className={styles.search__button}
          onClick={onSearchClick}
          textTransform='uppercase'
        >
          {t('search-component.search')}
        </Button>
      )}
    </div>
  );
}

export default SearchComponent;
