import { type CustomerIdentityDoc } from '@/types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchIdentityDocuments } from './actions';

export interface IdentityDocumentsSliceState {
  loading: boolean;
  identityDocs?: CustomerIdentityDoc | null;
}

const initialState: IdentityDocumentsSliceState = {
  loading: true,
};

export const identityDocumentsSlice = createSlice({
  name: 'identity-documents',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchIdentityDocuments.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchIdentityDocuments.fulfilled, (state, action) => {
      state.loading = false;
      state.identityDocs = action.payload ? action.payload : null;
    });
    builder.addCase(fetchIdentityDocuments.rejected, state => {
      state.loading = false;
      state.identityDocs = null;
    });
  },
});
