import Button from './Button';
import type { ButtonProps } from './Button';
import styles from './IconButton.module.scss';

interface Props extends ButtonProps {
  icon: React.ReactNode;
  label: string;
}

export default function IconButton({ icon, label, className, ...rest }: Readonly<Props>): React.JSX.Element {
  return (
    <Button
      className={`${styles.button} ${className ?? ''}`}
      {...rest}
    >
      {icon}
      <span>{label}</span>
    </Button>
  );
}
