import React from 'react';
import Typography from '@/components/common/typography/Typography';
import { normalGrey } from '@/utils/colors';
import styled from 'styled-components';
interface HeaderWrapperProps {
  $marginBottom?: string; // Define margin prop
}

const HeaderWrapper = styled.div<HeaderWrapperProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? '0px'};
`;
interface CardHeaderProps {
  title: string;
  subHeader?: string;
  marginBottom?: string;
}
const CardHeader: React.FC<CardHeaderProps> = ({ title, subHeader, marginBottom }) => {
  return (
    <HeaderWrapper $marginBottom={marginBottom}>
      <Typography variant='p3Medium'>{title}</Typography>
      <Typography
        color={normalGrey.cssColor}
        variant='p4'
      >
        {subHeader}
      </Typography>
    </HeaderWrapper>
  );
};

export default CardHeader;
