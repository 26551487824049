import styled from 'styled-components';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 160px;
  min-width: 270px;
  border: 1px solid #c7c4d0;
  border-radius: 0.625rem;
  padding: 0.375rem 0.75rem;
`;

const CardIfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionCard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.375rem;
  height: 1.875rem;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;

export { CardWrapper, CardContent, CardIfo, CardDetails, ActionCard, Info };
