import { type PaymentCardInfo } from '@/types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchPaymentCards } from './actions';

interface CustomerCardsState {
  cards: PaymentCardInfo[];
  isLoading: boolean;
  error?: string;
}

const initialState: CustomerCardsState = {
  cards: [],
  isLoading: false,
};

export const customerCardsSlice = createSlice({
  name: 'customer-cards',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPaymentCards.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchPaymentCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cards = action.payload;
    });
    builder.addCase(fetchPaymentCards.rejected, state => {
      state.isLoading = false;
    });
  },
});
