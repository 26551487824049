import authAxios from '@/auth/axios';
import { type PaymentCardInfo } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchPaymentCards = createAsyncThunk(
  'customer-cards.fetchPaymentCards',
  async ({ customerId }: { customerId: string }, { rejectWithValue }) => {
    try {
      const response = await authAxios.get<{ data: PaymentCardInfo[] }>('/cards/cards', {
        headers: { 'x-customer-id': customerId },
      });
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(customerId);
    }
  },
);
