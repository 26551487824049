import { ClickAwayListener } from '@mui/base';
import styles from './Drawer.module.scss';
import { purple, white } from '@/utils/colors';
import CrossIcon from '@/components/icons/CrossIcon';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { type Color } from '@/utils/colors';

type Size = 'small' | 'medium' | 'large';

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  size?: Size;
  color?: Color;
}

export default function Drawer({
  children,
  onClose,
  open,
  color = white,
  size = 'large',
}: Readonly<Props>): React.JSX.Element | null {
  if (!open) return null;

  const contentClass = classNames(styles.content, styles[`content--${size}`], styles[`content${color.modifier}`]);

  return createPortal(
    <div className={styles.wrapper}>
      <ClickAwayListener onClickAway={onClose}>
        <div className={contentClass}>
          <button
            className={styles.closeIcon}
            onClick={onClose}
          >
            <CrossIcon
              width={`${20 / 16}rem`}
              height={`${20 / 16}rem`}
              color={purple.cssColor}
              thickness='1.5'
            />
          </button>
          <div className={styles.childrenContainer}>{children}</div>
        </div>
      </ClickAwayListener>
    </div>,
    document.body,
  );
}
