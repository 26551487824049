import { useTranslation } from 'react-i18next';
import { type CustomerStatusEnum } from '@/types/Customer';

import styles from './CustomerStatusBadge.module.scss';
import CustomerStatusStamp from './CustomerStatusStamp';

interface CustomerStatusBadgeProps {
  customerId: string;
  status: CustomerStatusEnum;
}

function CustomerStatusBadge({ customerId, status }: Readonly<CustomerStatusBadgeProps>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');

  return (
    <div className={styles['customer-status-badge']}>
      <span>{t('widgets.account-management.customer-id')} </span>
      <span>{customerId}</span>
      <CustomerStatusStamp status={status} />
    </div>
  );
}

export default CustomerStatusBadge;
