import BusinessAccountActionButton, { BusinessAccountActionType } from './BusinessAccountActionButton';
import styled from 'styled-components';

const MainDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  gap: 2.5rem;
  padding-right: 1.5rem;
`;

export interface BusinessAccountActionButtonsProps {
  accountId: string;
  onAction?: (accountId: string, amount: number, type: BusinessAccountActionType) => void;
}

export default function BusinessAccountActionButtons({
  accountId,
  onAction = undefined,
}: Readonly<BusinessAccountActionButtonsProps>): React.JSX.Element {
  return (
    <MainDiv>
      <BusinessAccountActionButton
        accountId={accountId}
        type={BusinessAccountActionType.Withdraw}
        minAmount={1}
        onAction={onAction}
      />
      <BusinessAccountActionButton
        accountId={accountId}
        type={BusinessAccountActionType.Topup}
        minAmount={1}
        onAction={onAction}
      />
    </MainDiv>
  );
}
