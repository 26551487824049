import { toUtcDateString } from '@/utils/dateTimeUtils';
import TransactionView from '@/components/customer/transaction-history/transaction-details/TransactionView';
import { type TransactionWithSlipId } from '@/components/customer/transaction-history/TransactionHistoryDetailWidget';
import TransactionStatusStamp from '@/components/common/transaction-status-stamp/TransactionStatusStamp';

const Topup = ({ transaction }: { transaction: TransactionWithSlipId }): JSX.Element => {
  const columnOne = [
    { label: 'transactionType', value: transaction?.transactionType, highlight: true },
    { label: 'status', value: <TransactionStatusStamp status={transaction.status} /> },
    { label: 'destination-feeTotalAmount', value: transaction?.destination?.feeTotalAmount },
  ];

  const columnTwo = [
    { label: 'amount', value: `${transaction.requestedAmountCurrency} ${transaction.requestedAmount}` },
    { label: 'paymentMethodName', value: transaction?.paymentDetail?.paymentMethodName },
    { label: 'expiredOn', value: transaction?.expireDate && toUtcDateString(transaction.expireDate) },
  ];

  const columnThree = [
    { label: 'transactionId', value: transaction.transactionId },
    { label: 'providerTransactionId', value: transaction?.paymentDetail?.providerTransactionId },
    { label: 'createdDate', value: transaction?.createdDate && toUtcDateString(transaction.createdDate) },
  ];

  return (
    <TransactionView
      columnOne={columnOne}
      columnTwo={columnTwo}
      columnThree={columnThree}
    />
  );
};

export default Topup;
