import authAxios from '@/auth/axios';
import { type Account } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAccounts = createAsyncThunk(
  'customer.fetchAccounts',
  async (customerId: string, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<{ data: Account[] }>('/accounts', {
        headers: { 'x-customer-id': customerId },
      });
      return data.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
