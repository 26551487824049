import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionStatus, TransactionType } from '@/types';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { useParams } from 'react-router-dom';
import {
  type ActionProps,
  TransactionActionsContext,
  TransactionActionType,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';
import { transactionChargebacks } from '@/lib/redux/slices/transaction-chargebacks/action';
import { reset as chargebacksReset } from '@/lib/redux/slices/transaction-chargebacks/slice';
import ActionInput from '@/components/customer/transaction-history/transaction-actions/ActionInput';
import ActionButton from '@/components/customer/transaction-history/transaction-actions/ActionButton';
import { fetchChargebackActionEmptyResult } from '@/lib/redux/slices/transaction-actions/actions';
import { isAmountAllowed } from '@/utils/helperFunctions';

const ChargebackAction: React.FC<ActionProps> = ({ onClose, transaction, setAccessibility }) => {
  const { selectedTransactionAction } = useContext(TransactionActionsContext);

  const { t } = useTranslation(['customerOverview', 'global']);
  const { isChargebackEmpty } = useAppSelector((state: RootState) => state.transactionActionsSlice);
  const dispatch = useAppDispatch();
  const { customerId } = useParams();
  const { chargebacksLoading, chargebacksSuccess } = useAppSelector((state: RootState) => state.transactionChargebacks);

  useEffect(() => {
    if (chargebacksSuccess) {
      void dispatch(chargebacksReset());
      onClose({ success: true });
    }
  }, [chargebacksSuccess, dispatch, onClose]);

  useEffect(() => {
    if (transaction) {
      const allowedStatuses = [
        TransactionStatus.Approved,
        TransactionStatus.Initiated,
        TransactionStatus.Failed,
        TransactionStatus.Declined,
        TransactionStatus.Expired,
      ];
      const { transactionType, status, slipId } = transaction;

      if (
        TransactionType.Topup === transactionType &&
        !selectedTransactionAction &&
        slipId &&
        isChargebackEmpty === undefined &&
        customerId &&
        status &&
        allowedStatuses.includes(status)
      ) {
        void dispatch(
          fetchChargebackActionEmptyResult({
            customerId,
            slipId,
          }),
        );
      } else if (!isChargebackEmpty) {
        setAccessibility(false);
      }
    }
  }, [selectedTransactionAction, customerId, dispatch, transaction, isChargebackEmpty, setAccessibility]);

  const requestedAmount = transaction?.requestedAmount ?? 0;

  let amount = `${requestedAmount}`;

  if (transaction?.requestedAmount && transaction?.requestedAmountCurrency) {
    amount = `${transaction.requestedAmount} ${transaction.requestedAmountCurrency}`;
  }

  const handleConfirm = (amount: number, reason: string): void => {
    if (transaction?.slipId) {
      void dispatch(
        transactionChargebacks({
          slipId: transaction?.slipId ?? '',
          amount,
          reason,
        }),
      );
    }
  };

  const handleClose = (): void => onClose();

  useEffect(() => {
    if (isChargebackEmpty !== undefined) {
      setAccessibility(isChargebackEmpty);
    }
    return () => {
      setAccessibility(undefined);
    };
  }, [isChargebackEmpty, setAccessibility, transaction]);

  if (selectedTransactionAction === undefined) {
    return (
      <ActionButton
        type={TransactionActionType.Chargeback}
        hide={!(isChargebackEmpty && TransactionType.Topup === transaction?.transactionType)}
        label='Chargeback'
      />
    );
  }
  if (selectedTransactionAction === TransactionActionType.Chargeback) {
    return (
      <ActionInput
        disabledButton={chargebacksLoading}
        onConfirm={handleConfirm}
        onClose={handleClose}
        isAllowed={values => isAmountAllowed(values, requestedAmount)}
        hint={t('widgets.transaction-history.information-text-amount', {
          amount,
        })}
      ></ActionInput>
    );
  }

  return null;
};

export default ChargebackAction;
