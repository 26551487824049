import App from './App';
import Spinner from '@/components/common/spinners/Spinner';
import { useInstanceLoading } from '../utils/hooks';

export default function AuthApp(): React.JSX.Element {
  const loading = useInstanceLoading()

  if (loading) {
    return <Spinner />;
  }

  return <App />;
}
