import React from 'react';

import LinkButton from '@/components/common/button/LinkButton';
import Table from '@/components/common/tables/Table';
import DocumentIcon from '@/components/icons/DocumentIcon';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';

import { toTitleCase } from '@/utils/typography';
import { type AddressDocReview, Permissions } from '@/types';
import { toUtcDateString } from '@/utils/dateTimeUtils';

export default function TableRow({ doc }: Readonly<{ doc: AddressDocReview }>): React.JSX.Element {
  return (
    <Table.Row>
      <Table.Cell>{toUtcDateString(doc.createdOn)}</Table.Cell>
      <Table.Cell>{doc.customerId}</Table.Cell>
      <Table.Cell>{toTitleCase(doc.status)}</Table.Cell>
      <Table.Cell>Address document</Table.Cell>
      <Table.Cell>
        <LinkButton
          href={`/customer/${doc.customerId}`}
          target='_blank'
        >
          {doc.customerFullName}
        </LinkButton>
      </Table.Cell>
      <Table.Cell>
        <UserPermissionsGuard permission={Permissions.docQueueRead}>
          <LinkButton
            href={`/customer/${doc.customerId}/documents/review/address-docs/${doc.addressDocId}`}
            target='_blank'
          >
            <DocumentIcon />
          </LinkButton>
        </UserPermissionsGuard>
      </Table.Cell>
    </Table.Row>
  );
}
