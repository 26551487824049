import React, { useMemo } from 'react';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import ClickableRow from '@/components/common/tables/ClickableRow';
import { type Transaction } from '@/types';
import { type ClickOptions } from '@/utils/hooks/useMouseClicks';
import TransactionStatusStamp from '@/components/common/transaction-status-stamp/TransactionStatusStamp';

export default function TransactionHistoryRow({
  transaction,
  onClick,
}: Readonly<{
  transaction: Transaction;
  onClick: (transactionId: string, options?: ClickOptions) => void;
}>): React.JSX.Element {
  const cells = useMemo(() => {
    return [
      { content: toUtcDateString(transaction.createdDate), key: 'createdDate' },
      { content: toUtcDateString(transaction.updatedDate), key: 'updatedDate' },
      { content: transaction.transactionId, key: 'transactionId' },
      { content: transaction.transactionType, key: 'transactionType' },
      { content: <TransactionStatusStamp status={transaction.status} />, key: 'status' },
      { content: `${transaction.requestedAmountCurrency} ${transaction.requestedAmount}`, key: 'requestedAmount' },
      { content: transaction.paymentDetail?.paymentMethodName, key: 'paymentMethodName' },
      { content: transaction.statusReason, key: 'statusReason' },
      { content: transaction.slipId, key: 'slipId' },
    ];
  }, [transaction]);

  return (
    <ClickableRow
      onClick={opt => {
        onClick(transaction?.transactionId ?? '', opt);
      }}
      cells={cells}
    />
  );
}
