import { marine } from '@/utils/colors';

function EditIcon({
  width = `${15 / 16}rem`,
  height = `${15 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.99998 14H14M1 14L4.63834 13.2428C4.83148 13.2026 5.00883 13.1044 5.14811 12.9604L13.2929 4.54315C13.6834 4.13958 13.6831 3.48543 13.2923 3.0822L11.5669 1.30211C11.1763 0.899056 10.5433 0.89933 10.1529 1.30273L2.00731 9.72086C1.86829 9.86452 1.77338 10.0473 1.73442 10.2464L1 14Z'
        stroke={color}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default EditIcon;
