import axios from 'axios';
import { msalInstance, loginRequest } from './msalConfig';
import { EnvironmentConstants } from '@/utils/constants';

const authAxios = axios.create({
  baseURL: EnvironmentConstants.API_URL,
});

authAxios.interceptors.request.use(async function (config) {
  const account = msalInstance.getActiveAccount();
  let needToCancelRequest = false;
  if (account === null) {
    needToCancelRequest = true;
  } else {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      const accessToken = response.accessToken;
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers['Ocp-Apim-Subscription-Key'] = EnvironmentConstants.API_SUBSCRIPTION_KEY;
    } catch (error) {
      needToCancelRequest = true;
      console.log(error);
    }
  }

  const controller = new AbortController();

  if (needToCancelRequest) {
    controller.abort();
    await msalInstance.logoutRedirect();
  }

  return {
    ...config,
    signal: controller.signal,
  };
});

export default authAxios;
