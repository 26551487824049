import styled from 'styled-components';

const ModalBox = styled.div`
  width: 31.25rem;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.438rem;
  margin-top: 1.875rem;
`;
export { ModalBox, ModalActions };
