import { type Color } from '.';

const marine: Color = {
  class: 'text-marine',
  modifier: '--marine',
  cssColor: 'var(--color-primary-marine)',
};

const boldMarine: Color = {
  class: 'text-marine-success',
  modifier: '--bold-marine',
  cssColor: 'var(--color-success-700)',
};

const lightMarine: Color = {
  class: 'text-light-marine',
  modifier: '--light-marine',
  cssColor: 'var(--color-light-marine)',
};

export { marine, boldMarine, lightMarine };
