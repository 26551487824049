import { useCallback, useMemo } from 'react';
import Dropdown from '../filters/Dropdown';
import { type Option } from '@/utils/interfaces';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';

const ALL_IPP_VALUES: Option[] = [
  { text: '10', value: '10' },
  { text: '20', value: '20' },
  { text: '50', value: '50' },
  { text: '100', value: '100' },
];

export default function ItemsPerPage(): React.JSX.Element {
  const [itemsPerPage, setItemsPerPage] = useItemsPerPage();
  const selectedOption: Option = useMemo(
    () => ({
      text: `${itemsPerPage} / Page`,
      value: `${itemsPerPage}`,
    }),
    [itemsPerPage],
  );

  const handleSelect = useCallback(
    (opt: Option) => {
      setItemsPerPage(parseInt(opt.value ?? ''));
    },
    [setItemsPerPage],
  );

  return (
    <Dropdown
      options={ALL_IPP_VALUES}
      selectedOption={selectedOption}
      onSelect={handleSelect}
      setSelectedOption={() => {}}
    />
  );
}
