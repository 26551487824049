import authAxios from '@/auth/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { successToast, errorToast } from '@/utils/toastMessage';

export interface Role {
  roleId: string;
  roleName: string;
  reason: string;
  comment?: string;
}

interface Body {
  roles: Role[];
}

export const updateUserDetails = createAsyncThunk(
  'users.updateUserDetails',
  async ({ userId, body }: { userId: string; body: Body }, { rejectWithValue }) => {
    try {
      await authAxios.patch(`/boiam/v2/users/${userId}`, { ...body });
      successToast('toast-messages.access-management-role-changed');
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
