import Modal from '@/components/common/modals/Modal';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ChannelModal.module.scss';
import Button from '@/components/common/button/Button';
import Dropdown from '@/components/common/filters/Dropdown';
import { addChannels } from '@/lib/redux/slices/channels/actions';
import { TransactionType } from '@/types';
import { parseEnumValue } from '@/utils/helperFunctions';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { resetChannels } from '@/lib/redux/slices/channels/slice';

interface Props {
  onClose: () => void;
}

export default function ChannelModal({ onClose }: Props): JSX.Element {
  const { t } = useTranslation('configurations');
  const [name, setName] = useState('');
  const [reason, setReason] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const { success, isLoading } = useAppSelector((state: RootState) => state.channels);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (success) {
      onClose();
    }
    return () => {
      dispatch(resetChannels());
    };
  }, [success, onClose, dispatch]);

  const onSubmit = (): void => {
    const type = parseEnumValue(TransactionType, transactionType);
    if (type) {
      void dispatch(
        addChannels({
          transactionType: type,
          name,
          reason,
        }),
      );
    }
  };

  const isValid = name.length > 0 && reason.length > 0 && transactionType.length > 0;

  return (
    <Modal onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.child}>
          <InputLabel label={t('channels.name')}>
            <Input
              value={name}
              onChange={setName}
            />
          </InputLabel>
          <InputLabel label={t('channels.reason')}>
            <Input
              value={reason}
              onChange={setReason}
            />
          </InputLabel>
        </div>
        <div className={styles.child}>
          <InputLabel label={t('channels.transaction-type')}>
            <Dropdown
              options={[
                { text: TransactionType.Topup, value: TransactionType.Topup },
                { text: TransactionType.Withdrawal, value: TransactionType.Withdrawal },
              ]}
              onSelect={() => {}}
              selectedOption={{ value: transactionType, text: transactionType }}
              setSelectedOption={option => {
                setTransactionType(option.value ?? '');
              }}
            />
          </InputLabel>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          disabled={!isValid || isLoading}
          textTransform='uppercase'
          onClick={onSubmit}
        >
          + {t('channels.create')}
        </Button>
      </div>
    </Modal>
  );
}
