import Spinner from '@/components/common/spinners/Spinner';
import AddressDocsContent from '@/components/customer/documents/address-docs/AddressDocsContent';
import { type AppDispatch, useAppDispatch, type RootState, useAppSelector } from '@/lib/redux';
import { fetchCustomerAddressDocuments } from '@/lib/redux/slices/customer-address-docs/actions';
import { AppUrls } from '@/utils/constants';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

export default function AddressDocumentsPage({ review }: Readonly<{ review?: boolean }>): React.JSX.Element | null {
  const { documentsId } = useParams();
  const { customer } = useAppSelector((state: RootState) => state.customer);
  const { addressDocs, loading } = useAppSelector((state: RootState) => state.customerAddressDocs);
  const dispatch: AppDispatch = useAppDispatch();

  useDocumentTitle(`${customer?.firstName} ${customer?.lastName} - Address document`);

  useEffect(() => {
    if (customer?.customerId && documentsId) {
      void dispatch(fetchCustomerAddressDocuments({ docsId: documentsId, customerId: customer.customerId, review }));
    }
  }, [customer?.customerId, documentsId, dispatch, review]);

  if (customer?.customerId && addressDocs && addressDocs?.addressDocId === documentsId && !loading) {
    return (
      <AddressDocsContent
        addressDoc={addressDocs}
        review={review}
      />
    );
  }
  if (addressDocs === null) {
    return <Navigate to={AppUrls.NotFoundUrl} />;
  }

  return <Spinner />;
}
