import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerStatusBadge from '@/components/customer/overview/account-management/customer-status-badge/CustomerStatusBadge';

import Actions, { type ShowButtonsInterface, type ActionsProps } from './Actions';

import { CustomerStatusEnum } from '@/types/Customer';
import { isKeyOfEnum } from '@/utils/helperFunctions';

import styles from './AccountManagement.module.scss';
import WidgetBox from '../../widget-box/WidgetBox';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { Permissions } from '@/types';

export interface AccountManagementProps extends Omit<ActionsProps, keyof ShowButtonsInterface> {
  customerStatus: CustomerStatusEnum;
}

export default function AccountManagement({
  customerStatus,
  ...rest
}: Readonly<AccountManagementProps>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');

  const showButtonValues = useMemo(() => {
    let statusValue: string = customerStatus;
    if (isKeyOfEnum(CustomerStatusEnum, customerStatus)) {
      statusValue = CustomerStatusEnum[customerStatus];
    }

    const showButtons = {
      showBlockButton: false,
      showUnblockButton: false,
      showCloseAccountButton: false,
      showLoginAttemptsButton: false,
    };

    showButtons.showBlockButton = [CustomerStatusEnum.ACTIVATED, CustomerStatusEnum.ONBOARDED].includes(
      statusValue as CustomerStatusEnum,
    );
    showButtons.showUnblockButton = statusValue === CustomerStatusEnum.SUSPENDED;
    showButtons.showCloseAccountButton = [
      CustomerStatusEnum.ACTIVATED,
      CustomerStatusEnum.ONBOARDED,
      CustomerStatusEnum.SUSPENDED,
    ].includes(statusValue as CustomerStatusEnum);
    showButtons.showLoginAttemptsButton = statusValue !== CustomerStatusEnum.CLOSED;

    return showButtons;
  }, [customerStatus]);

  return (
    <WidgetBox title={t('widgets.account-management.header-title')}>
      <div className={`${styles.content}`}>
        <CustomerStatusBadge
          status={customerStatus}
          customerId={rest.customerId}
        />
        <UserPermissionsGuard permission={Permissions.customerManage}>
          <Actions
            {...rest}
            {...showButtonValues}
          />
        </UserPermissionsGuard>
      </div>
    </WidgetBox>
  );
}
