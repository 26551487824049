import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import AuthApp from './AuthApp';
import { authScopes } from '@/auth/msalConfig';
import Button from '@/components/common/button/Button';
import { purple } from '@/utils/colors';

export default function AuthWrapper(): React.JSX.Element {
  const { instance } = useMsal();

  return (
    <>
      <AuthenticatedTemplate>
        <AuthApp />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: purple.cssColor,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1 style={{ color: 'white' }}>Please log in!</h1>
          <Button
            onClick={() => {
              instance
                .loginPopup(authScopes)
                .then(response => {
                  instance.setActiveAccount(response.account);
                })
                .catch(e => {
                  console.log(e);
                });
            }}
          >
            Log in
          </Button>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
}
