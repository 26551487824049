import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { errorToast, successToast } from '@/utils/toastMessage';
import { type TransactionType } from '@/types';

interface Channels {
  name: string;
  transactionType: TransactionType;
  reason: string;
}

export const addChannels = createAsyncThunk('channels.addChannels', async (body: Channels, { rejectWithValue }) => {
  try {
    await authAxios.post('/channels', body);
    successToast('toast-messages.channel-created');
  } catch (err: any) {
    errorToast();
    return rejectWithValue(err);
  }
});
