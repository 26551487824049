import React, { useContext, useEffect } from 'react';
import { TransactionStatus, TransactionType } from '@/types';
import {
  type ActionProps,
  TransactionActionsContext,
  TransactionActionType,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { transactionAdjustment } from '@/lib/redux/slices/transaction-history-detail/actions';
import { reset as adjustmentReset } from '@/lib/redux/slices/transaction-history-detail/slice';
import ActionInput from '@/components/customer/transaction-history/transaction-actions/ActionInput';
import ActionButton from '@/components/customer/transaction-history/transaction-actions/ActionButton';

const AdjustmentDebitAction: React.FC<ActionProps> = ({ onClose, transaction, setAccessibility }) => {
  const { selectedTransactionAction } = useContext(TransactionActionsContext);
  const { adjustmentSuccess, adjustmentLoading } = useAppSelector((state: RootState) => state.singleTransactionSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (adjustmentSuccess) {
      void dispatch(adjustmentReset());
      onClose({ success: true });
    }
  }, [adjustmentSuccess, dispatch, onClose]);

  const allowedByStatus =
    typeof transaction?.status === 'string' &&
    [
      TransactionStatus.Failed,
      TransactionStatus.Declined,
      TransactionStatus.Expired,
      TransactionStatus.Approved,
    ].includes(transaction.status);

  const adjustmentAllowed =
    transaction?.transactionType &&
    [TransactionType.Topup, TransactionType.Withdrawal].includes(transaction?.transactionType) &&
    allowedByStatus;

  const slipId = transaction?.slipId ?? '';

  const handleConfirm = (amount: number, reason: string): void => {
    if (transaction?.slipId) {
      void dispatch(
        transactionAdjustment({
          body: {
            slipId,
            transactionType: TransactionType.AdjustmentDebit,
            amount,
            reason,
          },
        }),
      );
    }
  };

  const handleClose = (): void => onClose();

  useEffect(() => {
    setAccessibility(adjustmentAllowed && allowedByStatus);
    return () => {
      setAccessibility(undefined);
    };
  }, [adjustmentAllowed, allowedByStatus, setAccessibility]);

  if (selectedTransactionAction === undefined) {
    return (
      <ActionButton
        type={TransactionActionType.AdjustmentDebit}
        hide={!(adjustmentAllowed && allowedByStatus)}
        label='Adjustment Debit'
      />
    );
  }

  if (selectedTransactionAction === TransactionActionType.AdjustmentDebit) {
    return (
      <ActionInput
        disabledButton={adjustmentLoading}
        onConfirm={handleConfirm}
        onClose={handleClose}
      ></ActionInput>
    );
  }

  return null;
};

export default AdjustmentDebitAction;
