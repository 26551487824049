import RemoteImage, { type RemoteImageProps } from '@/components/common/images/RemoteImage';
import styles from './ImagesBox.module.scss';

export default function ImagesBox({ images }: Readonly<{ images: RemoteImageProps[] }>): React.JSX.Element {
  return (
    <div className={styles.main}>
      {images.map(({ alt, ...rest }, index) => {
        return (
          <div
            key={`${index}${alt}`}
            className={styles['img-box']}
          >
            <RemoteImage
              alt={alt}
              {...rest}
            />
          </div>
        );
      })}
    </div>
  );
}
