import { purple } from '@/utils/colors';

export default function CrossIcon({
  width = `${12 / 16}rem`,
  height = `${12 / 16}rem`,
  thickness = '2',
  color = purple.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
  thickness?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 1L1 11M11 11L1 1'
        stroke={color}
        strokeWidth={thickness}
        strokeLinecap='round'
      />
    </svg>
  );
}
