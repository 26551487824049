import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchKycApplications } from './actions';
import type { KycApplications } from '@/types';

export interface FetchKycApplicationsState {
  key: string;
  kycApplications: KycApplications;
}

const initialState: FetchKycApplicationsState = {
  key: '',
  kycApplications: {
    id: '',
    customerId: '',
    isApplicationApproved: false,
    applicationDate: '',
  },
};

export const kycApplicationsSlice = createSlice({
  name: 'kycApplications',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchKycApplications.pending, (state, action) => {
      state.key = action.meta.arg.customerId;
      state.kycApplications = initialState.kycApplications;
    });
    builder.addCase(fetchKycApplications.fulfilled, (state, action: PayloadAction<KycApplications>) => {
      state.kycApplications = action.payload;
    });
    builder.addCase(fetchKycApplications.rejected, state => {
      state.kycApplications = initialState.kycApplications;
    });
  },
});

export const { reset } = kycApplicationsSlice.actions;
