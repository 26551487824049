import { purple } from '@/utils/colors';

function ArrowLeftIcon({
  width = `${14 / 16}rem`,
  height = `${15 / 16}rem`,
  color = purple.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.54372 14.4481C6.20862 14.851 5.66532 14.851 5.33022 14.4481L0.251276 8.3416C0.0903559 8.14812 -4.81383e-05 7.88571 -4.81184e-05 7.6121C-4.80985e-05 7.33848 0.090356 7.07607 0.251277 6.88259L5.33022 0.776118C5.66532 0.373224 6.20862 0.373224 6.54372 0.776118C6.87882 1.17901 6.87882 1.83223 6.54372 2.23512L2.07153 7.6121L6.54372 12.9891C6.87882 13.392 6.87882 14.0452 6.54372 14.4481Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9933 7.96906C13.9933 8.53884 13.6091 9.00073 13.1352 9.00073L1.12217 9.00073C0.648268 9.00073 0.264095 8.53884 0.264095 7.96906C0.264095 7.39928 0.648268 6.93738 1.12217 6.93738L13.1352 6.93739C13.6091 6.93739 13.9933 7.39928 13.9933 7.96906Z'
        fill={color}
      />
    </svg>
  );
}

export default ArrowLeftIcon;
