import { useEffect } from 'react';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import TransactionView from '@/components/customer/transaction-history/transaction-details/TransactionView';
import type { TransactionWithSlipId } from '@/components/customer/transaction-history/TransactionHistoryDetailWidget';
import { fetchTransactionChargeback } from '@/lib/redux/slices/transaction-chargeback/actions';
import Spinner from '@/components/common/spinners/Spinner';
import TransactionStatusStamp from '@/components/common/transaction-status-stamp/TransactionStatusStamp';
import { toUtcDateString } from '@/utils/dateTimeUtils';

const Chargeback = ({ transaction }: { transaction: TransactionWithSlipId }): JSX.Element => {
  const dispatch = useAppDispatch();

  const { chargeback, isLoading } = useAppSelector((state: RootState) => state.transactionChargebackSlice);

  useEffect(() => {
    if (transaction?.transactionId) {
      void dispatch(
        fetchTransactionChargeback({
          transactionId: transaction.transactionId,
        }),
      );
    }
  }, [dispatch, transaction]);

  const columnOne = [
    { label: 'transactionType', value: transaction?.transactionType, highlight: true },
    { label: 'status', value: <TransactionStatusStamp status={transaction.status} /> },
    { label: 'reason', value: chargeback?.reason },
  ];

  const columnTwo = [
    { label: 'amount', value: `${transaction.requestedAmountCurrency} ${transaction.requestedAmount}` },
    { label: 'creatorEmail', value: chargeback?.creatorEmail },
    { label: 'slipPaymentMethod', value: chargeback?.slipPaymentMethod },
  ];

  const columnThree = [
    { label: 'transactionId', value: transaction.transactionId },
    { label: 'slipId', value: chargeback?.slipId },
    { label: 'createdDate', value: transaction?.createdDate && toUtcDateString(transaction.createdDate) },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <TransactionView
      columnOne={columnOne}
      columnTwo={columnTwo}
      columnThree={columnThree}
    />
  );
};
export default Chargeback;
