import { marine } from '@/utils/colors';

function ListIcon({
  width = `${35 / 16}rem`,
  height = `${35 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 35 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.4029 5H12.597C11.3779 5.00026 10.2088 5.50691 9.34672 6.40853C8.48466 7.31016 8.00025 8.53295 8 9.80804V25.193C8.00025 26.468 8.48466 27.6908 9.34672 28.5925C10.2088 29.4941 11.3779 30.0007 12.597 30.001H22.4029C23.6221 30.0007 24.7912 29.4941 25.6533 28.5925C26.5153 27.6908 26.9997 26.468 27 25.193V9.80804C26.9997 8.53295 26.5153 7.31016 25.6533 6.40853C24.7912 5.50691 23.6221 5.00026 22.4029 5ZM25.158 25.193C25.1559 25.9565 24.865 26.6882 24.3488 27.2281C23.8326 27.7681 23.133 28.0723 22.4029 28.0744H12.597C11.867 28.0723 11.1674 27.7681 10.6512 27.2281C10.135 26.6882 9.84406 25.9565 9.84205 25.193V9.80804C9.84406 9.04448 10.135 8.31279 10.6512 7.77287C11.1674 7.23294 11.867 6.92869 12.597 6.92659H22.4029C23.133 6.92869 23.8326 7.23294 24.3488 7.77287C24.865 8.31279 25.1559 9.04448 25.158 9.80804V25.193Z'
        fill={color}
      />
      <path
        d='M14.0665 13.0212H16.9165C17.1055 13.0212 17.2867 12.9427 17.4203 12.803C17.5539 12.6632 17.629 12.4737 17.629 12.2761C17.629 12.0785 17.5539 11.8889 17.4203 11.7492C17.2867 11.6094 17.1055 11.5309 16.9165 11.5309H14.0665C13.8775 11.5309 13.6963 11.6094 13.5627 11.7492C13.4291 11.8889 13.354 12.0785 13.354 12.2761C13.354 12.4737 13.4291 12.6632 13.5627 12.803C13.6963 12.9427 13.8775 13.0212 14.0665 13.0212Z'
        fill={color}
      />
      <path
        d='M20.35 16.7554H14.65C14.461 16.7554 14.2798 16.8339 14.1462 16.9736C14.0126 17.1134 13.9375 17.3029 13.9375 17.5005C13.9375 17.6981 14.0126 17.8876 14.1462 18.0274C14.2798 18.1671 14.461 18.2456 14.65 18.2456H20.35C20.539 18.2456 20.7202 18.1671 20.8538 18.0274C20.9874 17.8876 21.0625 17.6981 21.0625 17.5005C21.0625 17.3029 20.9874 17.1134 20.8538 16.9736C20.7202 16.8339 20.539 16.7554 20.35 16.7554Z'
        fill={color}
      />
      <path
        d='M20.35 21.9797H14.65C14.461 21.9797 14.2798 22.0582 14.1462 22.198C14.0126 22.3377 13.9375 22.5272 13.9375 22.7249C13.9375 22.9225 14.0126 23.112 14.1462 23.2517C14.2798 23.3915 14.461 23.47 14.65 23.47H20.35C20.539 23.47 20.7202 23.3915 20.8538 23.2517C20.9874 23.112 21.0625 22.9225 21.0625 22.7249C21.0625 22.5272 20.9874 22.3377 20.8538 22.198C20.7202 22.0582 20.539 21.9797 20.35 21.9797Z'
        fill={color}
      />
    </svg>
  );
}

export default ListIcon;
