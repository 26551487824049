import React from 'react';
import Table from '@/components/common/tables/Table';
import { type Countries } from '@/types';
import { useTranslation } from 'react-i18next';

export default function TableRow({ country }: Readonly<{ country: Countries }>): React.JSX.Element {
  const { t } = useTranslation('configurations');
  return (
    <Table.Row>
      <Table.Cell>{country.countryCode}</Table.Cell>
      <Table.Cell>{country.countryName}</Table.Cell>
      <Table.Cell>{country.isEnabled ? t('countries-table.yes') : t('countries-table.no')}</Table.Cell>
      <Table.Cell>{country.internationalCallingCode}</Table.Cell>
    </Table.Row>
  );
}
