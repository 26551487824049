import React from 'react';
import { useAppSelector, type RootState } from '@/lib/redux';
import AccountKycWidget from '@/components/customer/account-kyc-widget/AccountKycWidget';
import styles from './KycRiskTab.module.scss';
import HorizontalLayout from '@/components/layout/horizontal-layout/HorizontalLayout';
import PepSanctionChecks from '@/components/customer/kyc-risk/pep-and-sanctions/PepSanctionChecks';
import IdVerification from '@/components/customer/kyc-risk/id-verification/IdVerification';
import AuditLogs from '@/components/customer/kyc-risk/audit-logs/AuditLogs';
import AddressVerification from '@/components/customer/kyc-risk/address-verification/AddressVerification';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';

function KycRiskTab(): React.JSX.Element | null {
  const { t } = useTranslation('customerOverview');
  const customer = useAppSelector((state: RootState) => state.customer.customer);
  useDocumentTitle(
    customer
      ? t('navigation-tabs.kyc-risk-page-title', { customerFullName: `${customer.firstName} ${customer.lastName}` })
      : '',
  );

  if (customer === undefined || customer === null) {
    return null;
  }

  const customerIdentityDocs = customer?.identityDocs ?? [];

  return (
    <div className={styles['kyc-risk-tab']}>
      <HorizontalLayout
        colsTemplate='1fr 1fr'
        gap='1.25rem'
      >
        <AccountKycWidget customerId={customer.customerId ?? ''} />
        <PepSanctionChecks customer={customer} />
      </HorizontalLayout>
      <HorizontalLayout
        colsTemplate='1fr 1fr'
        gap='1.25rem'
      >
        <IdVerification
          identityDocs={customerIdentityDocs}
          customerId={customer.customerId ?? ''}
          urlRoot={location.origin}
        />
        <AddressVerification
          customerId={customer.customerId ?? ''}
          urlRoot={location.origin}
        />
      </HorizontalLayout>
      <HorizontalLayout colsTemplate='100%'>
        <AuditLogs />
      </HorizontalLayout>
    </div>
  );
}

export default KycRiskTab;
