import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { type User } from '@/types';
import { successToast, errorToast } from '@/utils/toastMessage';
import { type RootState } from '@/lib/redux/store';

export const fetchUsers = createAsyncThunk(
  'users/fetch',
  async ({ page, itemsPerPage }: { page: number; itemsPerPage: number }, { rejectWithValue, getState }) => {
    const rootState = getState() as RootState;
    const currentPage = rootState.users.page;
    const currentItemsPerPage = rootState.users.itemsPerPage;
    if (page === currentPage && currentItemsPerPage > 0 && currentItemsPerPage < itemsPerPage) {
      page = 1;
    }
    try {
      const { data } = await authAxios.get<{ data: { data: User[]; total: number } }>(
        `/users?limit=${itemsPerPage}&offset=${page - 1}&includeTotal=true`,
      );

      return {
        users: data.data.data ?? [],
        count: data.data.total,
        page,
        itemsPerPage,
      };
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/delete',
  async ({ userId }: { userId: string }, { rejectWithValue }) => {
    try {
      await authAxios.delete(`/boiam/users/${userId}`);
      successToast('toast-messages.user-delete');
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
