import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type Transaction } from '@/types';
import { type TransactionHistoryResponse, fetchTransactions } from './actions';

interface TransactionHistoryState {
  transactions: Transaction[];
  page: number;
  count: number;
  isLoading: boolean;
  emptyResult: boolean;
  error?: string;
}

const initialState: TransactionHistoryState = {
  transactions: [],
  page: -1,
  count: 0,
  emptyResult: false,
  isLoading: false,
};

export const transactionHistorySlice = createSlice({
  name: 'transactionHistorySlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTransactions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchTransactions.fulfilled, (state, action: PayloadAction<TransactionHistoryResponse>) => {
      state.isLoading = false;
      state.transactions = action.payload.transactions;
      state.count = action.payload.count;
      state.page = action.payload.page;
      state.emptyResult = action.payload.transactions.length === 0;
    });
    builder.addCase(fetchTransactions.rejected, (state, action) => {
      state.transactions = [];
      if (action.payload) {
        state.page = action.payload.page;
        state.count = action.payload.total;
      }
      state.isLoading = false;
    });
  },
});

export const { reset } = transactionHistorySlice.actions;
