import classNames from 'classnames';

import { white, type Color } from '@/utils/colors';
import { type DisplayType } from '@/utils/interfaces';

import styles from './DropdownOption.module.scss';

function DropdownOption({
  text,
  active,
  hidden = false,
  onOptionClick,
  primaryColor = white,
  displayType = 'block',
}: Readonly<{
  text: string;
  active: boolean;
  hidden?: boolean;
  onOptionClick: () => void;
  primaryColor?: Color;
  displayType?: DisplayType;
}>): React.JSX.Element {
  const optionClasses = classNames(styles.dropdown__option, {
    [styles[`dropdown__option${primaryColor?.modifier}`]]: primaryColor,
    [styles[`dropdown__option--${displayType}`]]: displayType,
    [styles['dropdown__option--active']]: active,
    [styles['dropdown__option--hidden']]: hidden,
  });

  return (
    <li
      className={optionClasses}
      onClick={onOptionClick}
    >
      {text}
    </li>
  );
}

export default DropdownOption;
