import { useTranslation } from 'react-i18next';

import TableRow from './TableRow';
import Table from '@/components/common/tables/Table';

import { type AddressDocReview } from '@/types';

export default function AddressDocsTable({
  documents,
}: Readonly<{ documents: AddressDocReview[] }>): React.JSX.Element {
  const { t } = useTranslation('documentReviewQueue');

  return (
    <Table cols={6}>
      <Table.Head>{t('table.timestamp')}</Table.Head>
      <Table.Head>{t('table.customer-id')}</Table.Head>
      <Table.Head>{t('table.status')}</Table.Head>
      <Table.Head>{t('table.document-type')}</Table.Head>
      <Table.Head>{t('table.link-to-customer-profile')}</Table.Head>
      <Table.Head>{t('table.document-view')}</Table.Head>
      {documents.map(doc => {
        return (
          <TableRow
            key={doc.addressDocId}
            doc={doc}
          />
        );
      })}
    </Table>
  );
}
