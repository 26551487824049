import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Spinner from '@/components/common/spinners/Spinner';
import TablePagination from '@/components/common/table-pagination/TablePagination';
import TransactionHistoryTable from '@/components/business-accounts/transaction-history/TransactionHistoryTable';

import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { type AppDispatch, type RootState, useAppSelector } from '@/lib/redux';
import { fetchTransactions } from '@/lib/redux/slices/transaction-history-ba/actions';
import { reset } from '@/lib/redux/slices/transaction-history-ba/slice';
import { CountriesEnum, CurrencyEnum } from '@/types';

import styles from './TransactionHistory.module.scss';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';
import usePagination from '@/utils/hooks/usePagination';
import {
  useTransactionIdFilter,
  usePspTransactionIdFilter,
  useTransactionTypeFilter,
  useTransactionStatusFilter,
  useDateRangeFilter,
  useCountryFilter,
  useCustomerIdFilter,
  usePhoneNumberFilter,
} from '@/components/customer/transaction-history/filters/filterHooks';
import TransactionHistoryFilters from './TransactionHistoryFilters';
import { isKeyOfEnum } from '@/utils/helperFunctions';

const TransactionHistory = (): React.JSX.Element => {
  const {
    transactions,
    isLoading: areTransactionsLoading,
    count,
    page,
  } = useAppSelector((state: RootState) => state.transactionHistoryBA);
  const { loading: areAccountsLoading } = useAppSelector((state: RootState) => state.businessAccounts);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation(['businessAccounts', 'global']);
  const [itemsPerPage] = useItemsPerPage();
  const [urlPage, setUrlPage] = usePagination(page);

  const [transactionId] = useTransactionIdFilter();
  const [pspTransactionId] = usePspTransactionIdFilter();
  const [transactionTypes] = useTransactionTypeFilter();
  const [transactionStatus] = useTransactionStatusFilter();
  const [dates] = useDateRangeFilter();
  const [country] = useCountryFilter();
  const [phoneNumber] = usePhoneNumberFilter();
  const [customerId] = useCustomerIdFilter();

  useDocumentTitle(t('tabs.transaction-history.page-title'));
  const [dateFrom, dateTo] = dates;

  useEffect(() => {
    if (country && isKeyOfEnum(CountriesEnum, country)) {
      const countryCurrency: Record<CountriesEnum, CurrencyEnum> = {
        [CountriesEnum.CHL]: CurrencyEnum.CLP,
        [CountriesEnum.PER]: CurrencyEnum.PEN,
        [CountriesEnum.ARG]: CurrencyEnum.ARS,
        [CountriesEnum.MEX]: CurrencyEnum.MXN,
        [CountriesEnum.COL]: CurrencyEnum.COP,
      };

      const selectedOptionValue = country as CountriesEnum;
      void dispatch(
        fetchTransactions({
          page: urlPage,
          itemsPerPage,
          countryCode: selectedOptionValue,
          currencyCode: countryCurrency[selectedOptionValue],
          dateFrom: dateFrom !== '' ? dateFrom : undefined,
          dateTo: dateTo !== '' ? dateTo : undefined,
          phoneNumber,
          transactionTypes: (transactionTypes?.length ?? 0) > 0 ? transactionTypes : undefined,
          transactionStatus: (transactionStatus?.length ?? 0) > 0 ? transactionStatus : undefined,
          transactionIds: transactionId ? [transactionId] : undefined,
          pspTransactionId,
          customerId,
        }),
      );
    } else {
      void dispatch(reset());
    }
  }, [
    itemsPerPage,
    urlPage,
    dispatch,
    transactionId,
    transactionTypes,
    transactionStatus,
    dateFrom,
    dateTo,
    pspTransactionId,
    country,
    phoneNumber,
    customerId,
  ]);

  useEffect(
    () => () => {
      void dispatch(reset());
    },
    [dispatch],
  );

  return (
    <>
      <div className={styles.filters}>
        <TransactionHistoryFilters />
      </div>
      <div className={styles['table-wrapper']}>
        {(areTransactionsLoading || areAccountsLoading) && <Spinner />}
        {!areTransactionsLoading && !areAccountsLoading && <TransactionHistoryTable transactions={transactions} />}
      </div>
      {country && (
        <TablePagination
          count={count}
          page={page}
          setPage={setUrlPage}
        />
      )}
    </>
  );
};

export default TransactionHistory;
