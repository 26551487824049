import styles from './InputLabel.module.scss';

export default function InputLabel({
  children,
  label,
  className,
}: Readonly<{
  label: string;
  children?: React.ReactNode;
  className?: string;
}>): React.JSX.Element {
  return (
    <div className={`${styles.wrapper} ${className ?? ''}`}>
      <div className={`${styles.label}`}>{label}</div>
      {children !== undefined && <div>{children}</div>}
    </div>
  );
}
