import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Chargeback } from '@/types/Chargeback';
import { fetchTransactionChargeback } from '@/lib/redux/slices/transaction-chargeback/actions';

interface TransactionChargebackState {
  isLoading: boolean;
  error?: string;
  chargeback?: Chargeback;
}

const initialState: TransactionChargebackState = {
  isLoading: true,
};
export const transactionChargebackSlice = createSlice({
  name: 'detailTransactionChargebackSlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTransactionChargeback.pending, state => {
      state.isLoading = true;
      state.error = undefined;
      state.chargeback = undefined;
    });

    builder.addCase(fetchTransactionChargeback.fulfilled, (state, action: PayloadAction<Chargeback>) => {
      return {
        chargeback: action.payload,
        isLoading: false,
        error: undefined,
      };
    });

    builder.addCase(fetchTransactionChargeback.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed to fetch chargeback transaction';
    });
  },
});

export const { reset } = transactionChargebackSlice.actions;
