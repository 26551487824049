import FilledCircleIcon from '@/components/icons/FilledCircleIcon';
import Typography from '@/components/common/typography/Typography';
import CircleTickIcon from '@/components/icons/CircleTickIcon';
import styles from './AccordionTitle.module.scss';

const AccordionTitle = ({ title, isValid = false }: { title: string; isValid?: boolean }): JSX.Element => {
  return (
    <div className={styles.title}>
      {isValid && <CircleTickIcon />}
      {!isValid && <FilledCircleIcon />}
      <Typography variant='p2MediumTight'> {title}</Typography>
    </div>
  );
};

export default AccordionTitle;
