import VerticalLine from '../vertical-line/VerticalLine';
import Logo from './logo/Logo';

import type { Color } from '@/utils/colors';
import { white } from '@/utils/colors';

import styles from './LogoWrapper.module.scss';

function LogoWrapper(props: Readonly<{ logoColor: Color }>): React.JSX.Element {
  return (
    <div className={styles['header__logo-wrapper']}>
      <Logo color={props.logoColor} />
      <VerticalLine color={white} />
      <div className={styles['header__logo-text']}>Admin</div>
    </div>
  );
}

export default LogoWrapper;
