import Button from '@/components/common/button/Button';
import MultiSelectDropdown from '@/components/common/filters/MultiSelectDropdown';
import Input from '@/components/common/inputs/Input';
import InputLabel from '@/components/common/labels/InputLabel';
import Modal from '@/components/common/modals/Modal';
import Typography from '@/components/common/typography/Typography';
import { type Option } from '@/utils/interfaces';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ModalBox = styled.div`
  width: 31.25rem;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.438rem;
  margin-top: 1.875rem;
`;

const ModalFieldsWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;

interface Props {
  onClose: () => void;
  name: string;
  setName: (value: string) => void;
  validateName: () => void;
  nameError: string;
  setNameError: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  validateEmail: () => void;
  emailError: string;
  setEmailError: (value: string) => void;
  roleOptions: Option[];
  selectedRoles: Option[];
  onRolesChange: (values: Option[]) => void;
  reason: string;
  setReason: (value: string) => void;
  reasonError: string;
  setReasonError: (value: string) => void;
  validateReason: () => void;
  onSubmit: () => void;
  isCreateButtonDisabled: boolean;
}
export default function CreateUserModalView({
  onClose,
  name,
  setName,
  validateName,
  nameError,
  setNameError,
  email,
  setEmail,
  validateEmail,
  emailError,
  setEmailError,
  roleOptions,
  selectedRoles,
  onRolesChange,
  reason,
  setReason,
  reasonError,
  setReasonError,
  validateReason,
  onSubmit,
  isCreateButtonDisabled,
}: Readonly<Props>): React.JSX.Element {
  const { t } = useTranslation('accessManagement');
  return (
    <Modal onClose={onClose}>
      <ModalBox>
        <Typography variant='poBold'>{t('tabs.users.new-user-modal.title')}</Typography>
        <ModalFieldsWrapper>
          <InputLabel label={t('tabs.users.new-user-modal.user-name-field')}>
            <Input
              value={name}
              onChange={newValue => setName(newValue)}
              onBlur={validateName}
              error={nameError}
              onFocus={() => setNameError('')}
            />
          </InputLabel>
          <InputLabel label={t('tabs.users.new-user-modal.email-field')}>
            <Input
              value={email}
              onChange={newEmail => setEmail(newEmail)}
              onBlur={validateEmail}
              error={emailError}
              onFocus={() => setEmailError('')}
            />
          </InputLabel>
          <InputLabel label={t('tabs.users.new-user-modal.role-field')}>
            <MultiSelectDropdown
              options={roleOptions}
              selectedOptions={selectedRoles}
              onChange={onRolesChange}
              excludeAll={true}
            />
          </InputLabel>
          <InputLabel label={t('tabs.users.new-user-modal.reason-field')}>
            <Input
              value={reason}
              onChange={newReason => setReason(newReason)}
              onBlur={validateReason}
              error={reasonError}
              onFocus={() => setReasonError('')}
            />
          </InputLabel>
        </ModalFieldsWrapper>
        <ModalActions>
          <Button
            variant='secondary'
            onClick={onClose}
            textTransform='uppercase'
          >
            {t('tabs.users.new-user-modal.cancel-button')}
          </Button>
          <Button
            disabled={isCreateButtonDisabled}
            onClick={onSubmit}
            textTransform='uppercase'
          >
            {t('tabs.users.new-user-modal.create-button')}
          </Button>
        </ModalActions>
      </ModalBox>
    </Modal>
  );
}
