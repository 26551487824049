import { purple } from '@/utils/colors';

export default function LockIcon({
  width = `${18 / 16}rem`,
  height = `${18 / 16}rem`,
  color = purple.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M14.8799 7.77612H3.11994C2.1921 7.77612 1.43994 8.53805 1.43994 9.47794V15.4343C1.43994 16.3742 2.1921 17.1361 3.11994 17.1361H14.8799C15.8078 17.1361 16.5599 16.3742 16.5599 15.4343V9.47794C16.5599 8.53805 15.8078 7.77612 14.8799 7.77612Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.04004 7.20005V4.86401C5.04004 3.80315 5.45725 2.78573 6.1999 2.03559C6.94254 1.28544 7.94978 0.864014 9.00004 0.864014C10.0503 0.864014 11.0575 1.28544 11.8002 2.03559C12.5428 2.78573 12.96 3.80315 12.96 4.86401V7.20005'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}
