import React, { useCallback, useState } from 'react';
import Table from './Table';
import styles from './ClickableRow.module.scss';
import classNames from 'classnames';
import useMouseClicks, { type ClickOptions } from '@/utils/hooks/useMouseClicks';

export interface CellProps {
  content?: React.ReactNode;
  key: string;
}
export interface Props {
  cells: CellProps[];
  onClick: (options?: ClickOptions) => void;
  hoverClassName?: string;
}

export default function ClickableRow({
  cells,
  onClick,
  hoverClassName = styles['cell-hover__default'],
}: Readonly<Props>): React.JSX.Element {
  const [isHover, setIsHover] = useState(false);
  const className = classNames(styles.cell, {
    [hoverClassName]: isHover,
  });

  const mouseClickElementProps = useMouseClicks(onClick);

  const onMouseEnter = useCallback(() => {
    setIsHover(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  return (
    <Table.Row>
      {cells.map(cell => (
        <Table.Cell
          key={cell.key}
          className={className}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          {...mouseClickElementProps}
        >
          {cell.content}
        </Table.Cell>
      ))}
    </Table.Row>
  );
}
