import { marine } from '@/utils/colors';

export default function CircleTickIcon({
  width = `${18 / 16}rem`,
  height = `${18 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='9'
        cy='9'
        r='9'
        fill='#110039'
      />
      <path
        d='M4.16699 8.89768L7.52017 12.5711L13.5559 6.14258'
        stroke='#00EAD0'
        strokeWidth='1.7'
        strokeLinecap='round'
      />
    </svg>
  );
}
