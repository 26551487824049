import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ContentBox from '@/components/common/content-box/ContentBox';
import NavigationTabs from '@/components/common/navigation-tabs/NavigationTabs';
import Layout from '@/components/layout/Layout';

import { type TabInfo } from '@/utils/interfaces';

import styles from './DocumentQueueLayout.module.scss';

export default function DocumentQueueLayout(): React.JSX.Element {
  const { t } = useTranslation('documentReviewQueue');

  const tabs: TabInfo[] = useMemo(() => {
    return [
      { linkText: t('identity-docs-tab'), linkPath: 'identity-docs' },
      {
        linkText: t('address-docs-tab'),
        linkPath: 'address-docs',
      },
    ];
  }, [t]);

  return (
    <Layout hideDocQueue>
      <div className={styles['document-queue-layout']}>
        <div className={styles['document-queue-layout__header']}>
          <h1>{t('header-title')}</h1>
          <NavigationTabs tabs={tabs} />
        </div>
        <ContentBox>
          <Outlet />
        </ContentBox>
      </div>
    </Layout>
  );
}
