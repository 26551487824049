import { store } from '@/lib/redux';
import { MsalProvider } from '@azure/msal-react';
import AuthWrapper from './AuthWrapper';
import { Provider } from 'react-redux';
import { msalInstance } from '@/auth/msalConfig';

export default function MsalWrapper(): React.JSX.Element {
  return (
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <AuthWrapper />
      </MsalProvider>
    </Provider>
  );
}
