export default class DebounceCache<K extends string, R> {
  private cache: Record<K, Promise<R> | undefined>;

  constructor() {
    this.cache = Object.create(null);
  }

  // eslint-disable-next-line @typescript-eslint/promise-function-async
  public getResult(key: K, getPromise: () => Promise<R>): Promise<R> {
    if (this.cache[key] === undefined) {
      this.cache[key] = getPromise()
        .then((result: R) => {
          this.cache[key] = undefined;
          return result;
        })
        .catch((error: unknown) => {
          this.cache[key] = undefined;
          throw error;
        });
    }

    const cachedResult = this.cache[key];
    if (cachedResult === undefined) {
      throw new Error('Cache miss error: the cached result is unexpectedly undefined.');
    }
    return cachedResult;
  }
}
