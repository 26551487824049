import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { useEffect, useState } from 'react';
import { fetchUsers } from '@/lib/redux/slices/users/actions';
import { insertUser, reset } from '@/lib/redux/slices/users/slice';
import styles from './UsersPage.module.scss';
import Spinner from '@/components/common/spinners/Spinner';
import TablePagination from '@/components/common/table-pagination/TablePagination';
import UsersTable from '@/components/access-management/users/UsersTable';
import Button from '@/components/common/button/Button';
import Typography from '@/components/common/typography/Typography';
import CreateUserModal from '@/components/access-management/users/CreateUserModal';
import { Permissions, type User } from '@/types';
import { successToast } from '@/utils/toastMessage';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import usePagination from '@/utils/hooks/usePagination';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';

const UsersPage = (): React.JSX.Element => {
  const { t } = useTranslation(['accessManagement', 'global']);
  const { users, isLoading, count, page } = useAppSelector((state: RootState) => state.users);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [currentPage, setCurrentPage] = usePagination(page);
  const [itemsPerPage] = useItemsPerPage();
  const dispatch = useAppDispatch();
  useDocumentTitle(t('tabs.users.page-title'));

  useEffect(() => {
    void dispatch(fetchUsers({ page: currentPage, itemsPerPage }));
  }, [currentPage, dispatch, itemsPerPage]);

  useEffect(
    () => () => {
      void dispatch(reset());
    },
    [dispatch],
  );

  const onNewUserCreated = (user: User): void => {
    successToast(t('toast-messages.user-created-toast', { ns: 'global' }));
    void dispatch(insertUser(user));
  };
  const onCreateUserModalClose: () => void = () => {
    setShowCreateUserModal(false);
  };

  return (
    <>
      {showCreateUserModal && (
        <CreateUserModal
          onClose={onCreateUserModalClose}
          onSuccess={onNewUserCreated}
        />
      )}
      <div className={styles.title}>
        <Typography variant='p1SemiBold'>Users</Typography>
        <UserPermissionsGuard permission={Permissions.accessManagementEdit}>
          <Button
            textTransform='uppercase'
            onClick={() => setShowCreateUserModal(true)}
          >
            + New User
          </Button>
        </UserPermissionsGuard>
      </div>
      <div className={styles['table-wrapper']}>
        {isLoading && <Spinner />}
        {!isLoading && (
          <UsersTable
            currentPage={currentPage}
            users={users}
          />
        )}
      </div>
      <TablePagination
        count={count}
        page={page}
        setPage={setCurrentPage}
      />
    </>
  );
};

export default UsersPage;
