import { useEffect } from 'react';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import TransactionView from '@/components/customer/transaction-history/transaction-details/TransactionView';
import { transactionAdjustment } from '@/lib/redux/slices/transaction-adjustment/action';
import { type TransactionWithSlipId } from '@/components/customer/transaction-history/TransactionHistoryDetailWidget';
import Spinner from '@/components/common/spinners/Spinner';
import TransactionStatusStamp from '@/components/common/transaction-status-stamp/TransactionStatusStamp';
import { toUtcDateString } from '@/utils/dateTimeUtils';

const Adjustment = ({ transaction }: { transaction: TransactionWithSlipId }): JSX.Element => {
  const dispatch = useAppDispatch();

  const { adjustment, isLoading } = useAppSelector((state: RootState) => state.transactionAdjustment);

  useEffect(() => {
    void dispatch(transactionAdjustment({ transactionId: transaction.transactionId }));
  }, [dispatch, transaction]);

  const columnOne = [
    { label: 'transactionType', value: transaction?.transactionType, highlight: true },
    { label: 'status', value: <TransactionStatusStamp status={transaction.status} /> },
    { label: 'reason', value: adjustment?.reason },
  ];

  const columnTwo = [
    { label: 'amount', value: `${transaction.requestedAmountCurrency} ${transaction.requestedAmount}` },
    { label: 'creatorEmail', value: adjustment?.creatorEmail },
  ];

  const columnThree = [
    { label: 'transactionId', value: transaction.transactionId },
    { label: 'slipId', value: adjustment?.slipId ?? transaction?.slipId },
    { label: 'createdDate', value: transaction?.createdDate && toUtcDateString(transaction.createdDate) },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <TransactionView
      columnOne={columnOne}
      columnTwo={columnTwo}
      columnThree={columnThree}
    />
  );
};
export default Adjustment;
