import React from 'react';
import styled from 'styled-components';

interface VariantStyles {
  fontWeight: string;
  fontSize: string;
  lineHeight: string;
  textDecoration?: string;
}

interface StyledTypographyProps {
  $fontWeight: string;
  $fontSize: string;
  $lineHeight: string;
  $textDecoration?: string;
  $color?: string;
}

const StyledTypography = styled.div<StyledTypographyProps>`
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-size: ${({ $fontSize }) => `${$fontSize}rem`};
  line-height: ${({ $lineHeight }) => `${$lineHeight}rem`};
  text-decoration: ${({ $textDecoration }) => $textDecoration ?? 'none'};
  color: ${({ $color }) => $color};
  min-height: ${({ $lineHeight }) => `${$lineHeight}rem`};
`;

export type Variant =
  | 'cta1'
  | 'cta2'
  | 'h1'
  | 'h2'
  | 'po'
  | 'poBold'
  | 'p1RegularBold'
  | 'p1MediumBold'
  | 'p1SemiBold'
  | 'p2'
  | 'p2Medium'
  | 'p2MediumTight'
  | 'p2Underlined'
  | 'p2Semibold'
  | 'p2Bold'
  | 'p2BoldTight'
  | 'p3'
  | 'p3Light'
  | 'p3Medium'
  | 'p3Bold'
  | 'p4'
  | 'poSemibold'
  | 'p4Bold';

const variantStyles: Record<Variant, VariantStyles> = {
  cta1: { fontWeight: '600', fontSize: '1', lineHeight: '1.188' },
  cta2: { fontWeight: '700', fontSize: '0.875', lineHeight: '1.063' },
  h1: { fontWeight: '700', fontSize: '2', lineHeight: '4.375' },
  h2: { fontWeight: '700', fontSize: '1.625', lineHeight: '1.75' },
  po: { fontWeight: '400', fontSize: '1.125', lineHeight: '1.5' },
  poBold: { fontWeight: '700', fontSize: '1.125', lineHeight: '1.5' },
  p1RegularBold: { fontWeight: '400', fontSize: '1', lineHeight: '1.5' },
  p1MediumBold: { fontWeight: '500', fontSize: '1', lineHeight: '1.25' },
  p1SemiBold: { fontWeight: '600', fontSize: '1', lineHeight: '1.5' },
  p2: { fontWeight: '400', fontSize: '0.875', lineHeight: '1.25' },
  p2Medium: { fontWeight: '500', fontSize: '0.875', lineHeight: '1.5' },
  p2MediumTight: { fontWeight: '500', fontSize: '0.875', lineHeight: '1.313' },
  p2Underlined: { fontWeight: '500', fontSize: '0.875', lineHeight: '1.5', textDecoration: 'underline' },
  p2Semibold: { fontWeight: '600', fontSize: '0.875', lineHeight: '1.5' },
  p2Bold: { fontWeight: '700', fontSize: '0.875', lineHeight: '1.5' },
  p2BoldTight: { fontWeight: '700', fontSize: '0.875', lineHeight: '1.313' },
  p3: { fontWeight: '400', fontSize: '0.813', lineHeight: '1' },
  p3Light: { fontWeight: '300', fontSize: '0.813', lineHeight: '1.375' },
  p3Medium: { fontWeight: '500', fontSize: '0.813', lineHeight: '1.125' },
  p3Bold: { fontWeight: '700', fontSize: '0.813', lineHeight: '1' },
  p4: { fontWeight: '400', fontSize: '0.688', lineHeight: '0.938' },
  poSemibold: { fontWeight: '600', fontSize: '1.125', lineHeight: '1.5' },
  p4Bold: { fontWeight: '700', fontSize: '0.688', lineHeight: '0.938' },
};
interface TypographyProps extends React.HTMLProps<HTMLDivElement> {
  variant?: Variant;
  color?: string;
}

const Typography: React.FC<TypographyProps> = ({ children, variant, color, ...rest }) => {
  const { fontWeight, fontSize, lineHeight, textDecoration } = variant
    ? variantStyles[variant]
    : variantStyles.p1SemiBold;

  return (
    <StyledTypography
      $fontWeight={fontWeight}
      $fontSize={fontSize}
      $lineHeight={lineHeight}
      $textDecoration={textDecoration}
      $color={color}
      {...rest}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
