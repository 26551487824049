import { type AuditLogs } from '@/types';
import { createSlice } from '@reduxjs/toolkit';
import { getAuditLogs } from './actions';

export interface AuditLogsState {
  auditLogs: AuditLogs[];
  page: number;
  count: number;
  itemsPerPage: number;
  isLoading: boolean;
  error?: string;
}

const initialState: AuditLogsState = {
  auditLogs: [],
  page: -1,
  count: 0,
  itemsPerPage: -1,
  isLoading: false,
};

export const auditLogsSlice = createSlice({
  name: 'auditLogs',
  initialState,
  reducers: {
    resetAuditLogs() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(getAuditLogs.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getAuditLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.auditLogs = action.payload.auditLogs;
      state.count = action.payload.count;
      state.page = action.payload.page;
      state.itemsPerPage = action.payload.itemsPerPage;
    });
    builder.addCase(getAuditLogs.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { resetAuditLogs } = auditLogsSlice.actions;
