import authAxios from '@/auth/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorToast, successToast } from '@/utils/toastMessage';
import { type Translation } from '@/types/Translation';

export const addCountryTranslation = createAsyncThunk(
  'country.addCountryTranslation',
  async (body: Translation, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      if (body.file) {
        formData.append('LanguageCode', body.languageCode);
        formData.append('VersionType', body.versionType);
        formData.append('FormFile', body.file);
        formData.append('Delimiter', body.delimitier);
      }

      await authAxios.post('/translations', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      successToast();
      return body;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
