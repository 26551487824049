import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { fetchTransactionReversal, transactionCreateReversal } from '@/lib/redux/slices/transaction-reversal/actions';
import { type Reversal } from '@/types/Reversal';

interface TransactionReversalState {
  reversalLoading: boolean;
  reversalSuccess: boolean;
  error?: string;
  reversal?: Reversal;
  isLoading: boolean;
  success: boolean;
}

const initialState: TransactionReversalState = {
  reversalLoading: true,
  reversalSuccess: false,
  isLoading: false,
  success: false,
};
export const transactionReversalSlice = createSlice({
  name: 'detailTransactionReversalSlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTransactionReversal.pending, state => {
      state.reversalLoading = true;
      state.error = undefined;
      state.reversal = undefined;
    });

    builder.addCase(fetchTransactionReversal.fulfilled, (state, action: PayloadAction<Reversal>) => {
      return {
        reversal: action.payload,
        reversalLoading: false,
        reversalSuccess: true,
        error: undefined,
        success: false,
        isLoading: false,
      };
    });

    builder.addCase(fetchTransactionReversal.rejected, (state, action) => {
      state.reversalLoading = false;
      state.error = action.error?.message ?? 'Failed to fetch reversal transaction';
    });

    builder.addCase(transactionCreateReversal.pending, state => {
      state.success = false;
      state.isLoading = true;
    });

    builder.addCase(transactionCreateReversal.fulfilled, state => {
      state.success = true;
      state.isLoading = false;
    });

    builder.addCase(transactionCreateReversal.rejected, (state, action) => {
      state.success = false;
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed adjustment details';
    });
  },
});

export const { reset } = transactionReversalSlice.actions;
