import Header from './header/Header';

import styles from './Layout.module.scss';

function Layout({
  children,
  hideDocQueue,
  hideHamburger,
}: Readonly<{ children: React.ReactNode; hideDocQueue?: boolean; hideHamburger?: boolean }>): React.JSX.Element {
  return (
    <div className={styles.layout}>
      <Header
        hideDocQueue={hideDocQueue}
        hideHamburger={hideHamburger}
      />
      <main className={styles.main}>{children}</main>
    </div>
  );
}

export default Layout;
