import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useBackButtonCallback(): () => void {
  const location = useLocation();
  const navigate = useNavigate();

  const goBackCb = useCallback(() => {
    if (location.key && location.key !== 'default') {
      navigate(-1);
    } else {
      const pathName = location.pathname;
      const relativeUrl = pathName.substring(0, pathName.lastIndexOf('/'));
      navigate(relativeUrl);
    }
  }, [navigate, location]);

  return goBackCb;
}
