import { marine } from '@/utils/colors';

function SearchIcon({
  width = `${24 / 16}rem`,
  height = `${24 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.8721 15.8973L20 20M17.7917 11.3568C17.7917 14.8675 14.9282 17.7135 11.3959 17.7135C7.86353 17.7135 5 14.8675 5 11.3568C5 7.84602 7.86353 5 11.3959 5C14.9282 5 17.7917 7.84602 17.7917 11.3568Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}

export default SearchIcon;
