import { useEffect, useState } from 'react';
import Input, { type InputProps } from './Input';
import { useDebounce } from 'use-debounce';

interface Props extends InputProps {
  delay?: number;
}
export default function DebounceInput({ value, onChange, delay = 500, ...rest }: Readonly<Props>): React.JSX.Element {
  const [inputValue, setInputValue] = useState(value);
  const [debounceValue] = useDebounce(inputValue, delay);

  useEffect(() => {
    onChange?.(debounceValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  useEffect(() => {
    setInputValue(() => value);
  }, [setInputValue, value]);

  return (
    <Input
      value={inputValue}
      onChange={v => setInputValue(() => v)}
      {...rest}
    />
  );
}
