import { marine } from '@/utils/colors';

export default function ExitIcon({
  width = `${20 / 16}rem`,
  height = `${20 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.88999 2.21615C2.57867 2.21615 2.2801 2.33982 2.05996 2.55996C1.83982 2.7801 1.71615 3.07867 1.71615 3.38999V17.6105C1.71615 17.6104 1.71615 17.6106 1.71615 17.6105C1.71638 17.9216 1.8401 18.2203 2.06014 18.4402C2.28026 18.6602 2.57876 18.7839 2.88999 18.7839H6.9504C7.4243 18.7839 7.80848 19.168 7.80848 19.6419C7.80848 20.1158 7.4243 20.5 6.9504 20.5H2.88999C2.12373 20.5 1.38883 20.1957 0.846891 19.654C0.304947 19.1123 0.000323647 18.3775 5.11452e-08 17.6112L0 3.38999C0 2.62352 0.304481 1.88844 0.84646 1.34646C1.38844 0.804481 2.12352 0.5 2.88999 0.5H6.9504C7.4243 0.5 7.80848 0.884173 7.80848 1.35807C7.80848 1.83198 7.4243 2.21615 6.9504 2.21615H2.88999Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4562 4.81431C13.7913 4.47921 14.3346 4.47921 14.6697 4.81431L19.7486 9.89325C19.9095 10.0542 19.9999 10.2724 19.9999 10.5C19.9999 10.7276 19.9095 10.9458 19.7486 11.1068L14.6697 16.1857C14.3346 16.5208 13.7913 16.5208 13.4562 16.1857C13.1211 15.8506 13.1211 15.3073 13.4562 14.9722L17.9284 10.5L13.4562 6.02781C13.1211 5.69271 13.1211 5.14941 13.4562 4.81431Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.00659 10.2031C6.00659 9.7292 6.39076 9.34503 6.86467 9.34503H18.8777C19.3516 9.34503 19.7358 9.7292 19.7358 10.2031C19.7358 10.677 19.3516 11.0612 18.8777 11.0612H6.86467C6.39076 11.0612 6.00659 10.677 6.00659 10.2031Z'
        fill={color}
      />
    </svg>
  );
}
