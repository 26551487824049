import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Withdrawal } from '@/types';
import { transactionWithdrawal } from '@/lib/redux/slices/transaction-withdrawal/action';

interface TransactionWithdrawaState {
  withdrawal?: Withdrawal;
  isLoading: boolean;
  error?: string;
}

const initialState: TransactionWithdrawaState = {
  isLoading: true,
};
export const transactionWithdrawalSlice = createSlice({
  name: 'transactionWithdrawal',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(transactionWithdrawal.pending, state => {
      state.isLoading = true;
      state.withdrawal = undefined;
    });

    builder.addCase(transactionWithdrawal.fulfilled, (state, action: PayloadAction<Withdrawal>) => {
      state.withdrawal = action.payload;
      state.isLoading = false;
    });

    builder.addCase(transactionWithdrawal.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed withdrawal details';
    });
  },
});

export const { reset } = transactionWithdrawalSlice.actions;
