import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { useEffect } from 'react';
import { fetchRolesAndPermissions } from '@/lib/redux/slices/roles-and-permissions/actions';
import Spinner from '@/components/common/spinners/Spinner';
import RolesAndPermissionsTable from '@/components/access-management/roles-and-permissions/RolesAndPermissionsTable';
import styles from './RolesAndPermissions.module.scss';

const RolesAndPermissions = (): React.JSX.Element => {
  const { t } = useTranslation('accessManagement');
  const { roles, permissions, isLoading } = useAppSelector(state => state.rolesAndPermissions);
  const dispatch = useAppDispatch();

  useDocumentTitle(t('tabs.roles-and-permissions.page-title'));

  useEffect(() => {
    void dispatch(fetchRolesAndPermissions());
  }, [dispatch]);

  return (
    <div className={styles['table-wrapper']}>
      {isLoading && <Spinner />}
      {!isLoading && (
        <RolesAndPermissionsTable
          roles={roles}
          permissions={permissions}
        />
      )}
    </div>
  );
};

export default RolesAndPermissions;
