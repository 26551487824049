import { Outlet } from 'react-router-dom';
import styles from './CustomerDocumentsLayout.module.scss';
import { useSelector } from 'react-redux';
import { type RootState } from '@/lib/redux';
import Typography from '@/components/common/typography/Typography';
import { useTranslation } from 'react-i18next';

export default function CustomerDocumentsLayout(): React.JSX.Element | null {
  const { customer } = useSelector((state: RootState) => state.customer);
  const { t } = useTranslation('documentReviewQueue');

  if (customer !== undefined && customer !== null) {
    return (
      <div className={styles.page}>
        <div className={styles.header}>
          <div className={styles['customer-wrapper']}>
            <h2 className={styles['customer-name']}>
              {customer.firstName} {customer.lastName}
            </h2>
            <div>{t('document-layout.document-view')}</div>
            <Typography variant='p3Light'>
              {t('document-layout.customer-id')} {customer.customerId}
            </Typography>
          </div>
        </div>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
