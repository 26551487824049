import authAxios from '@/auth/axios';
import { type UserRole } from '@/types';
import { useEffect, useState } from 'react';

export function useUserRoles(): UserRole[] {
  const [roles, setRoles] = useState<UserRole[]>([]);

  useEffect(() => {
    authAxios
      .get<{ data: { data: UserRole[] } }>('/roles?limit=100&offset=0')
      .then(({ data }) => {
        setRoles(data?.data?.data ?? []);
      })
      .catch(() => {});
  }, []);
  return roles;
}
