import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector, type RootState } from '@/lib/redux';

const TabsRedirectLayout = (): JSX.Element => {
  const { country } = useAppSelector((state: RootState) => state.country);
  if (country.countryCode === '') {
    return <Navigate to='/configurations/countries/new/country-information' />;
  }
  return <Outlet />;
};

export default TabsRedirectLayout;
