import { marine } from '@/utils/colors';

function ArrowIcon({
  width = `${12 / 16}rem`,
  height = `${7 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.00338 4.89031L1.46218 0.256926C1.38272 0.175498 1.28826 0.110882 1.18422 0.0667896C1.08019 0.0226979 0.968637 -4.82196e-07 0.855972 -4.87121e-07C0.743306 -4.92046e-07 0.631752 0.0226979 0.527719 0.0667896C0.423686 0.110882 0.329226 0.175498 0.249768 0.256926C0.0897429 0.42201 1.23147e-06 0.644748 1.22133e-06 0.876841C1.21118e-06 1.10893 0.0897429 1.33167 0.249768 1.49676L5.3953 6.74494C5.5514 6.90375 5.76157 6.99504 5.98205 6.9998C6.20253 7.00457 6.41631 6.92243 6.57887 6.77051L11.7608 1.50442C11.9162 1.33931 12.0021 1.1187 12 0.889839C11.9978 0.66098 11.9079 0.442067 11.7494 0.279981C11.5909 0.117895 11.3764 0.0255232 11.1519 0.0226473C10.9275 0.0197715 10.7108 0.106621 10.5484 0.264595L6.00338 4.89031Z'
        fill={color}
      />
    </svg>
  );
}

export default ArrowIcon;
