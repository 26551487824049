import { updateUserDetails } from './actions';
import { createSlice } from '@reduxjs/toolkit';

interface UsersSliceState {
  success: boolean;
  loading: boolean;
}

const initialState: UsersSliceState = {
  success: false,
  loading: false,
};

export const updateUserDetailsSlice = createSlice({
  name: 'updateUserDetails',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(updateUserDetails.pending, state => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(updateUserDetails.fulfilled, state => {
      state.success = true;
      state.loading = false;
    });
    builder.addCase(updateUserDetails.rejected, state => {
      state.loading = false;
    });
  },
});

export const { reset } = updateUserDetailsSlice.actions;
