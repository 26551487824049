import { type Permissions } from '@/types';
import { usePermissions } from '@/utils/hooks/usePermissions';
import Spinner from '@/components/common/spinners/Spinner';
import { Navigate } from 'react-router-dom';
import { AppUrls } from '@/utils/constants';

export default function UserPermissionsGuard({
  children,
  permission,
  redirect,
}: Readonly<{
  children: React.JSX.Element;
  permission: Permissions;
  redirect?: string | boolean;
}>): React.JSX.Element | null {
  const hasPermission = usePermissions(permission);
  const isPageLoading = hasPermission === undefined;
  const isRedirect = redirect !== undefined;

  if (isPageLoading && isRedirect) {
    return <Spinner />;
  }

  if (isPageLoading) {
    return null;
  }

  if (hasPermission) {
    return children;
  }

  if (isRedirect) {
    return <Navigate to={typeof redirect === 'string' ? redirect : AppUrls.NotFoundUrl} />;
  }

  return null;
}
