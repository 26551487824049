import { type TFunction } from 'i18next';

export const getAuditLogsHeaders = (t: TFunction): Array<Record<string, string>> => [
  { label: t('widgets.audit-logs.timestamp') },
  { label: t('widgets.audit-logs.action') },
  { label: t('widgets.audit-logs.username') },
  { label: t('widgets.audit-logs.location') },
  { label: t('widgets.audit-logs.outcome') },
  { label: t('widgets.audit-logs.comment') },
  { label: t('widgets.audit-logs.details') },
  { label: t('widgets.audit-logs.category') },
  { label: t('widgets.audit-logs.ip') },
  { label: t('widgets.audit-logs.device-information') },
];

export const getLimitsHeaders = (t: TFunction): Array<Record<string, string>> => [
  { title: '' },
  { title: t('widgets.limits.per-transaction') },
  { title: t('widgets.limits.daily') },
  { title: t('widgets.limits.weekly') },
  { title: t('widgets.limits.monthly') },
];
