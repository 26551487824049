import navigationPages from './navigationConfig';
import { useNavigate } from 'react-router-dom';
import Typography from '@/components/common/typography/Typography';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import styles from './HomeNavigation.module.scss';

const HomeNavigation = (): JSX.Element => {
  const navigate = useNavigate();
  const handleClick = (href: string): void => {
    navigate(href);
  };
  return (
    <div className={styles.card}>
      {navigationPages.map((nav, index) => {
        const Icon = nav.icon;
        return (
          <UserPermissionsGuard
            key={nav.title}
            permission={nav.permission}
          >
            <div
              className={styles.navigationCard}
              onKeyDown={() => handleClick(nav.href)}
              onClick={() => handleClick(nav.href)}
            >
              <div className={styles.navigationItems}>
                <div className={styles.iconFrame}>
                  <Icon />
                </div>
                <div className={styles.label}>
                  <Typography variant='poBold'>{nav.title}</Typography>
                </div>
              </div>
            </div>
          </UserPermissionsGuard>
        );
      })}
    </div>
  );
};

export default HomeNavigation;
