import { createSlice } from '@reduxjs/toolkit';
import { transactionExpireCard } from '@/lib/redux/slices/transaction-expire-card/actions';

interface ExpireCardState {
  isLoading: boolean;
  success: boolean;
  error?: string;
}

const initialState: ExpireCardState = {
  isLoading: false,
  success: false,
};
export const transactionExpireCardSlice = createSlice({
  name: 'expireCardSlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(transactionExpireCard.pending, state => {
      state.success = false;
      state.isLoading = true;
    });

    builder.addCase(transactionExpireCard.fulfilled, state => {
      state.success = true;
      state.isLoading = false;
    });

    builder.addCase(transactionExpireCard.rejected, (state, action) => {
      state.success = false;
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed expire card';
    });
  },
});

export const { reset } = transactionExpireCardSlice.actions;
