import { marine, white } from '@/utils/colors';

function ErrorIcon(): React.JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='143'
      height='191'
      viewBox='0 0 143 191'
      fill='none'
    >
      <path
        d='M61.0769 190.791C94.8088 190.791 122.154 148.081 122.154 95.3953C122.154 42.7099 94.8088 0 61.0769 0C27.3451 0 0 42.7099 0 95.3953C0 148.081 27.3451 190.791 61.0769 190.791Z'
        fill='#310D86'
      />
      <path
        d='M72.7933 190.791C106.525 190.791 133.87 148.081 133.87 95.3953C133.87 42.7099 106.525 0 72.7933 0C39.0615 0 11.7164 42.7099 11.7164 95.3953C11.7164 148.081 39.0615 190.791 72.7933 190.791Z'
        fill={white.cssColor}
      />
      <path
        d='M81.6762 190.791C115.408 190.791 142.753 148.081 142.753 95.3953C142.753 42.7099 115.408 0 81.6762 0C47.9444 0 20.5993 42.7099 20.5993 95.3953C20.5993 148.081 47.9444 190.791 81.6762 190.791Z'
        fill={marine.cssColor}
      />
      <path
        d='M81.6762 158.576C104.015 158.576 122.125 130.289 122.125 95.3951C122.125 60.5011 104.015 32.2139 81.6762 32.2139C59.337 32.2139 41.2274 60.5011 41.2274 95.3951C41.2274 130.289 59.337 158.576 81.6762 158.576Z'
        fill='#EEF7F9'
      />
      <path
        d='M81.6762 126.04C92.5114 126.04 101.295 112.32 101.295 95.3951C101.295 78.4705 92.5114 64.7505 81.6762 64.7505C70.8411 64.7505 62.0575 78.4705 62.0575 95.3951C62.0575 112.32 70.8411 126.04 81.6762 126.04Z'
        fill={marine.cssColor}
      />
    </svg>
  );
}

export default ErrorIcon;
