import Table from '@/components/common/tables/Table';
import TickIcon from '@/components/icons/TickIcon';
import { type UserPermission, type UserRole } from '@/types';
import { useMemo } from 'react';
import styles from './RolesAndPermissionsTable.module.scss';
import classNames from 'classnames';
import InfoIcon from '@/components/icons/InfoIcon';
import { Tooltip } from 'react-tooltip';
import Typography from '@/components/common/typography/Typography';
import { black } from '@/utils/colors';

interface Props {
  roles: UserRole[];
  permissions: UserPermission[];
}

function HeadWrapper({ text }: Readonly<{ text: string }>): React.JSX.Element {
  const words = text.split(' ');
  const lastWord = words.length > 1 ? words.pop() : undefined;
  const firstPart = words.join(' ');
  const className = classNames(styles['table-cell'], {
    [styles['empty-cell']]: text === '',
  });

  return (
    <div className={className}>
      <div>{firstPart}</div>
      {lastWord && <div>{lastWord}</div>}
    </div>
  );
}

export default function RolesAndPermissionsTable({ roles, permissions }: Readonly<Props>): React.JSX.Element {
  const { columns, rows } = useMemo(() => {
    const columns: string[] = [''];
    const rows: Record<string, Record<string, boolean>> = {};
    for (const role of roles) {
      const roleLabel = role.comment ?? role.roleName;
      columns.push(roleLabel);
      if (role.rolePermissions) {
        for (const permission of role.rolePermissions) {
          if (!rows[permission.permissionName]) {
            rows[permission.permissionName] = {};
          }
          rows[permission.permissionName][roleLabel] = true;
        }
      }
    }
    return {
      columns,
      rows,
    };
  }, [roles]);

  return (
    <Table cols={columns.length}>
      {columns.map(column => (
        <Table.Head
          key={`${column}-key`}
          className={styles['right-border']}
        >
          <HeadWrapper text={column} />
        </Table.Head>
      ))}
      {permissions.map((permission, permissionIndex) => {
        const rowKey = permission.name;
        const className = classNames(styles['right-border'], {
          [styles['even-row']]: permissionIndex % 2 === 1,
        });
        return columns.map(column =>
          column === '' ? (
            <Table.Cell
              className={`${styles['permission-cell']} ${className}`}
              key={`${rowKey}-permission-cell-key`}
            >
              <div>{rowKey}</div>
              <span
                className={styles['info-icon']}
                data-tooltip-id={`${rowKey}-permission-cell-key`}
              >
                <InfoIcon />
              </span>
              <Tooltip
                id={`${rowKey}-permission-cell-key`}
                place='right'
                className={styles['info-icon-tooltip']}
              >
                <Typography
                  color={black.cssColor}
                  variant='p3Medium'
                >
                  {permission.comment ?? permission.name}
                </Typography>
              </Tooltip>
            </Table.Cell>
          ) : (
            <Table.Cell
              key={`${column}-${rowKey}`}
              className={`${styles['table-cell']} ${className}`}
            >
              {rows[rowKey]?.[column] ? <TickIcon /> : <div></div>}
            </Table.Cell>
          ),
        );
      })}
    </Table>
  );
}
