import CrossIcon from '@/components/icons/CrossIcon';
import { purple } from '@/utils/colors';
import React from 'react';
import styles from './Chip.module.scss';

interface ChipProps {
  label: string;
  readonly?: boolean;
  onDelete: () => void;
}

function Chip({ label, readonly = false, onDelete }: Readonly<ChipProps>): React.JSX.Element {
  return (
    <div
      className={styles.filterChip}
      onClick={onDelete}
      onKeyDown={onDelete}
    >
      <span>{label}</span>
      {!readonly && (
        <div className={styles.filterChipCloseIcon}>
          <CrossIcon
            width='0.6rem'
            height='0.6rem'
            color={purple.cssColor}
            thickness='3'
          />
        </div>
      )}
    </div>
  );
}

export default Chip;
