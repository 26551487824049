import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { boldRed, boldMarine } from '@/utils/colors';

const WrappedToastContainer = ({ className }: { className?: string }): React.JSX.Element => (
  <div className={className}>
    <ToastContainer
      position='top-right'
      autoClose={2500}
      hideProgressBar={true}
      closeOnClick={true}
      pauseOnHover={true}
      draggable={false}
      theme='colored'
    />
  </div>
);

const StyledContainer = styled(WrappedToastContainer)`
  --toastify-color-success: ${boldMarine.cssColor};
  --toastify-color-error: ${boldRed.cssColor};
  & .Toastify__toast-body {
    font-weight: 600;
    font-family: Moderat;
    line-height: 1.5rem;
    font-size: 1.125rem;
  }
`;

const Toast = (): JSX.Element => {
  return <StyledContainer />;
};

export default Toast;
