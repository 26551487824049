import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Localization.module.scss';
import NavDropdownWidget from './NavDropdownWidget';

const languages = [
  {
    value: 'en',
    name: 'English',
    shortName: 'EN',
    iconSrc: '/flags/USA.svg',
  },
  {
    value: 'es',
    name: 'Español',
    shortName: 'ES',
    iconSrc: '/flags/ES.svg',
  },
  {
    value: 'pt',
    name: 'Português',
    shortName: 'PT',
    iconSrc: '/flags/BR.svg',
  },
];
export default function Localization(): React.JSX.Element {
  const { i18n } = useTranslation();

  const { mainWidget, menuRows } = useMemo(() => {
    let mainWidget;
    const menuRows = [];
    for (const lang of languages) {
      if (lang.value === i18n.language) {
        mainWidget = (
          <div className={styles.pair}>
            <img
              alt={`locale ${lang.value} flag`}
              src={lang.iconSrc}
            />
            <span>{lang.shortName}</span>
          </div>
        );
      } else {
        const onClick = (): void => {
          void i18n.changeLanguage(lang.value);
          localStorage.setItem('i18nextLng', lang.value);
        };
        menuRows.push(
          <div
            className={styles.pair}
            onClick={onClick}
            onKeyDown={onClick}
          >
            <img
              alt={`locale ${lang.value} flag`}
              src={lang.iconSrc}
            />
            <span>{lang.name}</span>
          </div>,
        );
      }
    }
    return { mainWidget, menuRows };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, i18n.language]);

  return (
    <NavDropdownWidget
      mainWidget={mainWidget}
      menuRows={menuRows}
      showArrow
      rightAligned
    />
  );
}
