import Modal from '@/components/common/modals/Modal';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PaymentMethodTopupModal.module.scss';
import Button from '@/components/common/button/Button';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import type PaymentMethod from '@/types/PaymentMethod';
import { parseNumberOrDefault } from '@/utils/helperFunctions';
import Typography from '@/components/common/typography/Typography';
import { savePaymentMethodTopup } from '@/lib/redux/slices/payment-methods-topup/actions';
import { reset } from '@/lib/redux/slices/payment-methods-topup/slice';

import { Checkbox, Select } from 'k8-web-lib-tmp';
import { type Option } from '@/utils/interfaces';
import { searchChannels } from '@/lib/redux/slices/channels-search/actions';

interface Props {
  onClose: () => void;
}

export default function PaymentMethodTopupModal({ onClose }: Props): JSX.Element {
  const { t } = useTranslation('configurations');
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({
    name: '',
    nameTranslationKey: '',
    groupId: undefined,
    countryCode: '',
    isEnabled: false,
    imageUrl: '',
    channelId: '',
    description: '',
    descriptionTranslationKey: '',
    order: 0,
    minAmount: 0,
    minAmountCurrency: '',
    reason: '',
    allowInCheckout: false,
  });
  const { success, loading } = useAppSelector((state: RootState) => state.paymentMethodTopup);
  const {
    success: successSearch,
    loading: loadingSearch,
    channels,
  } = useAppSelector((state: RootState) => state.channelsSearch);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(
      searchChannels({
        transactionType: 'Topup',
        limit: 5,
      }),
    );
  }, [dispatch]);

  const channelsOptions = useMemo(() => {
    if (successSearch) {
      return channels.map(channel => ({
        text: channel.name,
        value: channel.id,
      }));
    }
    return [];
  }, [channels, successSearch]);

  const [selectedChannel, setSelectedChannel] = useState<Option>();

  useEffect(() => {
    if (success) {
      onClose();
    }
    return () => {
      dispatch(reset());
    };
  }, [success, onClose, dispatch]);

  const onSubmit = (): void => {
    void dispatch(savePaymentMethodTopup(paymentMethod));
  };

  const isValid = useMemo(() => {
    return Object.entries(paymentMethod).every(([key, value]) => {
      if (key === 'groupId') return true;

      if (value === undefined) return false;
      if (typeof value === 'string') return value.length > 0;
      if (typeof value === 'number') return value > 0;
      return true;
    });
  }, [paymentMethod]);

  const onSearch = useCallback(
    (value: string): void => {
      void dispatch(
        searchChannels({
          transactionType: 'Topup',
          name: value,
          limit: 5,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Modal onClose={onClose}>
      <div className={styles.title}>
        <Typography variant='p1SemiBold'>{t('payment-method-topup.create-title')}</Typography>
      </div>
      <div className={styles.container}>
        <div className={styles.child}>
          <InputLabel label={t('payment-method-topup.name')}>
            <Input
              value={paymentMethod.name}
              onChange={value => setPaymentMethod({ ...paymentMethod, name: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.group-id')}>
            <Input
              value={paymentMethod.groupId ?? ''}
              onChange={value => setPaymentMethod({ ...paymentMethod, groupId: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.image-url')}>
            <Input
              value={paymentMethod.imageUrl}
              onChange={value => setPaymentMethod({ ...paymentMethod, imageUrl: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.description')}>
            <Input
              value={paymentMethod.description}
              onChange={value => setPaymentMethod({ ...paymentMethod, description: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.order')}>
            <Input
              value={String(paymentMethod.order)}
              onChange={value =>
                setPaymentMethod({ ...paymentMethod, order: parseNumberOrDefault(value, paymentMethod.order) })
              }
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.min-amount-currency')}>
            <Input
              value={paymentMethod.minAmountCurrency}
              onChange={value => setPaymentMethod({ ...paymentMethod, minAmountCurrency: value })}
            />
          </InputLabel>
        </div>
        <div className={styles.child}>
          <InputLabel label={t('payment-method-topup.name-translation-key')}>
            <Input
              value={paymentMethod.nameTranslationKey}
              onChange={value => setPaymentMethod({ ...paymentMethod, nameTranslationKey: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.country-code')}>
            <Input
              value={paymentMethod.countryCode}
              onChange={value => setPaymentMethod({ ...paymentMethod, countryCode: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.channel-name')}>
            <Select
              options={channelsOptions}
              selected={selectedChannel}
              onSelect={option => {
                setSelectedChannel(option);
                setPaymentMethod({ ...paymentMethod, channelId: option.value ?? '' });
              }}
              onSearch={onSearch}
              loading={loadingSearch}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.description-translation-key')}>
            <Input
              value={paymentMethod.descriptionTranslationKey}
              onChange={value => setPaymentMethod({ ...paymentMethod, descriptionTranslationKey: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.min-amount')}>
            <Input
              value={String(paymentMethod.minAmount)}
              onChange={value =>
                setPaymentMethod({ ...paymentMethod, minAmount: parseNumberOrDefault(value, paymentMethod.minAmount) })
              }
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.reason')}>
            <Input
              value={paymentMethod.reason}
              onChange={value => setPaymentMethod({ ...paymentMethod, reason: value })}
            />
          </InputLabel>
          <Checkbox
            checked={paymentMethod.isEnabled}
            labelPosition={'left'}
            onChange={(value: boolean) => setPaymentMethod({ ...paymentMethod, isEnabled: value })}
          >
            <InputLabel label={t('payment-method-topup.is-enabled')} />
          </Checkbox>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          disabled={!isValid || loading}
          textTransform='uppercase'
          onClick={onSubmit}
        >
          {t('payment-method-topup.save-button')}
        </Button>
      </div>
    </Modal>
  );
}
