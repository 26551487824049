import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { searchChannels } from '@/lib/redux/slices/channels-search/actions';
import { type Channel } from '@/types/Channel';

export interface ChannelsSearchState {
  channels: Channel[];
  loading: boolean;
  success: boolean;
}

const initialState: ChannelsSearchState = {
  channels: [],
  loading: false,
  success: false,
};

export const channelsSearchSlice = createSlice({
  name: 'channels-search',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(searchChannels.pending, state => {
      state.loading = true;
    });
    builder.addCase(searchChannels.fulfilled, (state, action: PayloadAction<Channel[]>) => {
      state.channels = action.payload ?? [];
      state.loading = false;
      state.success = true;
    });
    builder.addCase(searchChannels.rejected, state => {
      state.channels = [];
      state.loading = false;
    });
  },
});

export const { reset } = channelsSearchSlice.actions;
