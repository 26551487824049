import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Breadcrumbs.module.scss';
import Typography from '@/components/common/typography/Typography';
import { mediumPurple } from '@/utils/colors';
import i18n from '@/i18n/config';

export default function Breadcrumbs({
  urlToLabel,
  homeLabel = i18n.t('breadcrumbs.home'),
}: Readonly<{ urlToLabel: Record<string, string>; homeLabel?: string }>): React.JSX.Element {
  const loc = useLocation();

  const routePatterns = useMemo(() => {
    const patterns: Record<string, RegExp> = {};
    Object.keys(urlToLabel).forEach(key => {
      const regex = new RegExp(
        `^${key
          .split('/')
          .map(part => (part.startsWith(':') ? '([^/]+)' : part))
          .join('/')}/*$`,
      );
      patterns[key] = regex;
    });
    return patterns;
  }, [urlToLabel]);

  const convertPathToPlaceholder = useCallback(
    (path: string): string | null => {
      for (const key in routePatterns) {
        if (routePatterns[key].test(path)) {
          return key;
        }
      }
      return null;
    },
    [routePatterns],
  );

  const breadcrumbLinks = useMemo(() => {
    const pathnames = loc.pathname.split('/').filter(x => x);
    const result = pathnames.reduce((acc: string[], value, index) => {
      const currentPath = `${index > 0 ? acc[index - 1] : ''}/${value}`;
      acc.push(currentPath);
      return acc;
    }, []);
    return result.filter(res => convertPathToPlaceholder(res) !== null);
  }, [loc, convertPathToPlaceholder]);

  return (
    <div className={styles['flex-row']}>
      {breadcrumbLinks.length > 0 && (
        <>
          <Link to='/'>
            <Typography
              variant='p2'
              color={mediumPurple.cssColor}
            >
              {homeLabel}
            </Typography>
          </Link>
          <div className={styles.slash}>
            <Typography
              variant='p2'
              color={mediumPurple.cssColor}
            >
              /
            </Typography>
          </div>
        </>
      )}
      {breadcrumbLinks.map((link, index) => {
        const isLast = index === breadcrumbLinks.length - 1;
        const pathKey = convertPathToPlaceholder(link);
        if (pathKey !== null) {
          return (
            <div
              className={styles['flex-row']}
              key={link}
            >
              {isLast ? (
                <Typography variant='p2'>{urlToLabel[pathKey]}</Typography>
              ) : (
                <>
                  <Link to={link}>
                    <Typography
                      variant='p2'
                      color={mediumPurple.cssColor}
                    >
                      {urlToLabel[pathKey]}
                    </Typography>
                  </Link>
                  <div className={styles.slash}>
                    <Typography
                      variant='p2'
                      color={mediumPurple.cssColor}
                    >
                      /
                    </Typography>
                  </div>
                </>
              )}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}
