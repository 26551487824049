import { marine } from '@/utils/colors';

export default function FilledCircleIcon({
  width = `${18 / 16}rem`,
  height = `${18 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='9'
        cy='9'
        r='9'
        fill='#BEFFF7'
      />
      <circle
        cx='9'
        cy='9'
        r='5'
        fill={color}
      />
    </svg>
  );
}
