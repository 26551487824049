import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { ClickAwayListener } from '@mui/base';

import DropdownOption from './DropdownOption';
import ArrowIcon from '@/components/icons/ArrowIcon';
import { extraLightGrey } from '@/utils/colors';
import type { Option } from '@/utils/interfaces';
import Typography from '@/components/common/typography/Typography';

import styles from './MultiSelectDropdown.module.scss';
import FilterChip from '@/components/common/chip/Chip';
import { useTranslation } from 'react-i18next';
import { calculateHeightAndApplyStyles } from './DropdownUtils';

export interface MultiSelectDropdownProps {
  label?: string;
  options: Option[];
  selectedOptions: Option[];
  onChange: (options: Option[]) => void;
  excludeAll?: boolean;
}

function MultiSelectDropdown({
  label,
  options,
  selectedOptions,
  onChange,
  excludeAll = false,
}: Readonly<MultiSelectDropdownProps>): React.JSX.Element {
  const { t } = useTranslation(['global']);
  const allOptionValue = useMemo(() => t('filters.all-filter-value'), [t]);
  const optionsComputed = useMemo(() => {
    const optionsIncludeAllOption = excludeAll ? options : [{ text: allOptionValue }, ...options];
    if (selectedOptions.length > 0) {
      const mapElements = selectedOptions.map(x => x.text);
      return optionsIncludeAllOption.filter(option => !mapElements.includes(option.text));
    }
    return optionsIncludeAllOption;
  }, [options, allOptionValue, selectedOptions, excludeAll]);

  const selectedOptionsComputed = useMemo(() => {
    if (selectedOptions.length === 0 && !excludeAll) {
      return [{ text: allOptionValue }];
    }
    return selectedOptions;
  }, [selectedOptions, allOptionValue, excludeAll]);

  const [opened, setOpened] = useState(false);
  const multiselectDropdownListRef = useRef<HTMLUListElement>(null);

  const multiselectDropdownClasses = classNames(styles.dropdown, {
    [styles['dropdown--active']]: opened,
  });
  const multiselectDropdownListClasses = classNames(styles.dropdown__list, {
    [styles['dropdown__list--active']]: opened,
  });
  const multiselectSelectedValueClasses = classNames(styles['dropdown__selected-value']);
  const arrowClasses = classNames(styles.dropdown__arrow, {
    [styles['dropdown__arrow--opened']]: opened,
  });

  const onAddSelectedOption = (option: Option): void => {
    setOpened(!opened);
    if (option.text === allOptionValue) {
      onChange([]);
    } else {
      onChange([...selectedOptions, option]);
    }
  };

  const onDeleteSelectedOption = (option: Option): void => {
    onChange(selectedOptions.filter(x => x.text !== option.text));
  };

  useLayoutEffect(() => {
    if (opened && multiselectDropdownListRef.current) {
      calculateHeightAndApplyStyles(multiselectDropdownListRef.current);
    }
  }, [opened]);

  return (
    <div className={styles['dropdown-wrapper']}>
      {label && <Typography variant='p3'>{label}</Typography>}
      <ClickAwayListener onClickAway={() => setOpened(false)}>
        <div className={multiselectDropdownClasses}>
          <button
            className={styles.dropdown__button}
            onClick={() => setOpened(!opened)}
          >
            <span className={multiselectSelectedValueClasses}>
              {selectedOptionsComputed.map(item => (
                <FilterChip
                  key={item.value}
                  readonly={item.text === allOptionValue}
                  label={item.text}
                  onDelete={() => onDeleteSelectedOption(item)}
                />
              ))}
              {selectedOptionsComputed.length === 0 && ' '}
            </span>
            <span className={arrowClasses}>
              {!opened ? <ArrowIcon color={extraLightGrey.cssColor} /> : <ArrowIcon />}
            </span>
          </button>
          <ul
            className={multiselectDropdownListClasses}
            ref={multiselectDropdownListRef}
          >
            {optionsComputed.map(option => (
              <DropdownOption
                key={option.text}
                text={option.text}
                active={false}
                onOptionClick={() => onAddSelectedOption(option)}
                hidden={option.hidden}
                displayType='inline'
              />
            ))}
          </ul>
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default MultiSelectDropdown;
