import { useTranslation } from 'react-i18next';
import LockOpenIcon from '@/components/icons/LockOpenIcon';
import IconButton from '../IconButton';
import styles from './IconStroke.module.scss';

export default function UnblockButton({ onClick }: Readonly<{ onClick?: () => void }>): React.JSX.Element {
  const { t } = useTranslation('global');

  return (
    <IconButton
      icon={<LockOpenIcon />}
      label={t('buttons.unblock')}
      className={styles.button}
      onClick={onClick}
      textTransform='uppercase'
    />
  );
}
