import React, { useState } from 'react';
import InputLabel from '@/components/common/labels/InputLabel';
import NumberInput from '@/components/common/inputs/NumberInput';
import Input from '@/components/common/inputs/Input';
import { useTranslation } from 'react-i18next';
import styles from '@/components/customer/transaction-history/transaction-actions/TransactionActions.module.scss';
import type { NumberFormatValues } from 'react-number-format';
import { ModalActions } from '@/components/customer/kyc-risk/audit-logs/ActionModal/Styles';
import Button from '@/components/common/button/Button';

export interface ActionInputProps {
  disabledButton: boolean;
  onConfirm: (amount: number, reason: string) => void;
  onClose: () => void;
  inputLabel?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  hint?: string;
}

const ActionInput: React.FC<ActionInputProps> = ({
  disabledButton,
  onConfirm,
  onClose,
  inputLabel,
  isAllowed,
  hint,
}) => {
  const { t } = useTranslation(['customerOverview', 'global']);
  const [requestedAmountNumber, setRequestedAmountNumber] = useState(0);
  const [requestedAmountString, setRequestedAmountString] = useState('');
  const [reason, setReason] = useState('');
  const isSubmitEnabled = requestedAmountNumber > 0 && reason.length > 0;
  const handleAmount = (values: NumberFormatValues): void => {
    const { value, floatValue } = values;
    setRequestedAmountNumber(floatValue ?? 0);
    setRequestedAmountString(value);
  };

  const handleReason = (updatedReason: string): void => {
    setReason(updatedReason);
  };

  return (
    <div>
      <div className={styles.contentWrapper}>
        <InputLabel label={inputLabel ?? t('widgets.transaction-history.modal-input-amount')}>
          <NumberInput
            value={requestedAmountString}
            onChange={handleAmount}
            isAllowed={
              isAllowed ??
              (() => {
                return true;
              })
            }
            hint={hint}
          />
        </InputLabel>
        <InputLabel label={t('widgets.transaction-history.modal-input-reason')}>
          <Input
            value={reason}
            onChange={handleReason}
          />
        </InputLabel>
      </div>
      <ModalActions>
        <Button
          disabled={disabledButton}
          variant='secondary'
          onClick={onClose}
          textTransform='uppercase'
        >
          {t('confirm-modal.cancel', { ns: 'global' })}
        </Button>
        <Button
          disabled={!isSubmitEnabled || disabledButton}
          onClick={() => onConfirm(requestedAmountNumber, reason)}
          textTransform='uppercase'
        >
          {t('confirm-modal.confirm', { ns: 'global' })}
        </Button>
      </ModalActions>
    </div>
  );
};

export default ActionInput;
