import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import Toast from '@/components/toast/Toast';

function App(): React.JSX.Element {
  return (
    <>
      <RouterProvider router={router} />
      <Toast />
    </>
  );
}

export default App;
