import ThreeColumnsWidget, { type Record } from '@/components/common/layout/ThreeColumnsWidget';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const TransactionView = ({
  columnOne,
  columnTwo,
  columnThree,
}: {
  columnOne: Record[];
  columnTwo: Record[];
  columnThree: Record[];
}): JSX.Element => {
  const { t } = useTranslation('customerOverview');
  const colOne: Record[] = useMemo(
    () =>
      columnOne.map(col => ({
        ...col,
        label: t(`widgets.transaction-details.${col.label}`, { defaultValue: 'New type' }),
      })),
    [columnOne, t],
  );
  const colTwo: Record[] = useMemo(
    () =>
      columnTwo.map(col => ({
        ...col,
        label: t(`widgets.transaction-details.${col.label}`, { defaultValue: 'New type' }),
      })),
    [columnTwo, t],
  );
  const colThree: Record[] = useMemo(
    () =>
      columnThree.map(col => ({
        ...col,
        label: t(`widgets.transaction-details.${col.label}`, { defaultValue: 'New type' }),
      })),
    [columnThree, t],
  );
  return (
    <ThreeColumnsWidget
      columnOne={colOne}
      columnTwo={colTwo}
      columnThree={colThree}
    />
  );
};

export default TransactionView;
