export default function InfoIcon({
  width = `${15 / 16}rem`,
  height = `${15 / 16}rem`,
}: Readonly<{
  width?: string;
  height?: string;
}>): React.JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
    >
      <path
        d='M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z'
        fill='#BEFFF7'
      />
      <path
        d='M7.23244 6.48438H6.61985C6.49806 6.48438 6.38126 6.53276 6.29514 6.61887C6.20902 6.70499 6.16064 6.82179 6.16064 6.94358C6.16064 7.06537 6.20902 7.18217 6.29514 7.26828C6.38126 7.3544 6.49806 7.40278 6.61985 7.40278H7.23195C7.25212 7.40258 7.27212 7.40641 7.29079 7.41404C7.30946 7.42166 7.32642 7.43293 7.34069 7.4472C7.35495 7.46146 7.36622 7.47842 7.37385 7.49709C7.38147 7.51576 7.3853 7.53576 7.3851 7.55593V10.617C7.3851 10.7387 7.43348 10.8555 7.5196 10.9417C7.60571 11.0278 7.72251 11.0762 7.8443 11.0762C7.96609 11.0762 8.08289 11.0278 8.169 10.9417C8.25512 10.8555 8.3035 10.7387 8.3035 10.617V7.55593C8.3022 7.27222 8.18895 7.0005 7.98839 6.79984C7.78782 6.59918 7.51615 6.48581 7.23244 6.48438Z'
        fill='#008273'
      />
      <path
        d='M7.84396 4.03516C7.72252 4.03606 7.60631 4.08471 7.52044 4.17058C7.43456 4.25646 7.38592 4.37267 7.38501 4.49411V5.10671C7.38501 5.2285 7.43339 5.3453 7.51951 5.43142C7.60562 5.51754 7.72242 5.56592 7.84421 5.56592C7.966 5.56592 8.0828 5.51754 8.16892 5.43142C8.25503 5.3453 8.30341 5.2285 8.30341 5.10671V4.49411C8.3025 4.37258 8.25379 4.2563 8.16781 4.17041C8.08183 4.08452 7.96549 4.03593 7.84396 4.03516Z'
        fill='#008273'
      />
    </svg>
  );
}
