import React from 'react';

import styles from './TransactionStatusStamp.module.scss';
import { TransactionStatus } from '@/types';
import { splitCapitalizedWords } from '@/utils/helperFunctions';
import classNames from 'classnames';

interface TransactionStatusStampProps {
  status?: TransactionStatus;
}

function TransactionStatusStamp({ status }: Readonly<TransactionStatusStampProps>): React.JSX.Element {
  const definedStatus: string = status === undefined || !(status in TransactionStatus) ? 'Unknown' : status;
  const className = classNames(styles['status-stamp'], styles[definedStatus]);

  return <span className={className}>{splitCapitalizedWords(definedStatus)}</span>;
}

export default TransactionStatusStamp;
