import { useUrlState, useUrlStateArray } from '@/utils/hooks/useUrlState';
import { useCallback, useMemo } from 'react';
export const FILTER_URL_KEYS = {
  id: 'transactionId',
  slipId: 'slipId',
  type: 'type',
  status: 'status',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  pspTransactionId: 'pspTransactionId',
  country: 'country',
  phone: 'phone',
  customerId: 'customerId',
};
export function useTransactionIdFilter(): [string | undefined, (newValue: string) => void] {
  const [value, setValue] = useUrlState(FILTER_URL_KEYS.id);
  return [value.trim() ? value.trim() : undefined, setValue];
}
export function usePspTransactionIdFilter(): [string | undefined, (newValue: string) => void] {
  const [value, setValue] = useUrlState(FILTER_URL_KEYS.pspTransactionId);
  return [value.trim() ? value.trim() : undefined, setValue];
}

export function useSlipIdFilter(): [string | undefined, (newValue: string) => void] {
  const [value, setValue] = useUrlState(FILTER_URL_KEYS.slipId);
  return [value.trim() ? value.trim() : undefined, setValue];
}

export function usePhoneNumberFilter(): [string | undefined, (newValue: string) => void] {
  const [value, setValue] = useUrlState(FILTER_URL_KEYS.phone);
  return [value.trim() ? value.trim() : undefined, setValue];
}

export function useCustomerIdFilter(): [string | undefined, (newValue: string) => void] {
  const [value, setValue] = useUrlState(FILTER_URL_KEYS.customerId);
  return [value.trim() ? value.trim() : undefined, setValue];
}

export function useCountryFilter(): [string | undefined, (newValue: string) => void] {
  const [value, setValue] = useUrlState(FILTER_URL_KEYS.country);
  return [value.trim() ? value.trim() : undefined, setValue];
}

export function useTransactionTypeFilter(): [
  string[],
  (newValue: string[]) => void,
] {
  return useArrayFilter(FILTER_URL_KEYS.type);
}
export function useTransactionStatusFilter(): [
  string[],
  (newValue: string[]) => void,
] {
  return useArrayFilter(FILTER_URL_KEYS.status);
}

function useArrayFilter(key: string): [
  string[],
  (newValue: string[]) => void,
] {
  const [urlValues, setUrlValues] = useUrlStateArray(key);
  const values = useMemo(() => urlValues, [urlValues]);
  const setValues = useCallback(
    (newValues: string[]) => setUrlValues(newValues?.map(x => x.toString() ?? '') ?? []),
    [setUrlValues],
  );
  return [values, setValues];
}

export function useDateRangeFilter(): [[string, string], (newValue: [string, string]) => void] {
  const [dateFrom, setDateFrom] = useUrlState(FILTER_URL_KEYS.dateFrom);
  const [dateTo, setDateTo] = useUrlState(FILTER_URL_KEYS.dateTo);
  const result: [[string, string], (newValue: [string, string]) => void] = useMemo(
    () => [
      [dateFrom, dateTo],
      (newValues: [string, string]) => {
        setDateFrom(newValues[0]);
        setDateTo(newValues[1]);
      },
    ],
    [dateFrom, dateTo, setDateFrom, setDateTo],
  );
  return result;
}
