import {
  ListIcon,
  PersonIcon,
  SettingsIcon,
  StoreFrontIcon,
  // LockIcon,
  // AutorenewIcon,
  // DiamondIcon,
  // StarIcon,
} from '@/components/icons/navigation-icons';
import { Permissions } from '@/types';

const navigationPages = [
  {
    title: 'Access Management',
    href: '/access-management/users',
    icon: PersonIcon,
    permission: Permissions.accessManagementRead,
  },
  {
    title: 'Document Review Queue',
    href: '/document-review-queue/identity-docs',
    icon: ListIcon,
    permission: Permissions.docQueueView,
  },
  {
    title: 'Business Accounts',
    href: '/business-accounts/overview',
    icon: StoreFrontIcon,
    permission: Permissions.businessAccountsRead,
  },
  {
    title: 'Configurations',
    href: '/configurations',
    icon: SettingsIcon,
    permission: Permissions.countryConfigRead,
  },
  // {
  //   title: 'VIP Program',
  //   href: '',
  //   icon: StarIcon,
  //   permission: Permissions.forbidentRequest,
  // },
  // {
  //   title: 'Loyalty Program',
  //   href: '',
  //   icon: DiamondIcon,
  //   permission: Permissions.forbidentRequest,
  // },
  // {
  //   title: 'Transactions',
  //   href: '/business-accounts/transaction-history',
  //   icon: AutorenewIcon,
  //   permission: Permissions.forbidentRequest,
  // },
  // {
  //   title: 'Restriction Service',
  //   href: '/access-management/roles-and-permissions',
  //   icon: LockIcon,
  //   permission: Permissions.forbidentRequest,
  // },
];

export default navigationPages;
