import { type RemoteImageProps } from '@/components/common/images/RemoteImage';
import { useMemo } from 'react';
import ImagesBox from '@/components/customer/documents/id-docs/ImagesBox';
import { type RootState, useAppSelector } from '@/lib/redux';
import { toTitleCase } from '@/utils/typography';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';

export default function IdentityDocumentsPage(): React.JSX.Element | null {
  const { customer } = useAppSelector((state: RootState) => state.customer);
  const { identityDocs } = useAppSelector((state: RootState) => state.identityDocs);

  const documentTitle =
    customer?.customerId && identityDocs
      ? `${customer.firstName} ${customer.lastName} - ${toTitleCase(identityDocs.docType)}`
      : '';

  useDocumentTitle(documentTitle);

  const images = useMemo(() => {
    const result: RemoteImageProps[] = [];
    if (customer?.customerId && identityDocs) {
      if (identityDocs.frontImageId) {
        result.push({
          url: `/identity-docs/approved/${identityDocs.identityDocId}/${identityDocs.frontImageId}`,
          alt: 'front side',
          config: {
            headers: {
              'x-customer-id': customer?.customerId,
            },
          },
        });
      }
      if (identityDocs.backImageId) {
        result.push({
          url: `/identity-docs/approved/${identityDocs.identityDocId}/${identityDocs.backImageId}`,
          alt: 'back side',
          config: {
            headers: {
              'x-customer-id': customer?.customerId,
            },
          },
        });
      }
    }
    return result;
  }, [customer?.customerId, identityDocs]);

  if (customer?.customerId && identityDocs) {
    return <ImagesBox images={images} />;
  } else {
    return null;
  }
}
