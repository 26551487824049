import Table from '@/components/common/tables/Table';
import { type PaymentCardInfo } from '@/types';
import { useTranslation } from 'react-i18next';
import CardRow from './CardRow';
import { type ClickOptions } from '@/utils/hooks/useMouseClicks';

export default function CustomerCardsTable({
  cards,
  onClick,
}: Readonly<{ cards: PaymentCardInfo[]; onClick: (id: string, options?: ClickOptions) => void }>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');
  return (
    <Table colsTemplate={'auto auto auto auto auto auto min-content'}>
      <Table.Head>{t('widgets.cards-tab.card-number')}</Table.Head>
      <Table.Head>{t('widgets.cards-tab.type')}</Table.Head>
      <Table.Head>{t('widgets.cards-tab.status')}</Table.Head>
      <Table.Head>{t('widgets.cards-tab.activation-date')}</Table.Head>
      <Table.Head>{t('widgets.cards-tab.creation-date')}</Table.Head>
      <Table.Head>{t('widgets.cards-tab.pomelo-id')}</Table.Head>
      <Table.Head>{t('widgets.cards-tab.actions')}</Table.Head>
      {cards.map(card => {
        return (
          <CardRow
            key={card.id}
            card={card}
            onClick={onClick}
          />
        );
      })}
    </Table>
  );
}
