import VerticalLine from '@/components/common/vertical-line/VerticalLine';
import styles from './ThreeColumnsWidget.module.scss';
import { lightWhite } from '@/utils/colors';
import ColumnCard from './ColumnCard';

export interface Record {
  label: string;
  value?: string | number | JSX.Element;
  highlight?: boolean;
}

const ThreeColumnsWidget = ({
  columnOne,
  columnTwo,
  columnThree,
}: {
  columnOne: Record[];
  columnTwo: Record[];
  columnThree: Record[];
}): JSX.Element => {
  return (
    <div className={styles.transactionCard}>
      <ColumnCard records={columnOne} />
      <div>
        <VerticalLine
          size='large'
          color={lightWhite}
        />
      </div>
      <ColumnCard records={columnTwo} />
      <div>
        <VerticalLine
          size='large'
          color={lightWhite}
        />
      </div>
      <ColumnCard records={columnThree} />
    </div>
  );
};

export default ThreeColumnsWidget;
