import { createAsyncThunk } from '@reduxjs/toolkit';
import { type Reversal } from '@/types/Reversal';
import authAxios from '@/auth/axios';
import { type AxiosResponse } from 'axios';
import { errorToast, successToast } from '@/utils/toastMessage';

export interface ReversalTransactionRequest {
  transactionId: string;
  customerId: string;
}

interface ReversalTransactionResponse {
  data: Reversal;
}

export const fetchTransactionReversal = createAsyncThunk<Reversal, ReversalTransactionRequest>(
  'detailReversalTransactionHistory/fetch',
  async ({ transactionId, customerId }, thunkApi) => {
    try {
      const response: AxiosResponse<ReversalTransactionResponse> = await authAxios.get<ReversalTransactionResponse>(
        `/reversals/${transactionId}`,
        {
          headers: { 'x-customer-id': customerId },
        },
      );
      return response.data.data;
    } catch (err: any) {
      errorToast();
      return thunkApi.rejectWithValue(err);
    }
  },
);

export const transactionCreateReversal = createAsyncThunk(
  'transaction/createReversal',
  async ({ amount, reason, slipId }: { amount: number; reason: string; slipId: string }, thunkApi) => {
    try {
      await authAxios.post('/reversals', { amount, reason, slipId });
      successToast('toast-messages.manual-transaction');
    } catch (err: any) {
      errorToast();
      return thunkApi.rejectWithValue(err);
    }
  },
);
