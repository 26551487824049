import React from 'react';

import styles from './TransactionHistoryTab.module.scss';
import { Outlet } from 'react-router-dom';

function TransactionHistoryTab(): React.JSX.Element {
  return (
    <div className={styles['transaction-history-tab']}>
      <div className={styles['transaction-history-tab__content']}>
        <Outlet />
      </div>
    </div>
  );
}

export default TransactionHistoryTab;
