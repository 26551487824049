import { type Color } from '.';

const white: Color = {
  class: 'text-white',
  modifier: '--white',
  cssColor: 'var(--color-white-100)',
};
const extraLightWhite: Color = {
  class: 'text-extra-light-white',
  modifier: '--extra-light-white',
  cssColor: 'var(--color-white-200)',
};
const lightWhite: Color = {
  class: 'text-light-white',
  modifier: '--light-white',
  cssColor: 'var(--color-white-300)',
};
const normalWhite: Color = {
  class: 'text-normal-white',
  modifier: '--normal-white',
  cssColor: 'var(--color-white-400)',
};

export { white, extraLightWhite, lightWhite, normalWhite };
