import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch, type RootState } from '@/lib/redux';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import Typography from '@/components/common/typography/Typography';
import Button from '@/components/common/button/Button';
import styles from './CountriesConfigurations.module.scss';
import { Permissions } from '@/types';
import ContentBox from '@/components/common/content-box/ContentBox';
import { countries } from '@/lib/redux/slices/countries/actions';
import CountriesTable from '@/components/configurations/countries/CountriesTable';
import Spinner from '@/components/common/spinners/Spinner';

export default function CountriesConfigurations(): React.JSX.Element {
  const { t } = useTranslation('configurations');

  const { list, isLoading } = useAppSelector((state: RootState) => state.countries);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useDocumentTitle(t('countries-page-title'));

  const handleNewCountry = (): void => {
    navigate('/configurations/countries/new');
  };

  useEffect(() => {
    void dispatch(countries());
  }, [dispatch]);

  return (
    <ContentBox>
      <div className={styles.title}>
        <Typography variant='p1SemiBold'>{t('countries')}</Typography>
        <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
          <Button
            textTransform='uppercase'
            onClick={handleNewCountry}
          >
            + {t('button-new-country')}
          </Button>
        </UserPermissionsGuard>
      </div>
      {isLoading && <Spinner />}
      {!isLoading && <CountriesTable list={list} />}
      <div className={styles.emptyCard}></div>
    </ContentBox>
  );
}
