import { useCallback, useEffect, useState } from 'react';
import { getAuditLogs } from '@/lib/redux/slices/audit-logs/actions';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { type AuditLogsState } from '@/lib/redux/slices/audit-logs/slice';
import usePagination from './usePagination';

interface UseAuditLogs extends AuditLogsState {
  setPage: (n: number) => void;
  orderData: () => void;
  refetch: () => void;
}

export function useAuditLogs(customerId: string | undefined, itemsPerPage: number): UseAuditLogs {
  const auditLogs = useAppSelector((state: RootState) => state.auditLogsSlice);
  const dispatch = useAppDispatch();
  const [page, setPage] = usePagination(auditLogs.page);
  const [order, setOrder] = useState('desc');

  const orderData = (): void => {
    setOrder(val => (val === 'asc' ? 'desc' : 'asc'));
  };

  const refetch = useCallback(() => {
    void dispatch(getAuditLogs({ customerId: customerId ?? '', page, itemsPerPage, order }));
  }, [customerId, dispatch, page, itemsPerPage, order]);

  useEffect(() => {
    if (customerId !== undefined) {
      refetch();
    }
  }, [customerId, refetch]);

  return {
    ...auditLogs,
    setPage,
    orderData,
    refetch,
  };
}
