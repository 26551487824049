import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import type { Chargeback } from '@/types/Chargeback';
import type { AxiosResponse } from 'axios';
import { errorToast } from '@/utils/toastMessage';

export interface ChargebackTransactionRequest {
  transactionId: string;
}

interface ChargebackTransactionResponse {
  data: Chargeback;
}

export const fetchTransactionChargeback = createAsyncThunk<Chargeback, ChargebackTransactionRequest>(
  'detailChargebackTransactionHistory/fetch',
  async ({ transactionId }, thunkApi) => {
    try {
      const response: AxiosResponse<ChargebackTransactionResponse> = await authAxios.get<ChargebackTransactionResponse>(
        `/chargebacks/${transactionId}`,
      );
      const chargeback = response.data.data;
      return chargeback;
    } catch (err: any) {
      errorToast();
      return thunkApi.rejectWithValue(err);
    }
  },
);
