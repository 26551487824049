import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import VerticalLine from '@/components/common/vertical-line/VerticalLine';
import SearchComponent from '@/components/search-component/SearchComponent';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { HeaderContext } from '../Header';

import { Permissions } from '@/types';
import { boldGrey, purple } from '@/utils/colors';

import styles from './Navigation.module.scss';
import UserProfile from './UserProfile';
import Localization from './Localization';
import MenuIcon from './Menu';

function Navigation(): React.JSX.Element {
  const { hideDocQueue, hideHamburger } = useContext(HeaderContext);
  const { t } = useTranslation('global');

  const { customerId } = useParams<{ customerId: string | undefined }>();

  return (
    <div className={styles['header__nav-wrapper']}>
      <nav className={styles.header__nav}>
        <ul className={styles['header__nav-list']}>
          {!hideDocQueue && (
            <UserPermissionsGuard permission={Permissions.docQueueView}>
              <li className={styles['header__nav-item']}>
                <Link
                  className={styles['header__nav-link']}
                  to='/document-review-queue/identity-docs'
                >
                  {t('header.document-review-queue-link')}
                </Link>
              </li>
            </UserPermissionsGuard>
          )}
          {customerId !== undefined && (
            <li className={styles['header__nav-item']}>
              <SearchComponent
                type='inline'
                autoFocus={false}
                primaryColor={purple}
              />
            </li>
          )}
        </ul>
      </nav>
      <VerticalLine color={boldGrey} />
      <UserProfile />
      <VerticalLine color={boldGrey} />
      <Localization />
      {!hideHamburger && (
        <>
          <VerticalLine color={boldGrey} />
          <MenuIcon />
        </>
      )}
    </div>
  );
}

export default Navigation;
