import authAxios from '@/auth/axios';
import { type BusinessAccounts } from '@/types';
import { errorToast, successToast } from '@/utils/toastMessage';
import { createAsyncThunk } from '@reduxjs/toolkit';

export enum AdjustmentType {
  Topup = 'MerchantTopup',
  Withdraw = 'MerchantWithdrawal',
}

export interface BusinessAccountAdjustment {
  accountId: string;
  amount: number;
  type: AdjustmentType;
}

export const getBusinessAccounts = createAsyncThunk(
  'businessAccounts.getBusinessAccounts',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<{ data: BusinessAccounts[] }>('/payments/merchant-accounts');
      return data.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const adjustBusinessAccount = createAsyncThunk(
  'businessAccounts.adjustBusinessAccount',
  async ({ accountId, amount, type }: BusinessAccountAdjustment, { rejectWithValue }) => {
    try {
      const { status } = await authAxios.post<{ data: BusinessAccounts[] }>('/payments/merchant-transactions', {
        accountId,
        amount,
        type,
      });
      successToast(
        type === AdjustmentType.Topup
          ? 'toast-messages.business-account-topup-success'
          : 'toast-messages.business-account-withdraw-success',
      );
      return status === 201;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
