import * as Flags from 'country-flag-icons/react/1x1';
import countries from 'i18n-iso-countries';
import type { LocaleData } from 'i18n-iso-countries'; // Importing types only
import styles from './FlagDisplay.module.scss';

import en from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(en as LocaleData);

interface FlagDisplayProps {
  countryCode: string;
}

const FlagDisplay = ({ countryCode }: FlagDisplayProps): JSX.Element => {
  const alpha2Code = countries.alpha3ToAlpha2(countryCode.toUpperCase());

  const FlagComponent = Flags[alpha2Code as keyof typeof Flags];

  if (!FlagComponent) {
    return <img src={'/flags/Default.svg'} />;
  }

  return (
    <FlagComponent
      title={alpha2Code}
      className={styles.flag}
    />
  );
};

export default FlagDisplay;
