import { ClickAwayListener } from '@mui/base';
import { useState } from 'react';
import styles from './NavDropdownWidget.module.scss';
import DropdownArrow from '@/components/common/dropdown-arrow/DropdownArrow';
import classNames from 'classnames';

interface Props {
  mainWidget: React.ReactNode;
  menuRows: React.JSX.Element[];
  showArrow?: boolean;
  rightAligned?: boolean;
}

export default function NavDropdownWidget({
  mainWidget,
  menuRows,
  showArrow,
  rightAligned,
}: Readonly<Props>): React.JSX.Element {
  const [showMenu, setShowMenu] = useState(false);
  const mainClassNames = classNames(styles.main, {
    [styles.rp]: showArrow,
  });
  const menuClassNames = classNames(styles.menu, {
    [styles.alignRight]: rightAligned,
  });
  return (
    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
      <div
        className={mainClassNames}
        onClick={() => setShowMenu(prev => !prev)}
        onKeyDown={() => setShowMenu(prev => !prev)}
      >
        {mainWidget}
        {showArrow && <DropdownArrow opened={showMenu} />}
        {showMenu && (
          <div className={menuClassNames}>
            {menuRows.map(row => (
              <div
                key={`${row.key}`}
                className={styles.menuRow}
              >
                {row}
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
