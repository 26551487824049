import React from 'react';
import styles from './WidgetBox.module.scss';
import LinkButton from '@/components/common/button/LinkButton';
import Typography from '@/components/common/typography/Typography';
import { purple } from '@/utils/colors';
import { type Permissions } from '@/types';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';

interface WidgetBoxMainProps {
  children: React.ReactNode;
  title?: string;
  label?: string;
}

interface WidgetBoxLinkProps {
  linkPath: string;
  linkLabel: string;
  linkTarget: React.HTMLAttributeAnchorTarget;
  linkPermission?: Permissions;
}

interface WidgetBoxNoLinkProps {
  linkPath?: never;
  linkLabel?: never;
  linkTarget?: never;
  linkPermission?: never;
}

export type WidgetBoxProps = WidgetBoxMainProps & (WidgetBoxLinkProps | WidgetBoxNoLinkProps);

const WidgetBox: React.FC<WidgetBoxProps> = ({
  children,
  title,
  label,
  linkPath,
  linkLabel,
  linkTarget,
  linkPermission,
}) => {
  return (
    <div className={styles.widget}>
      <div className={styles['widget__title-bar']}>
        {title && (
          <Typography
            color={purple.cssColor}
            variant='p1SemiBold'
          >
            {title}
          </Typography>
        )}
        {linkPath !== undefined &&
          (linkPermission ? (
            <UserPermissionsGuard permission={linkPermission}>
              <LinkButton
                href={linkPath}
                target={linkTarget}
              >
                {linkLabel}
              </LinkButton>
            </UserPermissionsGuard>
          ) : (
            <LinkButton
              href={linkPath}
              target={linkTarget}
            >
              {linkLabel}
            </LinkButton>
          ))}
        {label !== undefined && (
          <Typography
            color={purple.cssColor}
            variant='p4'
          >
            {label}
          </Typography>
        )}
      </div>
      {children}
    </div>
  );
};

export default WidgetBox;
