import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

import './i18n/config';

import 'react-toastify/dist/ReactToastify.min.css';
import './styles/application-styles.scss';

import MsalWrapper from './app/MsalWrapper';

const container = document.getElementById('root');

if (container != null) {
  const root = createRoot(container);
  root.render(<MsalWrapper />);
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
} else {
  document.body.innerText = 'Cannot load application';
}
