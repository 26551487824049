import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TransactionStatus, TransactionType } from '@/types';
import {
  TransactionActionsContext,
  TransactionActionType,
  type ActionProps,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { chargebackReversals } from '@/lib/redux/slices/transaction-chargeback-reversals/actions';
import { resetChargebackReversals } from '@/lib/redux/slices/transaction-chargeback-reversals/slice';
import ActionInput from '@/components/customer/transaction-history/transaction-actions/ActionInput';
import ActionButton from '@/components/customer/transaction-history/transaction-actions/ActionButton';
import { fetchChargebackReversalActionEmptyResult } from '@/lib/redux/slices/transaction-actions/actions';
import { isAmountAllowed } from '@/utils/helperFunctions';

const ChargebackReversalAction: React.FC<ActionProps> = ({ onClose, transaction, setAccessibility }) => {
  const { selectedTransactionAction } = useContext(TransactionActionsContext);
  const { isLoading, success } = useAppSelector((state: RootState) => state.chargebackReversals);
  const { isChargebackReversalEmpty } = useAppSelector((state: RootState) => state.transactionActionsSlice);

  const dispatch = useAppDispatch();
  const { t } = useTranslation(['customerOverview', 'global']);
  const { customerId } = useParams<{ customerId: string }>();

  const status = transaction?.status ?? '';
  const transactionType = transaction?.transactionType ?? '';

  const chargebackReversalAllowed =
    transactionType === TransactionType.Chargeback && status === TransactionStatus.Approved;

  useEffect(() => {
    if (success) {
      void dispatch(resetChargebackReversals());
      onClose({ success: true });
    }
  }, [success, dispatch, onClose]);

  useEffect(() => {
    if (
      transaction &&
      !selectedTransactionAction &&
      isChargebackReversalEmpty === undefined &&
      customerId &&
      chargebackReversalAllowed
    ) {
      void dispatch(
        fetchChargebackReversalActionEmptyResult({
          customerId,
          slipId: transaction.slipId ?? '',
        }),
      );
    } else if (!isChargebackReversalEmpty) {
      setAccessibility(false);
    }
  }, [
    selectedTransactionAction,
    customerId,
    dispatch,
    transaction,
    transactionType,
    chargebackReversalAllowed,
    isChargebackReversalEmpty,
    setAccessibility,
  ]);

  const handleConfirm = (amount: number, reason: string): void => {
    if (transaction?.slipId) {
      void dispatch(
        chargebackReversals({
          slipId: transaction.slipId,
          amount,
          reason,
        }),
      );
    }
  };

  const handleClose = (): void => onClose();

  const requestedAmount = transaction?.requestedAmount ?? 0;

  let amount = `${requestedAmount}`;

  useEffect(() => {
    if (isChargebackReversalEmpty !== undefined) {
      setAccessibility(isChargebackReversalEmpty);
    }
    return () => {
      setAccessibility(undefined);
    };
  }, [isChargebackReversalEmpty, setAccessibility]);

  if (transaction?.requestedAmount && transaction?.requestedAmountCurrency) {
    amount = `${transaction.requestedAmount} ${transaction.requestedAmountCurrency}`;
  }

  if (selectedTransactionAction === undefined) {
    return (
      <ActionButton
        type={TransactionActionType.ChargebackReversal}
        hide={!(isChargebackReversalEmpty && chargebackReversalAllowed)}
        label='Chargeback Reversal'
      />
    );
  }
  if (selectedTransactionAction === TransactionActionType.ChargebackReversal) {
    return (
      <ActionInput
        disabledButton={isLoading}
        inputLabel={t('widgets.transaction-history.modal-input-amount')}
        onConfirm={handleConfirm}
        onClose={handleClose}
        isAllowed={values => isAmountAllowed(values, requestedAmount)}
        hint={t('widgets.transaction-history.information-text-amount', {
          amount,
        })}
      ></ActionInput>
    );
  }

  return null;
};

export default ChargebackReversalAction;
