import { purple } from '@/utils/colors';

function UserIcon({
  width = `${20 / 16}rem`,
  height = `${20 / 16}rem`,
  color = purple.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='10.0001'
        cy='5.4'
        r='4.53261'
        stroke={color}
        strokeWidth='1.73478'
      />
      <path
        d='M2 19.0961C2 17.4119 2.95135 14.0435 6.75676 14.0435C10.5622 14.0435 10.6486 14.0435 13.6757 14.0435C15.8378 14.0435 18 16.1487 18 19.0961'
        stroke={color}
        strokeWidth='1.73478'
        strokeLinecap='round'
      />
    </svg>
  );
}

export default UserIcon;
