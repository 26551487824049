import { useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ClickAwayListener } from '@mui/base';

import DropdownOption from './DropdownOption';
import ArrowIcon from '@/components/icons/ArrowIcon';
import Typography from '@/components/common/typography/Typography';

import { extraLightGrey } from '@/utils/colors';
import type { Option } from '@/utils/interfaces';

import styles from './Dropdown.module.scss';
import { calculateHeightAndApplyStyles } from './DropdownUtils';

export interface DropdownProps {
  label?: string;
  options: Option[];
  onSelect: (option: Option) => void;
  selectedOption?: Option;
  setSelectedOption: (selectedOption: Option) => void;
}

function Dropdown({
  label,
  options,
  onSelect,
  selectedOption,
  setSelectedOption,
}: Readonly<DropdownProps>): React.JSX.Element {
  const [opened, setOpened] = useState(false);
  const dropdownListRef = useRef<HTMLUListElement>(null);

  const dropdownClasses = classNames(styles.dropdown, {
    [styles['dropdown--active']]: opened,
  });
  const dropdownListClasses = classNames(styles.dropdown__list, {
    [styles['dropdown__list--active']]: opened,
  });
  const selectedValueClasses = classNames(styles['dropdown__selected-value']);
  const arrowClasses = classNames(styles.dropdown__arrow, {
    [styles['dropdown__arrow--opened']]: opened,
  });

  const onOptionClick = (option: Option): void => {
    setSelectedOption(option);
    setOpened(!opened);
    onSelect(option);
  };

  useLayoutEffect(() => {
    if (opened && dropdownListRef.current) {
      calculateHeightAndApplyStyles(dropdownListRef.current);
    }
  }, [opened]);

  return (
    <div className={styles['dropdown-wrapper']}>
      {label && <Typography variant='p3'>{label}</Typography>}
      <ClickAwayListener onClickAway={() => setOpened(false)}>
        <div className={dropdownClasses}>
          <button
            className={styles.dropdown__button}
            onClick={() => setOpened(!opened)}
          >
            <span className={selectedValueClasses}>{selectedOption?.text ?? ' '}</span>
            <span className={arrowClasses}>
              {!opened ? <ArrowIcon color={extraLightGrey.cssColor} /> : <ArrowIcon />}
            </span>
          </button>
          <ul
            className={dropdownListClasses}
            ref={dropdownListRef}
          >
            {options
              .filter(
                option =>
                  (!selectedOption?.value || selectedOption.value !== option.value) &&
                  selectedOption?.text !== option.text,
              )
              .map(option => (
                <DropdownOption
                  key={option.text}
                  text={option.text}
                  active={false}
                  onOptionClick={() => onOptionClick(option)}
                  hidden={option.hidden}
                  displayType='inline'
                />
              ))}
          </ul>
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default Dropdown;
