import type { Currency } from '@/types';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import Dropdown from '@/components/common/filters/Dropdown';
import type { Option } from '@/utils/interfaces';
import { GridItem } from '@/components/configurations/country-parameters/CountryParameters';
import { useTranslation } from 'react-i18next';
import { parseNumberOrDefault } from '@/utils/helperFunctions';

interface Props {
  currency?: Currency;
  setCurrency: (currency: Currency) => void;
}
const positionOptions = [
  {
    text: 'Left',
    value: 'left',
  },
  {
    text: 'Right',
    value: 'right',
  },
];
export default function CountryParametersCurrency({ currency, setCurrency }: Props): JSX.Element {
  const { t } = useTranslation('configurations');

  function getDefaultCurrency(): Currency {
    return {
      currencyName: '',
      currencyCode: '',
      position: '',
      precision: 0,
      symbol: '',
    };
  }

  const currencyEdit = currency ?? getDefaultCurrency();

  return (
    <>
      <GridItem $cell={[1, 2]}>
        <InputLabel label={t('new-country.input-label.currencyName', { defaultValue: 'New type' })}>
          <Input
            onChange={(value: string) => setCurrency({ ...currencyEdit, currencyName: value })}
            value={currencyEdit.currencyName}
          />
        </InputLabel>
      </GridItem>

      <GridItem $cell={[3]}>
        <InputLabel label={t('new-country.input-label.currencyCode', { defaultValue: 'New type' })}>
          <Input
            onChange={(value: string) => setCurrency({ ...currencyEdit, currencyCode: value.toUpperCase() })}
            value={currencyEdit.currencyCode}
          />
        </InputLabel>
      </GridItem>

      <GridItem $cell={[4]}>
        <InputLabel label={t('new-country.input-label.symbol', { defaultValue: 'New type' })}>
          <Input
            onChange={(value: string) => setCurrency({ ...currencyEdit, symbol: value })}
            value={currencyEdit.symbol}
          />
        </InputLabel>
      </GridItem>

      <GridItem $cell={[5]}>
        <InputLabel label={t('new-country.input-label.position', { defaultValue: 'New type' })}>
          <Dropdown
            options={positionOptions}
            onSelect={(option: Option) => setCurrency({ ...currencyEdit, position: option.value ?? '' })}
            selectedOption={{
              value: currencyEdit.position,
              text:
                currencyEdit.position &&
                t(`new-country.position-options.${currencyEdit.position}`, { defaultValue: 'New type' }),
            }}
            setSelectedOption={() => {}}
          />
        </InputLabel>
      </GridItem>

      <GridItem $cell={[6]}>
        <InputLabel label={t('new-country.input-label.precision', { defaultValue: 'New type' })}>
          <Input
            onChange={(value: string) =>
              setCurrency({
                ...currencyEdit,
                precision: parseNumberOrDefault(value, currencyEdit.precision),
              })
            }
            value={String(currencyEdit.precision)}
          />
        </InputLabel>
      </GridItem>
    </>
  );
}
