import Spinner from '@/components/common/spinners/Spinner';
import { type AppDispatch, useAppDispatch, type RootState, useAppSelector } from '@/lib/redux';
import { fetchIdentityDocuments } from '@/lib/redux/slices/identity-docs/actions';
import { AppUrls } from '@/utils/constants';
import { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

export default function IdentityDocumentsLayout({ review }: Readonly<{ review?: boolean }>): React.JSX.Element | null {
  const { documentsId } = useParams();
  const { customer } = useAppSelector((state: RootState) => state.customer);
  const { identityDocs, loading } = useAppSelector((state: RootState) => state.identityDocs);
  const dispatch: AppDispatch = useAppDispatch();

  useEffect(() => {
    if (customer?.customerId && documentsId) {
      void dispatch(fetchIdentityDocuments({ docsId: documentsId, customerId: customer.customerId, review }));
    }
  }, [customer?.customerId, documentsId, dispatch, review]);

  if (customer?.customerId && identityDocs && identityDocs?.identityDocId === documentsId && !loading) {
    return <Outlet />;
  } else if (identityDocs === null) {
    return <Navigate to={AppUrls.NotFoundUrl} />;
  } else {
    return <Spinner />;
  }
}
