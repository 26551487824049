import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import NavigationTabs from '@/components/common/navigation-tabs/NavigationTabs';
import Layout from '@/components/layout/Layout';
import ContentBox from '@/components/common/content-box/ContentBox';

import { type TabInfo } from '@/utils/interfaces';
import { useAppDispatch } from '@/lib/redux';
import { getBusinessAccounts } from '@/lib/redux/slices/business-accounts/actions';
import { reset } from '@/lib/redux/slices/business-accounts/slice';

import styles from './BusinessAccountsPage.module.scss';

export default function BusinessAccountsPage(): React.JSX.Element {
  const { t } = useTranslation('businessAccounts');

  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(getBusinessAccounts());
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const tabs: TabInfo[] = useMemo(() => {
    return [
      {
        linkText: t('tab-overview'),
        linkPath: 'overview',
      },
      { linkText: t('tab-transaction-history'), linkPath: 'transaction-history' },
    ];
  }, [t]);

  return (
    <Layout>
      <div className={styles['business-accounts-page']}>
        <div className={styles['business-accounts-page__header']}>
          <h1>{t('header-title')}</h1>
          <NavigationTabs tabs={tabs} />
        </div>
        <ContentBox>
          <Outlet />
        </ContentBox>
      </div>
    </Layout>
  );
}
