import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function ItemsPerPageLabel({
  page,
  itemsPerPage,
  count,
}: Readonly<{
  itemsPerPage: number;
  page: number;
  count: number;
}>): React.JSX.Element {
  const { t } = useTranslation('global');

  const itemsRange = useMemo(() => {
    const startIndex = 1 + (page - 1) * itemsPerPage;
    const endIndex = page * itemsPerPage > count ? count : page * itemsPerPage;
    return `${startIndex}-${endIndex}`;
  }, [page, itemsPerPage, count]);

  if (count < 2) {
    return <div>{t('pagination.items-per-page', { itemsRange: count, count })}</div>;
  }

  return <div>{t('pagination.items-per-page', { itemsRange, count })}</div>;
}
