import React from 'react';

export function useDocumentTitle(title?: string): void {
  const documentDefined = typeof document !== 'undefined';

  React.useEffect(() => {
    if (!documentDefined || !title || document.title === title) {
      return;
    }

    const originalTitle = document.title;
    document.title = title;

    return () => {
      document.title = originalTitle;
    };
  }, [title, documentDefined]);
}
