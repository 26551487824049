import StatusStamp from '@/components/common/status-stamp/StatusStamp';
import { type PaymentCardStatus } from '@/types';
import { type StatusVariants } from '@/utils/statusVariants';
import { useMemo } from 'react';

const cardStatusMap = {
  active: {
    variant: 'successMedium',
    text: 'ACTIVE',
  },
  blocked: {
    variant: 'warningMedium',
    text: 'BLOCKED',
  },
  cancelled: {
    variant: 'errorMedium',
    text: 'CANCELLED',
  },
  created: {
    variant: 'warningLight',
    text: 'CREATED',
  },
  closed: {
    variant: 'errorDark',
    text: 'CLOSED',
  },
  default: {
    variant: 'successMedium',
    text: '',
  },
};

interface Props {
  status?: PaymentCardStatus;
  noBorder?: boolean;
}

export default function CardStatusStamp({ status, noBorder }: Readonly<Props>): React.JSX.Element | null {
  const { text: statusText, variant: statusVariant } = useMemo(() => {
    if (!status) {
      return cardStatusMap.default;
    }
    const key = (status as string).toLowerCase();
    return cardStatusMap[key as keyof typeof cardStatusMap];
  }, [status]);

  if (!status) {
    return null;
  }

  return (
    <StatusStamp
      status={statusText}
      variant={statusVariant as StatusVariants}
      noBorder={noBorder}
    />
  );
}
