import { useTranslation } from 'react-i18next';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import CustomerPiiField from './CustomerPiiField';
import CustomerStatusStamp from '../../overview/account-management/customer-status-badge/CustomerStatusStamp';
import { type CustomerStatusEnum } from '@/types';
import CardStatusStamp from '../../overview/payment-cards/card/CardStatusStamp';
import { usePaymentCards } from '@/utils/hooks/usePaymentCards';
import { useKycApplications } from '@/utils/hooks/useKycApplications';

interface AccountDataProps {
  customerId?: string;
  customerStatus?: CustomerStatusEnum;
  signUpDateOtp?: Date | string;
  signUpDateAccount?: Date | string;
}

export function AccountData({
  customerId,
  customerStatus,
  signUpDateOtp,
  signUpDateAccount,
}: Readonly<AccountDataProps>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');
  const { virtualCard } = usePaymentCards(customerId ?? '');
  const kycInfo = useKycApplications(customerId ?? '');

  let signUpDateOtpUtc: string | undefined;
  if (signUpDateOtp !== undefined) {
    signUpDateOtpUtc = toUtcDateString(signUpDateOtp);
  }

  let signUpDateAccountUtc: string | undefined;
  if (signUpDateAccount !== undefined) {
    signUpDateAccountUtc = toUtcDateString(signUpDateAccount);
  }

  return (
    <div>
      <CustomerPiiField fieldName={t('widgets.customer-pii.account-data.customer-id')}>{customerId}</CustomerPiiField>
      <CustomerPiiField fieldName={t('widgets.customer-pii.account-data.customer-status')}>
        <CustomerStatusStamp
          status={customerStatus}
          noBorder
        />
      </CustomerPiiField>
      <CustomerPiiField fieldName={t('widgets.customer-pii.account-data.kyc-level')}>{kycInfo?.isApplicationApproved ? t('widgets.customer-pii.account-data.kyc-level-approved') : t('widgets.customer-pii.account-data.kyc-level-not-approved')}</CustomerPiiField>
      <CustomerPiiField fieldName={t('widgets.customer-pii.account-data.virtual-card-status')}>
        {!!virtualCard?.status && (
          <CardStatusStamp
            status={virtualCard.status}
            noBorder
          />
        )}
      </CustomerPiiField>
      <CustomerPiiField fieldName={t('widgets.customer-pii.account-data.sign-up-date')}>
        {signUpDateOtpUtc}
      </CustomerPiiField>
      <CustomerPiiField fieldName={t('widgets.customer-pii.account-data.mambu-accounts-creations')}>
        {signUpDateAccountUtc}
      </CustomerPiiField>
    </div>
  );
}
