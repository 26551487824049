import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getUserPermissions, type UserPermissionsResponse } from './actions';
import { type Permissions } from '@/types';

export interface UserPermission {
  value?: boolean;
}

export type UserPermissions = {
  [K in Permissions]?: UserPermission;
};

export interface UserPermissionsState {
  permissions: UserPermissions;
};

const initialState: UserPermissionsState = {
  permissions: {},
};

export const userPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUserPermissions.pending, (state, action) => {
      const { permission } = action.meta.arg;
      state.permissions[permission] = {};
    });
    builder.addCase(getUserPermissions.fulfilled, (state, action: PayloadAction<UserPermissionsResponse>) => {
      state.permissions[action.payload.permission] = { value: action.payload.success };
    });
    builder.addCase(getUserPermissions.rejected, (state, action: PayloadAction<UserPermissionsResponse>) => {
      state.permissions[action.payload.permission] = { value: false };
    });
  },
});
