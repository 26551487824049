import React from 'react';
import { useTranslation } from 'react-i18next';

import FirstOrnament from './first-ornament/FirstOrnament';
import SecondOrnament from './second-ornament/SecondOrnament';
import SearchComponent from '@/components/search-component/SearchComponent';
import Layout from '@/components/layout/Layout';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';

import styles from './Home.module.scss';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import HomeNavigation from '@/components/home/HomeNavigation';
import { Permissions } from '@/types';

function Home(): React.JSX.Element {
  const { t } = useTranslation('global');

  useDocumentTitle(t('search-component.page-title'));

  return (
    <Layout hideHamburger>
      <div className={styles['home-page']}>
        <div className={styles['home-page__search']}>
          <UserPermissionsGuard permission={Permissions.customerOverview}>
            <SearchComponent
              type='block'
              autoFocus={true}
            />
          </UserPermissionsGuard>
          <HomeNavigation />
        </div>
        <FirstOrnament />
        <SecondOrnament />
      </div>
    </Layout>
  );
}

export default Home;
