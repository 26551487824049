import { useCallback, useEffect, useMemo } from 'react';
import { useUrlState } from './useUrlState';

export default function usePagination(dependentPageProp?: number): [number, (p: number) => void] {
  const dependentPage = dependentPageProp ?? -1;
  const [urlPageStr, internalSetUrlPage] = useUrlState('page');

  const urlPage = useMemo(() => {
    const result = parseInt(urlPageStr);
    if (isNaN(result) || result < 1) {
      return 1;
    }
    return result;
  }, [urlPageStr]);

  const setUrlPage = useCallback(
    (value: number) => {
      internalSetUrlPage(`${value}`);
    },
    [internalSetUrlPage],
  );

  useEffect(() => {
    if (dependentPage > 0) setUrlPage(dependentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependentPage]);

  const result = useMemo(() => [urlPage, setUrlPage] as [number, (p: number) => void], [urlPage, setUrlPage]);
  return result;
}
