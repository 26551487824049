import { marine } from '@/utils/colors';

function StoreFrontIcon({
  width = `${35 / 16}rem`,
  height = `${35 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 35 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.125 29.25V22.75C20.125 22.1977 19.6773 21.75 19.125 21.75H14.875C14.3227 21.75 13.875 22.1977 13.875 22.75V29.25'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 16.75V25.25C8.25 27.1356 8.25 28.0784 8.83579 28.6642C9.42157 29.25 10.3644 29.25 12.25 29.25H21.75C23.6356 29.25 24.5784 29.25 25.1642 28.6642C25.75 28.0784 25.75 27.1356 25.75 25.25V16.75'
        stroke={color}
        strokeWidth='2'
      />
      <path
        d='M7.87127 8.26493C8.05316 7.53737 8.1441 7.17359 8.41536 6.96179C8.68663 6.75 9.0616 6.75 9.81155 6.75H24.1884C24.9384 6.75 25.3134 6.75 25.5846 6.96179C25.8559 7.17359 25.9468 7.53737 26.1287 8.26493L27.7279 14.6617C27.9205 15.432 28.0168 15.8171 27.9005 16.1144C27.8295 16.2957 27.7072 16.4523 27.5486 16.5651C27.2884 16.75 26.8857 16.75 26.0801 16.75V16.75C25.0094 16.75 24.4741 16.75 24.0531 16.5405C23.764 16.3966 23.5141 16.1849 23.3246 15.9234C23.0488 15.5426 22.962 15.022 22.7885 13.9807V13.9807C22.7398 13.6887 22.7155 13.5427 22.6714 13.5197C22.6423 13.5045 22.6077 13.5045 22.5786 13.5197C22.5345 13.5427 22.5102 13.6887 22.4615 13.9807L22.3596 14.5923C22.2831 15.0516 22.2448 15.2813 22.1706 15.4728C21.9254 16.1053 21.3763 16.5704 20.7121 16.7083C20.5111 16.75 20.2782 16.75 19.8125 16.75V16.75C19.3468 16.75 19.1139 16.75 18.9129 16.7083C18.2487 16.5704 17.6996 16.1053 17.4544 15.4728C17.3802 15.2813 17.3419 15.0516 17.2654 14.5923L17.1635 13.9807C17.1148 13.6887 17.0905 13.5427 17.0464 13.5197C17.0173 13.5045 16.9827 13.5045 16.9536 13.5197C16.9095 13.5427 16.8852 13.6887 16.8365 13.9807L16.7346 14.5923C16.6581 15.0516 16.6198 15.2813 16.5456 15.4728C16.3004 16.1053 15.7513 16.5704 15.0871 16.7083C14.8861 16.75 14.6532 16.75 14.1875 16.75V16.75C13.7218 16.75 13.4889 16.75 13.2879 16.7083C12.6237 16.5704 12.0746 16.1053 11.8294 15.4728C11.7552 15.2813 11.7169 15.0516 11.6404 14.5923L11.5385 13.9807C11.4898 13.6887 11.4655 13.5427 11.4214 13.5197C11.3923 13.5045 11.3577 13.5045 11.3286 13.5197C11.2845 13.5427 11.2602 13.6887 11.2115 13.9807V13.9807C11.038 15.022 10.9512 15.5426 10.6754 15.9234C10.4859 16.1849 10.236 16.3966 9.94687 16.5405C9.52593 16.75 8.99058 16.75 7.91989 16.75V16.75C7.11435 16.75 6.71157 16.75 6.4514 16.5651C6.29275 16.4523 6.17046 16.2957 6.09953 16.1144C5.98322 15.8171 6.0795 15.432 6.27207 14.6617L7.87127 8.26493Z'
        stroke={color}
        strokeWidth='2'
      />
    </svg>
  );
}

export default StoreFrontIcon;
