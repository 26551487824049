import Typography from '@/components/common/typography/Typography';
import { mediumPurple } from '@/utils/colors';
import LinkButton from '@/components/common/button/LinkButton';
import styles from './ColumnCard.module.scss';

export interface Record {
  label: string;
  value?: string | number | JSX.Element;
  highlight?: boolean;
  link?: string;
}

const ColumnCard = ({ records }: Readonly<{ records: Record[] }>): JSX.Element => {
  function renderValue(record: Record): JSX.Element {
    if (record.link) {
      return (
        <LinkButton
          href={record?.link}
          target='_self'
        >
          {record.value}
        </LinkButton>
      );
    } else if (record.value) {
      return <Typography variant='p2Medium'>{record.value}</Typography>;
    } else {
      return <Typography variant='p2Medium'>{'-'}</Typography>;
    }
  }

  return (
    <div className={styles.content}>
      {records.map(record => (
        <div
          className={record.highlight ? styles.highlight : styles.box}
          key={record.label}
        >
          <Typography
            color={mediumPurple.cssColor}
            variant='p3'
          >
            {record.label}
          </Typography>
          {renderValue(record)}
        </div>
      ))}
    </div>
  );
};

export default ColumnCard;
