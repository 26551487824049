import { purple } from '@/utils/colors';

export default function CloseAccountIcon({
  width = `${18 / 16}rem`,
  height = `${18 / 16}rem`,
  color = purple.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M6.08724 8.59773C6.84598 8.59773 7.58769 8.36653 8.21856 7.93335C8.84943 7.50018 9.34113 6.8845 9.63149 6.16416C9.92185 5.44382 9.99782 4.65118 9.84979 3.88647C9.70177 3.12177 9.3364 2.41934 8.79989 1.86802C8.26338 1.31669 7.57982 0.941238 6.83566 0.789128C6.0915 0.637019 5.32015 0.715087 4.61917 1.01346C3.91818 1.31183 3.31904 1.81711 2.8975 2.4654C2.47597 3.11369 2.25098 3.87587 2.25098 4.65556C2.25098 5.70109 2.65515 6.70379 3.37459 7.44309C4.09403 8.18239 5.0698 8.59773 6.08724 8.59773ZM6.08724 2.2947C6.54212 2.2947 6.98678 2.43331 7.365 2.69301C7.74322 2.9527 8.038 3.32181 8.21208 3.75367C8.38615 4.18552 8.4317 4.66072 8.34295 5.11917C8.25421 5.57763 8.03517 5.99875 7.71352 6.32927C7.39187 6.6598 6.98207 6.88489 6.53593 6.97608C6.08979 7.06728 5.62736 7.02047 5.20711 6.84159C4.78685 6.66271 4.42766 6.35979 4.17494 5.97113C3.92223 5.58247 3.78734 5.12553 3.78734 4.6581C3.78832 4.0316 4.03095 3.43104 4.46205 2.98804C4.89315 2.54503 5.47757 2.29571 6.08724 2.2947Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.4749 10.2676C10.7769 9.9572 11.2666 9.9572 11.5687 10.2676L17.453 16.3143C17.755 16.6247 17.755 17.1279 17.453 17.4383C17.1509 17.7487 16.6612 17.7487 16.3591 17.4383L10.4749 11.3916C10.1728 11.0812 10.1728 10.578 10.4749 10.2676Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.4532 10.2678C17.7552 10.5782 17.7552 11.0814 17.4532 11.3918L11.5689 17.4385C11.2668 17.7489 10.7771 17.7489 10.4751 17.4385C10.173 17.1281 10.173 16.6249 10.4751 16.3145L16.3593 10.2678C16.6614 9.95743 17.1511 9.95743 17.4532 10.2678Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.61756 14.1334C1.57374 14.5697 1.19416 14.8871 0.769429 14.8424C0.344491 14.7977 0.0352814 14.4075 0.0787895 13.9708L0.848208 14.0518C0.0787895 13.9708 0.0788617 13.9701 0.0788617 13.9701L0.0789418 13.9693L0.079126 13.9675L0.079593 13.963L0.0809441 13.9508C0.0820385 13.9412 0.0835329 13.9286 0.0854948 13.9134C0.0894159 13.8829 0.0952196 13.8415 0.103452 13.7907C0.119882 13.6893 0.146189 13.5492 0.186936 13.3831C0.26764 13.0539 0.409053 12.6058 0.653707 12.1497C1.15373 11.2175 2.11581 10.2202 3.80337 10.2202H8.03339C8.46055 10.2202 8.80683 10.5761 8.80683 11.015C8.80683 11.454 8.46055 11.8098 8.03339 11.8098H3.80337C2.83834 11.8098 2.32284 12.3309 2.00843 12.917C1.84586 13.2201 1.74585 13.5312 1.68693 13.7715C1.65786 13.89 1.63981 13.9871 1.62933 14.0518C1.62411 14.084 1.62082 14.1079 1.61901 14.1219L1.61737 14.1353L1.61756 14.1334Z'
          fill={color}
        />
      </g>
    </svg>
  );
}
