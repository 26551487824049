import { useTranslation } from 'react-i18next';
import CustomerPiiField from './CustomerPiiField';
import styles from './ContactInfoData.module.scss';

interface ContactInfoDataProps {
  phoneNumber?: string;
  email?: string;
}

export function ContactInfoData(props: Readonly<ContactInfoDataProps>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');

  const { phoneNumber, email } = props;

  const firstTwoLetters = phoneNumber?.substring(0, 2);
  const restOfText = phoneNumber?.substring(2);

  return (
    <div>
      <CustomerPiiField fieldName={t('widgets.customer-pii.contact-info-data.phone-number')}>
        <span className={styles.prefix}>+{firstTwoLetters}</span>
        <span>{restOfText}</span>
      </CustomerPiiField>
      <CustomerPiiField fieldName={t('widgets.customer-pii.contact-info-data.email')}>{email}</CustomerPiiField>
    </div>
  );
}
