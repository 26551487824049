import UserIcon from '@/components/icons/UserIcon';
import { marine } from '@/utils/colors';
import styles from './UserProfile.module.scss';
import { useAppSelector } from '@/lib/redux';
import ExitIcon from '@/components/icons/ExitIcon';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import Typography from '@/components/common/typography/Typography';
import NavDropdownWidget from './NavDropdownWidget';

export default function UserProfile(): React.JSX.Element {
  const { name } = useAppSelector(state => state.auth);
  const { instance } = useMsal();
  const { t } = useTranslation('global');

  const logOut = (): void => {
    void instance.logoutPopup();
  };

  return (
    <NavDropdownWidget
      mainWidget={
        <>
          <UserIcon color={marine.cssColor} />
          <span>{name}</span>
        </>
      }
      menuRows={[
        <div
          className={styles.menuRow}
          key='logout'
          onClick={logOut}
          onKeyDown={logOut}
        >
          <ExitIcon />
          <Typography variant='p2'>{t('header.profile.menu.log-out')}</Typography>
        </div>,
      ]}
    />
  );
}
