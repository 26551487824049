import styles from './SecondOrnament.module.scss';

function SecondOrnament(): React.JSX.Element {
  return (
    <svg
      className={styles['search-page__second-ornament']}
      width='374'
      height='196'
      viewBox='0 0 374 196'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.8125 -57.812C29.2821 193.715 439.889 289.627 481.565 39.6549'
        stroke='white'
        strokeWidth='20'
      />
    </svg>
  );
}

export default SecondOrnament;
