import authAxios from '@/auth/axios';
import { type PaymentCardInfo } from '@/types';
import { errorToast } from '@/utils/toastMessage';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchCardById = createAsyncThunk(
  'customer-cards.fetchCardById',
  async ({ customerId, cardId }: { customerId: string; cardId: string }, { rejectWithValue }) => {
    try {
      const response = await authAxios.get<{ data: PaymentCardInfo }>(`/cards/cards/${cardId}`, {
        headers: { 'x-customer-id': customerId },
      });
      return response.data.data;
    } catch (_: any) {
      errorToast();
      return rejectWithValue(cardId);
    }
  },
);
