import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { type Country, type Currency } from '@/types';
import Typography from '@/components/common/typography/Typography';
import Input from '@/components/common/inputs/Input';
import InputLabel from '@/components/common/labels/InputLabel';
import { mediumPurple } from '@/utils/colors';
import styles from './CountryParameters.module.scss';
import CountryParametersCurrency from '@/components/configurations/country-parameters/CountryParametersCurrency';
import Button from '@/components/common/button/Button';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { objectDeepEquality, parseNumberOrDefault } from '@/utils/helperFunctions';
import { addCountry, countryEdit } from '@/lib/redux/slices/country/actions';
import { AccordionTitle } from '@/components/configurations';
import Accordion from '@/components/common/accordion/Accordion';

export const GridItem = styled.div<{ $cell?: [number?, number?] }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  @media (max-width: 1090px) {
    grid-column: 1 / span 5;
  }
`;

interface Props {
  setCountryCode: (countryCode: string) => void;
}

const CountryParameters = ({ setCountryCode }: Props): JSX.Element => {
  const { t } = useTranslation('configurations');

  const { country, isLoading } = useAppSelector((state: RootState) => state.country);
  const [active, setActive] = useState<boolean>(false);
  const [countryParameters, setCountryParameters] = useState<Country>({
    ...country,
    currencies:
      country.currencies.length > 0
        ? country.currencies
        : [{ currencyName: '', currencyCode: '', position: '', precision: 0, symbol: '' }],
  });

  const dispatch = useAppDispatch();

  const isNew = country.countryCode.length === 0;
  const isChanged = useMemo(() => !objectDeepEquality(countryParameters, country), [countryParameters, country]);

  const setCurrency = (newCurrency: Currency, index: number): void => {
    const previousCountry = countryParameters;
    const updatedCurrencies = [...previousCountry.currencies];
    updatedCurrencies[index] = newCurrency;
    setCountryParameters({ ...previousCountry, currencies: updatedCurrencies });
  };

  const areCurrenciesValid = (): boolean => {
    // Filter the currencies based on the presence of required fields
    const currenciesValid = countryParameters.currencies.filter(currency => {
      return (
        currency.currencyCode.length > 0 ||
        currency.currencyName.length > 0 ||
        currency.symbol.length > 0 ||
        currency.position.length > 0
      );
    });
    // If none of the currencies have valid fields, return false
    if (currenciesValid.length === 0) {
      return false;
    }
    // Ensure that every filtered currency has all required fields with valid values
    return currenciesValid.every(
      currency =>
        currency &&
        currency.currencyCode?.length > 0 &&
        currency.currencyName?.length > 0 &&
        currency.symbol?.length > 0 &&
        currency.position?.length > 0 &&
        typeof currency.precision === 'number',
    );
  };

  const hasValidCountryParametersInputs = (): boolean => {
    const countryCodeValid = countryParameters.countryCode.length > 0;
    const countryNameValid = countryParameters.countryName.length > 0;
    const internationalCallingCodeValid = countryParameters.internationalCallingCode > 0;
    const minPhoneLengthValid = countryParameters.minPhoneLength > 0;
    const maxPhoneLengthValid = countryParameters.maxPhoneLength > 0;
    const currenciesValid = areCurrenciesValid();

    return (
      countryCodeValid &&
      countryNameValid &&
      currenciesValid &&
      internationalCallingCodeValid &&
      minPhoneLengthValid &&
      maxPhoneLengthValid
    );
  };

  const handleSubmit = (): void => {
    if (isNew) {
      void dispatch(addCountry({ body: countryParameters }));
    } else if (isChanged) {
      const { countryCode, isEnabled, isTesting, ...filtered } = countryParameters;
      void dispatch(countryEdit({ body: filtered, countryCode: countryCode.toUpperCase() }));
    }
  };

  const renderContent = (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.label}>
          <Typography
            variant='p3'
            color={mediumPurple.cssColor}
          >
            {t('new-country.country-parameters-description')}
          </Typography>
        </div>
        <div className={styles.inputCard}>
          <div className={styles.gridCard}>
            <GridItem $cell={[1, 2]}>
              <InputLabel label={t('new-country.input-label.countryCode', { defaultValue: 'New type' })}>
                <Input
                  disabled={!isNew}
                  onChange={(value: string) => {
                    setCountryParameters({ ...countryParameters, countryCode: value.toUpperCase() });
                    setCountryCode(value.toUpperCase());
                  }}
                  value={countryParameters.countryCode}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[3, 2]}>
              <InputLabel label={t('new-country.input-label.countryName', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) => setCountryParameters({ ...countryParameters, countryName: value })}
                  value={countryParameters.countryName}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[5, 2]}>
              <InputLabel label={t('new-country.input-label.internationalCallingCode', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) =>
                    setCountryParameters({
                      ...countryParameters,
                      internationalCallingCode: parseNumberOrDefault(value, country.internationalCallingCode),
                    })
                  }
                  value={String(countryParameters.internationalCallingCode)}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[1, 2]}>
              <InputLabel label={t('new-country.input-label.minPhoneLength', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) =>
                    setCountryParameters({
                      ...countryParameters,
                      minPhoneLength: parseNumberOrDefault(value, countryParameters.minPhoneLength),
                    })
                  }
                  value={String(countryParameters.minPhoneLength)}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[3, 2]}>
              <InputLabel label={t('new-country.input-label.maxPhoneLength', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) =>
                    setCountryParameters({
                      ...countryParameters,
                      maxPhoneLength: parseNumberOrDefault(value, countryParameters.maxPhoneLength),
                    })
                  }
                  value={String(countryParameters.maxPhoneLength)}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[5, 2]} />
            <GridItem $cell={[1, 6]}>
              <Typography variant='p3Bold'>{t('new-country.local-currency')}</Typography>
            </GridItem>
            <CountryParametersCurrency
              currency={countryParameters.currencies.at(0)}
              setCurrency={(currency: Currency) => setCurrency(currency, 0)}
            />
            <GridItem $cell={[1, 6]}>
              <Typography variant='p3Bold'>{t('new-country.second-currency')}</Typography>
            </GridItem>

            <CountryParametersCurrency
              currency={countryParameters.currencies.at(1)}
              setCurrency={(currency: Currency) => setCurrency(currency, 1)}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          textTransform='uppercase'
          disabled={isLoading || !isChanged || !hasValidCountryParametersInputs()}
          onClick={handleSubmit}
        >
          {t('new-country.button-submit-configuration')}
        </Button>
      </div>
    </div>
  );

  return (
    <Accordion
      title={
        <AccordionTitle
          isValid={hasValidCountryParametersInputs() && !isChanged}
          title='Country parameters'
        />
      }
      content={renderContent}
      isActive={active}
      onClick={() => setActive(!active)}
    />
  );
};

export default CountryParameters;
