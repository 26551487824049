import { createSlice } from '@reduxjs/toolkit';
import { addAuditLogsComment } from './actions';

export interface AuditLogsCommentSliceState {
  loading: boolean;
  success: boolean;
}

const initialState: AuditLogsCommentSliceState = {
  loading: false,
  success: false,
};

export const auditLogsCommentSlice = createSlice({
  name: 'auditLogsComment',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(addAuditLogsComment.pending, state => {
      state.loading = true;
    });
    builder.addCase(addAuditLogsComment.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(addAuditLogsComment.rejected, state => {
      state.loading = false;
      state.success = false;
    });
  },
});

export const { reset } = auditLogsCommentSlice.actions;
