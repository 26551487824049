import { useTranslation } from 'react-i18next';
import Table from '@/components/common/tables/Table';
import TransactionHistoryRow from './TransactionHistoryRow';

import { type TransactionBA } from '@/types';

export default function TransactionHistoryTable({
  transactions,
}: Readonly<{ transactions: TransactionBA[] }>): React.JSX.Element {
  const { t } = useTranslation('businessAccounts');

  return (
    <Table colsTemplate='repeat(13, auto)'>
      <Table.Head>{t('tabs.transaction-history.transaction-id')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.psp-transaction-id')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.status')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.transaction-type')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.description')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.created-on')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.updated-on')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.country')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.amount')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.customer-id')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.entity-name')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.provider-name')}</Table.Head>
      <Table.Head>{t('tabs.transaction-history.reason')}</Table.Head>

      {transactions.map(transaction => (
        <TransactionHistoryRow
          key={transaction.id}
          transaction={transaction}
        />
      ))}
    </Table>
  );
}
