import { useTranslation } from 'react-i18next';
import LockIcon from '@/components/icons/LockIcon';
import IconButton from '../IconButton';
import styles from './IconStroke.module.scss';

export default function BlockButton({ onClick }: Readonly<{ onClick?: () => void }>): React.JSX.Element {
  const { t } = useTranslation('global');

  return (
    <IconButton
      icon={<LockIcon />}
      label={t('buttons.block')}
      className={styles.button}
      onClick={onClick}
      textTransform='uppercase'
    />
  );
}
