import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import WidgetBox from '../widget-box/WidgetBox';
import Table from '@/components/common/tables/Table';
import { getLimitsHeaders } from '@/utils/headers';
import { extraLightWhite } from '@/utils/colors';
import type { LimitConfig } from '@/pages/customer/limits/LimitsTab';
import Typography from '@/components/common/typography/Typography';
import styled from 'styled-components';

const StyledRowCellSecondary = styled.div`
  margin-left: 1.5rem;
`;

export interface LimitsProps {
  title: string;
  limits: LimitConfig[];
}

const LimitsWidget: React.FC<LimitsProps> = ({ title, limits }) => {
  const { t } = useTranslation('customerOverview');
  const limitsHeaders = useMemo(() => getLimitsHeaders(t), [t]);

  return (
    <WidgetBox
      label={t('widgets.limits.in-usd')}
      title={title}
    >
      <Table cols={5}>
        {limitsHeaders.map(header => (
          <Table.Head key={`key-${header.title}`}>{header.title}</Table.Head>
        ))}
        {limits.map(limit => (
          <Table.Row key={limit.title}>
            <Table.Cell style={{ backgroundColor: extraLightWhite.cssColor }}>
              {limit.isPrimary ? (
                <Typography variant='p2Bold'>{limit.title}</Typography>
              ) : (
                <StyledRowCellSecondary>{limit.title}</StyledRowCellSecondary>
              )}
            </Table.Cell>
            <Table.Cell>{limit.perTrn}</Table.Cell>
            <Table.Cell>{limit.daily}</Table.Cell>
            <Table.Cell>{limit.weekly}</Table.Cell>
            <Table.Cell>{limit.monthly}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
    </WidgetBox>
  );
};

export default LimitsWidget;
