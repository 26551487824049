import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { successToast, errorToast } from '@/utils/toastMessage';

interface WalletRefunds {
  amount: number;
  reason: string;
  transactionType: string;
  accountId: string;
}

export const walletRefunds = createAsyncThunk(
  'transaction/chargebacks',
  async ({ amount, reason, transactionType, accountId }: WalletRefunds, thunkApi) => {
    try {
      await authAxios.post('/wallet-refunds', { amount, reason, transactionType, accountId });
      successToast('toast-messages.manual-transaction');
    } catch (err: any) {
      errorToast();
      return thunkApi.rejectWithValue(err);
    }
  },
);
