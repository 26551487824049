import { type Record } from '@/components/common/layout/ColumnCard';
import ThreeColumnsWidget from '@/components/common/layout/ThreeColumnsWidget';
import { type PaymentCardInfo } from '@/types';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function PhysicalCardView({ card }: Readonly<{ card: PaymentCardInfo }>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');
  const colOne: Record[] = useMemo(
    () => [
      { label: t('widgets.cards.details.expected-delivery-label'), value: toUtcDateString(card.expectedDeliveryAt) },
    ],
    [card, t],
  );
  return (
    <ThreeColumnsWidget
      columnOne={colOne}
      columnTwo={[]}
      columnThree={[]}
    />
  );
}
