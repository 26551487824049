import { addCountryTranslation } from '@/lib/redux/slices/country-translation/actions';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Translation } from '@/types/Translation';

export interface CountryTranslationState {
  isLoading: boolean;
  translation?: Translation;
}

const initialState: CountryTranslationState = {
  isLoading: false,
};

export const countryTranslationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    resetTranslation() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(addCountryTranslation.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(addCountryTranslation.fulfilled, (state, action: PayloadAction<Translation>) => {
      state.isLoading = false;
      state.translation = action.payload;
    });
    builder.addCase(addCountryTranslation.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { resetTranslation } = countryTranslationSlice.actions;
