import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { type FeatureFlagHistory } from '@/types/FeatureFlag';

export const getFeatureFlagHistory = createAsyncThunk(
  'featureFlag.history',
  async ({ flagId }: { flagId: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<{ data: FeatureFlagHistory[] }>(
        `/feature-flags/audit-logs?flagId=${flagId}`,
      );
      return data.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
