import { marine } from '@/utils/colors';

export default function ExternalLinkIcon({
  width = `${18 / 16}rem`,
  height = `${18 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M8.05494 1.44H4.27494C2.70921 1.44 1.43994 2.70927 1.43994 4.27499V13.725C1.43994 15.2907 2.70921 16.56 4.27494 16.56H13.7249C15.2907 16.56 16.5599 15.2907 16.5599 13.725V9.94495M11.8345 1.44023L16.5599 1.44M16.5599 1.44V5.69259M16.5599 1.44L8.52675 9.47223'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}
