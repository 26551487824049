import { toTitleCase } from '@/utils/typography';
import LinkButton from '@/components/common/button/LinkButton';
import Table from '@/components/common/tables/Table';
import DocumentIcon from '@/components/icons/DocumentIcon';
import { type DocumentReview, Permissions } from '@/types';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import React from 'react';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';

export default function TableRow({ doc }: Readonly<{ doc: DocumentReview }>): React.JSX.Element {
  return (
    <Table.Row>
      <Table.Cell>{toUtcDateString(doc.createdOn)}</Table.Cell>
      <Table.Cell>{doc.customerId}</Table.Cell>
      <Table.Cell>{toTitleCase(doc.docStatus ?? 'Pending review')}</Table.Cell>
      <Table.Cell>{toTitleCase(doc.docType)}</Table.Cell>
      <Table.Cell>
        <LinkButton
          href={`/customer/${doc.customerId}`}
          target='_blank'
        >
          {doc.customerFullName}
        </LinkButton>
      </Table.Cell>
      <Table.Cell>
        <UserPermissionsGuard permission={Permissions.docQueueRead}>
          <LinkButton
            href={`/customer/${doc.customerId}/documents/review/identity-docs/${doc.identityDocId}`}
            target='_blank'
          >
            <DocumentIcon />
          </LinkButton>
        </UserPermissionsGuard>
      </Table.Cell>
    </Table.Row>
  );
}
