import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface AuthSliceState {
  name: string;
}

const initialState: AuthSliceState = {
  name: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setName(state: AuthSliceState, action: PayloadAction<string>) {
      state.name = action.payload;
    },
  },
});

export const { setName } = authSlice.actions;
