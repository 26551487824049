import { type Color } from '.';

const purple: Color = {
  class: 'text-purple',
  modifier: '--purple',
  cssColor: 'var(--color-primary-purple)',
};
const mediumPurple: Color = {
  class: 'text-medium-purple',
  modifier: '--medium-purple',
  cssColor: 'var(--color-purple-500)',
};

export { purple, mediumPurple };
