import { transactionAdjustment } from './action';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Adjustment } from '@/types';

interface TransactionAdjustmentState {
  adjustment?: Adjustment;
  isLoading: boolean;
  error?: string;
}

const initialState: TransactionAdjustmentState = {
  isLoading: true,
};

export const transactionAdjustmentSlice = createSlice({
  name: 'transactionAdjustment',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(transactionAdjustment.pending, state => {
      state.isLoading = true;
      state.adjustment = undefined;
    });

    builder.addCase(transactionAdjustment.fulfilled, (state, action: PayloadAction<Adjustment>) => {
      state.adjustment = action.payload;
      state.isLoading = false;
    });

    builder.addCase(transactionAdjustment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed adjustment details';
    });
  },
});

export const { reset } = transactionAdjustmentSlice.actions;
