import { createSlice } from '@reduxjs/toolkit';
import { loginAttempts } from './actions';

export interface LoginAttempts {
  loading: boolean;
  success: boolean;
}

const initialState: LoginAttempts = {
  loading: false,
  success: false,
};

export const loginAttemptsSlice = createSlice({
  name: 'loginAttempts',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(loginAttempts.pending, state => {
      state.loading = true;
    });
    builder.addCase(loginAttempts.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(loginAttempts.rejected, state => {
      state.loading = false;
      state.success = false;
    });
  },
});

export const { reset } = loginAttemptsSlice.actions;
