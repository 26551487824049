export enum Permissions {
  docQueueView = 'docqueue-overview-read',
  docQueueRead = 'docqueue-docs-read',
  docQueueAdmit = 'docqueue-docs-admit',
  kycriskDocsView = 'kycrisk-docs-view',
  kycriskOverview = 'kycrisk-overview-read',
  kycriskStatusEdit = 'kycrisk-status-edit',
  transactionsOverview = 'transactions-overview-read',
  transactionsChargebackEdit = 'transactions-chargeback-edit',
  transactionsWalletrefundEdit = 'transactions-walletrefund-edit',
  transactionsAdjustmentEdit = 'transactions-adjustment-edit',
  transactionsP2preversalEdit = 'transactions-p2preversal-edit',
  transactionsKaspmreversalEdit = 'transactions-kaspmreversal-edit',
  transactionsCardRefundEdit = 'transactions-cardrefund-edit',
  transactionsCardchargebackEdit = 'transactions-cardchargeback-edit',
  transactionsFeeReversalEdit = 'transactions-feereversal-edit',
  customerOverview = 'customer-overview-read',
  customerManage = 'customer-manage',
  customerPIEdit = 'customer-pidata-edit',
  businessAccountsRead = 'business-accounts-read',
  businessAccountsTxnAdjustments = 'business-accounts-manual-txn-adjustments',
  accessManagementRead = 'access-management-read',
  accessManagementEdit = 'access-management-edit',
  cardOverviewRead = 'card-overview-read',
  countryConfigRead = 'country-config-read',
  countryConfigEdit = 'country-config-edit',
  expireAuthEdit = 'expire-auth-edit',
}

export interface UserPermission {
  id: string;
  name: string;
  createdOn?: string;
  createdBy?: string;
  modifiedOn?: string;
  reason?: string;
  comment?: string;
  isDeleted: boolean;
}
