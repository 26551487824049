import { useEffect } from 'react';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { useParams } from 'react-router-dom';
import TransactionView from '@/components/customer/transaction-history/transaction-details/TransactionView';
import { fetchTransactionExchange } from '@/lib/redux/slices/transaction-exchange/actions';
import type { Exchange } from '@/types/Exchange';
import type { TransactionWithSlipId } from '@/components/customer/transaction-history/TransactionHistoryDetailWidget';
import Spinner from '@/components/common/spinners/Spinner';
import TransactionStatusStamp from '@/components/common/transaction-status-stamp/TransactionStatusStamp';
import { toUtcDateString } from '@/utils/dateTimeUtils';

const ExchangeView = ({ transaction }: { transaction: TransactionWithSlipId }): JSX.Element => {
  const { customerId } = useParams<{ customerId: string }>();
  const dispatch = useAppDispatch();

  const { exchange, isLoading } = useAppSelector((state: RootState) => state.transactionExchangeSlice);

  useEffect(() => {
    if (customerId) {
      void dispatch(
        fetchTransactionExchange({
          transactionId: transaction.transactionId,
          customerId: customerId ?? '',
        }),
      );
    }
  }, [dispatch, transaction, customerId]);

  function getAppliedRate(t?: Exchange): string | undefined {
    if (t === undefined) return undefined;

    const { sourceBalanceAmountCurrency, destinationBalanceAmountCurrency, appliedRate } = t;

    if (sourceBalanceAmountCurrency && destinationBalanceAmountCurrency && appliedRate) {
      return `${sourceBalanceAmountCurrency} 1 = ${destinationBalanceAmountCurrency} ${appliedRate}`;
    }
    return undefined;
  }

  const columnOne = [
    { label: 'transactionType', value: transaction?.transactionType, highlight: true },
    { label: 'status', value: <TransactionStatusStamp status={transaction.status} /> },
  ];

  const columnTwo = [
    { label: 'appliedRate', value: getAppliedRate(exchange) },
    {
      label: 'sourceBalanceAmount',
      value: `${exchange?.sourceBalanceAmountCurrency} ${exchange?.sourceBalanceAmount}`,
    },
  ];

  const columnThree = [
    { label: 'transactionId', value: transaction.transactionId },
    {
      label: 'destinationBalanceAmount',
      value: `${exchange?.destinationBalanceAmountCurrency} ${exchange?.destinationBalanceAmount}`,
    },
    { label: 'createdDate', value: transaction?.createdDate && toUtcDateString(transaction.createdDate) },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <TransactionView
      columnOne={columnOne}
      columnTwo={columnTwo}
      columnThree={columnThree}
    />
  );
};
export default ExchangeView;
