import authAxios from '@/auth/axios';
import { type User } from '@/types';
import { ServerErrors } from '@/utils/constants';
import { errorToast } from '@/utils/toastMessage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateUserModalView from './CreateUserModalView';
import { type RoleOption, useUserRoleOptions } from '@/utils/hooks/useUserRoleOptions';

interface Props {
  onSuccess: (newUser: User) => void;
  onClose: () => void;
}

export default function CreateUserModal({ onSuccess, onClose }: Readonly<Props>): React.JSX.Element {
  const { t } = useTranslation('accessManagement');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState('');
  const [roles, setRoles] = useState<RoleOption[]>([]);
  const roleOptions = useUserRoleOptions();

  const isCreateButtonDisabled =
    !name || !!nameError || !email || !!emailError || roles.length === 0 || !reason || !!reasonError;

  const validateName = (): boolean => {
    if (!name.trim()) {
      setNameError(
        t('tabs.users.new-user-modal.field-required-error', { field: t('tabs.users.new-user-modal.user-name-field') }),
      );
      return false;
    }

    const words = name.split(' ');
    if (!words.every(word => /^[A-Z0-9]/.test(word))) {
      setNameError(
        t('tabs.users.new-user-modal.invalid-field-format-error', {
          field: t('tabs.users.new-user-modal.user-name-field'),
        }),
      );
      return false;
    }

    return true;
  };

  const validateEmail = (): boolean => {
    if (!email.trim()) {
      setEmailError(
        t('tabs.users.new-user-modal.field-required-error', { field: t('tabs.users.new-user-modal.email-field') }),
      );
      return false;
    }
    if (!/^[\w\-.]+@(external\.)?payretailers\.com$/.test(email)) {
      setEmailError(
        t('tabs.users.new-user-modal.invalid-field-format-error', {
          field: t('tabs.users.new-user-modal.email-field'),
        }),
      );
      return false;
    }
    return true;
  };

  const validateReason = (): boolean => {
    if (!reason.trim()) {
      setReasonError(
        t('tabs.users.new-user-modal.field-required-error', { field: t('tabs.users.new-user-modal.reason-field') }),
      );
      return false;
    }
    return true;
  };

  const onSubmit = async (): Promise<void> => {
    if (!validateEmail() || !validateName() || !validateReason || roles.length === 0) {
      return;
    }
    try {
      const { data } = await authAxios.post<{ data: User }>('/boiam/users', {
        userName: name,
        email,
        reason,
        roles: roles.map(r => r.role),
      });
      onSuccess(data.data);
    } catch (error: any) {
      const errorsArr = error?.response?.data?.errors;
      if (
        Array.isArray(errorsArr) &&
        errorsArr.length === 1 &&
        errorsArr[0].errorMessage === ServerErrors.USER_ALREADY_EXISTS
      ) {
        setEmailError(t('tabs.users.new-user-modal.email-exists-error'));
        return;
      }
      errorToast();
    }
    onClose();
  };

  return (
    <CreateUserModalView
      email={email}
      emailError={emailError}
      isCreateButtonDisabled={isCreateButtonDisabled}
      name={name}
      nameError={nameError}
      onClose={onClose}
      onRolesChange={roles => setRoles(roles as RoleOption[])}
      onSubmit={() => {
        void onSubmit();
      }}
      reason={reason}
      roleOptions={roleOptions}
      selectedRoles={roles}
      setEmail={setEmail}
      setEmailError={setEmailError}
      setName={setName}
      setNameError={setNameError}
      setReason={setReason}
      validateEmail={validateEmail}
      validateName={validateName}
      reasonError={reasonError}
      setReasonError={setReasonError}
      validateReason={validateReason}
    />
  );
}
