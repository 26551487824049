import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { type Countries } from '@/types';

export const countries = createAsyncThunk('countries.countriesList', async () => {
  try {
    const { data } = await authAxios.get<{ data: Countries }>('countries');
    return data.data;
  } catch (err: any) {
    return err;
  }
});
