import { type ReactNode } from 'react';
import styled from 'styled-components';
import { ClickAwayListener } from '@mui/base';
import { lightShadeGrey, lightMarine } from '@/utils/colors';
const StyledUiWrapper = styled.div<{ $open: boolean; $singleChild: boolean }>`
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  position: relative;
  top: ${({ $singleChild }) => ($singleChild ? '-120%' : '-195%')};
  &::after {
    content: '';
    position: absolute;
    margin-top: ${({ $singleChild }) => ($singleChild ? '1.188rem' : '2.688rem')};
    transform: translateY(-50%);
    left: -0.563rem;
    width: 0;
    height: 0;
    border-top: 0.313rem solid transparent;
    border-bottom: 0.313rem solid transparent;
    border-left: 0.313rem solid ${lightMarine.cssColor};
  }
`;

const StyledMenuList = styled.ul`
  position: absolute;
  left: -250%;
  transform: translateX(-50%);
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 1rem;
  background-color: ${lightMarine.cssColor};
  box-shadow: 0 0.063rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  list-style-type: none;
  margin: 0;
`;

const StyledMenuItem = styled.li`
  display: flex;
  gap: 0.375rem;
  padding: 0.75rem 1rem;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    background-color: ${lightShadeGrey.cssColor};
  }
`;

const Menu = ({ children, onClose }: Readonly<{ children: ReactNode; onClose: () => void }>): JSX.Element => {
  return <ClickAwayListener onClickAway={onClose}>{<div>{children}</div>}</ClickAwayListener>;
};

const MenuList = ({
  children,
  open,
  singleChild,
}: Readonly<{ children: ReactNode; open: boolean; singleChild: boolean }>): JSX.Element => (
  <StyledUiWrapper
    $singleChild={singleChild}
    $open={open}
  >
    <StyledMenuList>{children}</StyledMenuList>
  </StyledUiWrapper>
);

const MenuItem = ({ children, onClick }: Readonly<{ children: ReactNode; onClick: () => void }>): JSX.Element => (
  <StyledMenuItem onClick={onClick}>{children}</StyledMenuItem>
);

Menu.List = MenuList;
Menu.Item = MenuItem;

export default Menu;
