import { type User } from '@/types';
import Table from '@/components/common/tables/Table';
import { useTranslation } from 'react-i18next';
import UserRow from './UserRow';

export default function UsersTable({
  users,
  currentPage,
}: Readonly<{ users: User[]; currentPage: number }>): React.JSX.Element {
  const { t } = useTranslation('accessManagement');

  return (
    <Table colsTemplate={'auto auto auto auto min-content'}>
      <Table.Head>{t('tabs.users.table.userName')}</Table.Head>
      <Table.Head>{t('tabs.users.table.email')}</Table.Head>
      <Table.Head>{t('tabs.users.table.roles')}</Table.Head>
      <Table.Head>{t('tabs.users.table.status')}</Table.Head>
      <Table.Head>{t('tabs.users.table.actions')}</Table.Head>
      {users.map(user => {
        return (
          <UserRow
            key={user.id}
            user={user}
            currentPage={currentPage}
          />
        );
      })}
    </Table>
  );
}
