import { marine } from '@/utils/colors';

function MenuIcon({
  width = `${35 / 16}rem`,
  height = `${35 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.0835 9.91669H26.9168'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M7.0835 17H26.9168'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M7.0835 24.0833H26.9168'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  );
}

export default MenuIcon;
