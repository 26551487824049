import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionStatus, TransactionType } from '@/types';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import {
  type ActionProps,
  TransactionActionsContext,
  TransactionActionType,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';
import ActionButton from '@/components/customer/transaction-history/transaction-actions/ActionButton';
import { useParams } from 'react-router-dom';
import { cardRefund, RefundTypes } from '@/lib/redux/slices/transaction-card-refund/actions';
import ActionInputReason from '@/components/customer/transaction-history/transaction-actions/ActionInputReason';
import { fetchCardFeeReversalActionEmptyResult } from '@/lib/redux/slices/transaction-actions/actions';
import { resetCardRefund } from '@/lib/redux/slices/transaction-card-refund/slice';

const CardFeeReversalAction: React.FC<ActionProps> = ({ onClose, transaction, setAccessibility }) => {
  const { selectedTransactionAction } = useContext(TransactionActionsContext);
  const { isCardFeeReversalEmpty } = useAppSelector((state: RootState) => state.transactionActionsSlice);
  const { success, isLoading } = useAppSelector((state: RootState) => state.cardRefund);
  const { t } = useTranslation(['customerOverview', 'global']);
  const dispatch = useAppDispatch();
  const { customerId } = useParams<{ customerId: string }>();

  const transactionType = transaction?.transactionType ?? '';

  const isCardFeeReversalAllowed =
    transaction?.transactionType === TransactionType.CardFee && transaction.status === TransactionStatus.Approved;

  useEffect(() => {
    if (
      transaction &&
      !selectedTransactionAction &&
      isCardFeeReversalEmpty === undefined &&
      customerId &&
      isCardFeeReversalAllowed
    ) {
      void dispatch(
        fetchCardFeeReversalActionEmptyResult({
          customerId,
          slipId: transaction.slipId ?? '',
        }),
      );
    } else if (!isCardFeeReversalEmpty) {
      setAccessibility(false);
    }
  }, [
    selectedTransactionAction,
    customerId,
    dispatch,
    transaction,
    transactionType,
    isCardFeeReversalAllowed,
    isCardFeeReversalEmpty,
    setAccessibility,
  ]);

  useEffect(() => {
    if (success) {
      void dispatch(resetCardRefund());
      onClose({ success: true });
    }
  }, [success, dispatch, onClose]);

  useEffect(() => {
    if (isCardFeeReversalEmpty !== undefined) {
      setAccessibility(isCardFeeReversalEmpty);
    }
    return () => {
      setAccessibility(undefined);
    };
  }, [isCardFeeReversalEmpty, setAccessibility]);

  const handleConfirm = (reason: string): void => {
    if (transaction?.slipId) {
      void dispatch(
        cardRefund({
          body: {
            customerId: customerId ?? '',
            transactionId: transaction.transactionId ?? '',
            amount: transaction.requestedAmount ?? 0,
            cardTransactionType: RefundTypes.FeeReversal,
            transactionReason: reason,
          },
        }),
      );
    }
  };

  if (selectedTransactionAction === undefined) {
    return (
      <ActionButton
        type={TransactionActionType.CardFeeReversal}
        hide={!(isCardFeeReversalEmpty && isCardFeeReversalAllowed)}
        label='Card Fee Reversal'
      />
    );
  }
  if (selectedTransactionAction === TransactionActionType.CardFeeReversal) {
    return (
      <ActionInputReason
        disabledButton={isLoading}
        onConfirm={handleConfirm}
        onClose={onClose}
        reasonLabel={t('widgets.transaction-history.modal-input-reason')}
      ></ActionInputReason>
    );
  }

  return null;
};

export default CardFeeReversalAction;
