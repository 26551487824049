import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type ChargebackReversal } from '@/types/ChargebackReversal';
import { fetchTransactionChargebackReversal } from '@/lib/redux/slices/transaction-chargeback-reversal/actions';

interface TransactionChargebackReversalState {
  isLoading: boolean;
  error?: string;
  chargebackReversal?: ChargebackReversal;
}

const initialState: TransactionChargebackReversalState = {
  isLoading: true,
};
export const transactionChargebackReversalSlice = createSlice({
  name: 'detailTransactionChargebackReversalSlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTransactionChargebackReversal.pending, state => {
      state.isLoading = true;
      state.error = undefined;
      state.chargebackReversal = undefined;
    });

    builder.addCase(
      fetchTransactionChargebackReversal.fulfilled,
      (state, action: PayloadAction<ChargebackReversal>) => {
        return {
          chargebackReversal: action.payload,
          isLoading: false,
          error: undefined,
        };
      },
    );

    builder.addCase(fetchTransactionChargebackReversal.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed to fetch chargebackReversal transaction';
    });
  },
});

export const { reset } = transactionChargebackReversalSlice.actions;
