import { type FeatureFlagHistory } from '@/types/FeatureFlag';
import { createSlice } from '@reduxjs/toolkit';
import { getFeatureFlagHistory } from './actions';

interface FeatureFlagHistoryState {
  history: FeatureFlagHistory[];
  isLoading: boolean;
}

const initialState: FeatureFlagHistoryState = {
  history: [],
  isLoading: true,
};

export const featureFlagHistorySlice = createSlice({
  name: 'featureFlagHistorySlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getFeatureFlagHistory.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getFeatureFlagHistory.fulfilled, (state, action) => {
      state.history = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getFeatureFlagHistory.rejected, state => {
      state.isLoading = false;
    });
  },
});

export default featureFlagHistorySlice.actions;
