function DartShadowIcon(): React.JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='79'
      height='8'
      viewBox='0 0 79 8'
      fill='none'
    >
      <path
        d='M39.8897 7.99982C61.4086 7.99982 78.8532 6.29285 78.8532 4.18718C78.8532 2.0815 61.4086 0.374512 39.8897 0.374512C18.3707 0.374512 0.926208 2.0815 0.926208 4.18718C0.926208 6.29285 18.3707 7.99982 39.8897 7.99982Z'
        fill='#BDF9F2'
      />
    </svg>
  );
}

export default DartShadowIcon;
