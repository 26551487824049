import { type Transaction, TransactionStatus, TransactionType } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosResponse } from 'axios';
import authAxios from '@/auth/axios';
import { errorToast } from '@/utils/toastMessage';

export interface TransactionActionsEmptyResultResponse {
  isEmpty: boolean;
}

interface TransactionActionsResponse {
  data: Transaction[];
}

interface TransactionHistoryRequest {
  customerId: string;
  slipId: string;
}

async function fetchTransactionsEmptyByType(
  transactionType: TransactionType,
  customerId: string,
  slipId: string,
  transactionStatus?: TransactionStatus,
): Promise<TransactionActionsEmptyResultResponse> {
  const response: AxiosResponse<TransactionActionsResponse> = await authAxios.post<TransactionActionsResponse>(
    '/transactions/search',
    {
      slipId,
      types: [transactionType],
      statuses: transactionStatus ? [transactionStatus] : undefined,
      page: 1,
      itemsPerPage: 10,
    },
    {
      headers: {
        'x-customer-id': customerId,
      },
    },
  );

  const data = response.data;
  return {
    isEmpty: data.data.length === 0,
  };
}

export const fetchReversalActionEmptyResult = createAsyncThunk<
  TransactionActionsEmptyResultResponse,
  TransactionHistoryRequest
>('transactionActions/fetchReversalActionEmptyResult', async ({ customerId, slipId }, thunkApi) => {
  try {
    return await fetchTransactionsEmptyByType(TransactionType.Reversal, customerId, slipId);
  } catch (err: any) {
    errorToast();
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchMerchantPaymentReversalActionEmptyResult = createAsyncThunk<
  TransactionActionsEmptyResultResponse,
  TransactionHistoryRequest
>('transactionActions/fetchMerchantPaymentReversalActionEmptyResult', async ({ customerId, slipId }, thunkApi) => {
  try {
    return await fetchTransactionsEmptyByType(TransactionType.MerchantPaymentReversal, customerId, slipId);
  } catch (err: any) {
    errorToast();
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchChargebackReversalActionEmptyResult = createAsyncThunk<
  TransactionActionsEmptyResultResponse,
  TransactionHistoryRequest
>('transactionActions/fetchChargebackReversalActionEmptyResult', async ({ customerId, slipId }, thunkApi) => {
  try {
    return await fetchTransactionsEmptyByType(TransactionType.ChargebackReversal, customerId, slipId);
  } catch (err: any) {
    errorToast();
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchChargebackActionEmptyResult = createAsyncThunk<
  TransactionActionsEmptyResultResponse,
  TransactionHistoryRequest
>('transactionActions/fetchChargebackActionEmptyResult', async ({ customerId, slipId }, thunkApi) => {
  try {
    return await fetchTransactionsEmptyByType(TransactionType.Chargeback, customerId, slipId);
  } catch (err: any) {
    errorToast();
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchExpiredCardActionEmptyResult = createAsyncThunk<
    TransactionActionsEmptyResultResponse,
    TransactionHistoryRequest
>('transactionActions/fetchExpiredCardActionEmptyResult', async ({ customerId, slipId }, thunkApi) => {
  try {
    return await fetchTransactionsEmptyByType(TransactionType.Card, customerId, slipId, TransactionStatus.Expired);
  } catch (err: any) {
    errorToast();
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchCardFeeReversalActionEmptyResult = createAsyncThunk<
  TransactionActionsEmptyResultResponse,
  TransactionHistoryRequest
>('transactionActions/fetchCardFeeReversalActionEmptyResult', async ({ customerId, slipId }, thunkApi) => {
  try {
    return await fetchTransactionsEmptyByType(TransactionType.CardFeeReversal, customerId, slipId);
  } catch (err: any) {
    errorToast();
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchCardFraudRefundActionEmptyResult = createAsyncThunk<
  TransactionActionsEmptyResultResponse,
  TransactionHistoryRequest
>('transactionActions/fetchCardFraudRefundActionEmptyResult', async ({ customerId, slipId }, thunkApi) => {
  try {
    return await fetchTransactionsEmptyByType(TransactionType.CardFraudRefund, customerId, slipId);
  } catch (err: any) {
    errorToast();
    return thunkApi.rejectWithValue(err);
  }
});
