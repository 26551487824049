export interface PaymentCardResponse {
  id: string;
  providerCardId: string;
  status: string;
  cardType: string;
  lastFour: string;
  createdAt: string;
  activatedAt: string;
  termsAndConditions: PaymentCardTermsAndConditions;
}

export interface PaymentCardsResponse {
  data: PaymentCardResponse[];
}

export enum PaymentCardStatus {
  Unset = 'UNSET',
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Closed = 'CLOSED',
}

export enum PaymentCardActions {
  Unset = 'Unset',
  Active = 'Active',
  BlockedByUser = 'BlockedByUser',
  BlockedByCs = 'BlockedByCs',
  BlockedByRisk = 'BlockedByRisk',
  CancelledByUser = 'CancelledByUser',
  CancelledByCs = 'CancelledByCs',
  CancelledByRisk = 'CancelledByRisk',
  Created = 'Created',
  Failed = 'Failed',
  NotRecognized = 'NotRecognized',
  CardDoesNotExist = 'CardDoesNotExist',
}
export interface PaymentCardInfo {
  id: string;
  providerCardId: string;
  status: PaymentCardStatus;
  cardType: string;
  lastFour: string;
  activatedAt: string;
  cardHolder?: string;
  expirationDate?: string;
  expectedDeliveryAt?: string;
  createdAt?: string;
  termsAndConditions?: PaymentCardTermsAndConditions;
}

export interface PaymentCardTermsAndConditions {
  createdAt: string;
  version: string;
}

export interface PaymentCardsInfo {
  customerId: string;
  physicalCard?: PaymentCardInfo;
  virtualCard?: PaymentCardInfo;
}
