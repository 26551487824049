import { transactionChargebacks } from './action';
import { createSlice } from '@reduxjs/toolkit';

interface TransactionChargebacksState {
  chargebacksLoading: boolean;
  chargebacksSuccess: boolean;
  error?: string;
}

const initialState: TransactionChargebacksState = {
  chargebacksLoading: false,
  chargebacksSuccess: false,
};

export const transactionChargebacksSlice = createSlice({
  name: 'transactionChargebacks',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(transactionChargebacks.pending, state => {
      state.chargebacksSuccess = false;
      state.chargebacksLoading = true;
    });

    builder.addCase(transactionChargebacks.fulfilled, state => {
      state.chargebacksSuccess = true;
      state.chargebacksLoading = false;
    });

    builder.addCase(transactionChargebacks.rejected, (state, action) => {
      state.chargebacksSuccess = false;
      state.chargebacksLoading = false;
      state.error = action.error?.message ?? 'Failed adjustment details';
    });
  },
});

export const { reset } = transactionChargebacksSlice.actions;
