import { useTranslation } from 'react-i18next';
import Table from '@/components/common/tables/Table';
import TransactionHistoryRow from './TransactionHistoryRow';

import { type Transaction } from '@/types';
import { type ClickOptions } from '@/utils/hooks/useMouseClicks';

export default function TransactionHistoryTable({
  transactions,
  onClick,
}: Readonly<{
  transactions: Transaction[];
  onClick: (transactionId: string, options?: ClickOptions) => void;
}>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');

  return (
    <Table colsTemplate='repeat(9, auto)'>
      <Table.Head>{t('widgets.transaction-history.created-on')}</Table.Head>
      <Table.Head>{t('widgets.transaction-history.updated-on')}</Table.Head>
      <Table.Head>{t('widgets.transaction-history.transaction-id')}</Table.Head>
      <Table.Head>{t('widgets.transaction-history.transaction-type')}</Table.Head>
      <Table.Head>{t('widgets.transaction-history.status')}</Table.Head>
      <Table.Head>{t('widgets.transaction-history.amount')}</Table.Head>
      <Table.Head>{t('widgets.transaction-history.payment-method')}</Table.Head>
      <Table.Head>{t('widgets.transaction-history.reason')}</Table.Head>
      <Table.Head>{t('widgets.transaction-history.slid-id')}</Table.Head>

      {transactions.map(transaction => (
        <TransactionHistoryRow
          onClick={onClick}
          key={transaction.transactionId}
          transaction={transaction}
        />
      ))}
    </Table>
  );
}
