import { marine } from '@/utils/colors';

export default function UploadIcon({
  width = `${24 / 16}rem`,
  height = `${24 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.71615 19.11C3.71615 19.4213 3.83982 19.7199 4.05996 19.94C4.2801 20.1602 4.57867 20.2839 4.88999 20.2839L19.1105 20.2839C19.1104 20.2839 19.1106 20.2839 19.1105 20.2839C19.4216 20.2836 19.7203 20.1599 19.9402 19.9399C20.1602 19.7197 20.2839 19.4212 20.2839 19.11L20.2839 15.0496C20.2839 14.5757 20.668 14.1915 21.1419 14.1915C21.6158 14.1915 22 14.5757 22 15.0496L22 19.11C22 19.8763 21.6957 20.6112 21.154 21.1531C20.6123 21.6951 19.8775 21.9997 19.1112 22L4.88999 22C4.12352 22 3.38844 21.6955 2.84646 21.1535C2.30448 20.6116 2 19.8765 2 19.11L2 15.0496C2 14.5757 2.38417 14.1915 2.85807 14.1915C3.33198 14.1915 3.71615 14.5757 3.71615 15.0496L3.71615 19.11Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.31431 8.54378C5.97921 8.20868 5.97921 7.66538 6.31431 7.33028L11.3933 2.25134C11.5542 2.09042 11.7724 2.00001 12 2.00001C12.2276 2.00001 12.4458 2.09042 12.6068 2.25134L17.6857 7.33028C18.0208 7.66538 18.0208 8.20868 17.6857 8.54378C17.3506 8.87888 16.8073 8.87888 16.4722 8.54378L12 4.07159L7.52781 8.54378C7.19271 8.87888 6.64941 8.87888 6.31431 8.54378Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.703 15.9935C11.2291 15.9935 10.845 15.6093 10.845 15.1354L10.845 3.12235C10.845 2.64845 11.2291 2.26428 11.703 2.26428C12.1769 2.26428 12.5611 2.64845 12.5611 3.12235L12.5611 15.1354C12.5611 15.6093 12.1769 15.9935 11.703 15.9935Z'
        fill={color}
      />
    </svg>
  );
}
