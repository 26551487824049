import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IdVerification.module.scss';
import Table from '@/components/common/tables/Table';

import TickIcon from '@/components/icons/TickIcon';
import { CustomerDocStatus, Permissions, type CustomerIdentityDoc } from '@/types';
import ExternalLinkIcon from '@/components/icons/ExternalLinkIcon';
import LinkButton from '@/components/common/button/LinkButton';
import { lightWhite } from '@/utils/colors';
import { toTitleCase } from '@/utils/typography';
import WidgetBox from '@/components/customer/widget-box/WidgetBox';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import Typography from '@/components/common/typography/Typography';

interface DocumentPresentation {
  docType: string;
  isUploaded: boolean;
  url?: string;
}

interface IdVerificationProps {
  identityDocs: CustomerIdentityDoc[];
  customerId: string;
  urlRoot: string;
}

const IdVerification: React.FC<IdVerificationProps> = ({ identityDocs, customerId, urlRoot }) => {
  const { t } = useTranslation('customerOverview');

  const data = useMemo((): [DocumentPresentation, DocumentPresentation] => {
    const idDocLabel = (type: string): string => `ID doc (${toTitleCase(type)})`;
    const selfieLabel = 'Selfie';

    let idDocument: DocumentPresentation = { docType: 'ID doc', isUploaded: false };
    let selfieImage: DocumentPresentation = { docType: selfieLabel, isUploaded: false };

    function isIdDocument(doc: CustomerIdentityDoc): boolean {
      return (
        doc.docStatus === CustomerDocStatus.APPROVED &&
        ((!!doc.frontImageId && doc.frontImageId.length > 0) || (!!doc.backImageId && doc.backImageId.length > 0))
      );
    }

    function isSelfieDocument(doc: CustomerIdentityDoc): boolean {
      return doc.docStatus === CustomerDocStatus.APPROVED && !!doc.selfieImageId && doc.selfieImageId.length > 0;
    }

    for (const document of identityDocs) {
      if (!idDocument.isUploaded && isIdDocument(document)) {
        idDocument = {
          docType: idDocLabel(document.docType),
          isUploaded: true,
          url: `/customer/${customerId}/documents/identity-docs/${document.identityDocId}`,
        };
      }

      if (!selfieImage.isUploaded && isSelfieDocument(document)) {
        selfieImage = {
          docType: selfieLabel,
          isUploaded: true,
          url: `/customer/${customerId}/documents/identity-docs/${document.identityDocId}/selfie`,
        };
      }

      if (idDocument.isUploaded && selfieImage.isUploaded) {
        break;
      }
    }

    return [idDocument, selfieImage];
  }, [identityDocs, customerId]);

  return (
    <WidgetBox title={t('widgets.id-verification.header-title')}>
      <Table colsTemplate='1fr 3.5rem 1fr'>
        <Table.Head>
          <div></div>
        </Table.Head>
        <Table.Head>
          <Typography variant='p2Medium'>{/* t('widgets.id-verification.timestamp-of-upload') */}</Typography>
        </Table.Head>
        <Table.Head>
          <div></div>
        </Table.Head>
        {data.map(document => (
          <Table.Row key={document.docType}>
            <Table.Cell>{document.docType}</Table.Cell>
            <Table.Cell>{document.isUploaded && <TickIcon></TickIcon>}</Table.Cell>
            <Table.Cell>
              <div className={styles.verification}>
                <UserPermissionsGuard permission={Permissions.kycriskDocsView}>
                  {document.url ? (
                    <LinkButton
                      href={urlRoot + document.url}
                      target='_blank'
                    >
                      <ExternalLinkIcon />
                    </LinkButton>
                  ) : (
                    <div className={styles['flex-box']}>
                      <ExternalLinkIcon color={lightWhite.cssColor} />
                    </div>
                  )}
                </UserPermissionsGuard>
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>
    </WidgetBox>
  );
};

export default IdVerification;
