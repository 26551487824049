import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@/components/common/typography/Typography';
import Button from '@/components/common/button/Button';
import styles from './EditCustomer.module.scss';
import Input from '@/components/common/inputs/Input';
import InputLabel from '@/components/common/labels/InputLabel';
import { type Customer } from '@/types';
import { updateCustomerDetails } from '@/lib/redux/slices/customer/actions';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { updateState } from '@/lib/redux/slices/customer/slice';
import { objectDeepEquality } from '@/utils/helperFunctions';

export interface EditCustomerProps {
  customer: Customer;
  onCancel: () => void;
}

const EditCustomer = (props: EditCustomerProps): JSX.Element => {
  const { onCancel, customer } = props;

  const dispatch = useAppDispatch();

  const { editLoading, success } = useAppSelector((state: RootState) => state.customer);

  const [customerEdit, setCustomerEdit] = useState<Partial<Customer>>({ ...customer });
  const [updatedCustomer, setUpdatedCustomer] = useState({});
  const { t } = useTranslation('customerOverview');

  const handleInputChange = (newVal: string, label: string): void => {
    setCustomerEdit(val => ({ ...val, [label]: newVal }));
    setUpdatedCustomer(val => ({ ...val, [label]: newVal }));
  };

  const onSave = (): void => {
    if (customer.customerId) {
      void dispatch(updateCustomerDetails({ customerId: customer.customerId, body: { ...updatedCustomer } }));
    }
  };

  useEffect(() => {
    if (success) {
      onCancel();
      dispatch(updateState({ ...customerEdit }));
    }
  }, [success, dispatch, customerEdit, onCancel]);

  const leftColumn = [
    {
      name: 'firstName',
      label: t('edit-customer.first-name'),
      value: customerEdit.firstName,
      focused: customerEdit.firstName !== customer.firstName,
    },
    {
      name: 'lastName',
      label: t('edit-customer.last-name'),
      value: customerEdit.lastName,
      focused: customerEdit.lastName !== customer.lastName,
    },
    {
      name: 'birthDate',
      label: t('edit-customer.date-of-birth'),
      value: customerEdit.birthDate,
      type: 'date',
      focused: customerEdit.birthDate !== customer.birthDate,
    },
    {
      name: 'email',
      label: t('edit-customer.email'),
      value: customerEdit.email,
      focused: customerEdit.email !== customer.email,
    },
    {
      name: 'fiscalNumber',
      label: t('edit-customer.fiscal-number'),
      value: customerEdit.fiscalNumber,
      focused: customerEdit.fiscalNumber !== customer.fiscalNumber,
    },
  ];

  const rightColumn = [
    {
      name: 'addressLine1',
      label: t('edit-customer.address-line-1'),
      value: customerEdit.addressLine1,
      focused: customerEdit.addressLine1 !== customer.addressLine1,
    },
    {
      name: 'addressLine2',
      label: t('edit-customer.address-line-2'),
      value: customerEdit.addressLine2,
      focused: customerEdit.addressLine2 !== customer.addressLine2,
    },
    {
      name: 'addressCity',
      label: t('edit-customer.city'),
      value: customerEdit.addressCity,
      focused: customerEdit.addressCity !== customer.addressCity,
    },
    {
      name: 'addressCountryCode',
      label: t('edit-customer.country'),
      value: customerEdit.addressCountryCode,
      focused: customerEdit.addressCountryCode !== customer.addressCountryCode,
    },
    {
      name: 'addressPostalCode',
      label: t('edit-customer.postal-code'),
      value: customerEdit.addressPostalCode,
      focused: customerEdit.addressPostalCode !== customer.addressPostalCode,
    },
  ];

  const isDisabled = objectDeepEquality(customerEdit, customer) || (editLoading !== undefined && editLoading);

  return (
    <div className={styles.content}>
      <div className={styles['content-box']}>
        <div className={styles['header-wrapper']}>
          <Typography variant='p1SemiBold'>{t('edit-customer.header-title')}</Typography>
          <div>
            <Button
              className={styles['cancel-button']}
              variant='secondary'
              onClick={onCancel}
              textTransform='uppercase'
            >
              {t('edit-customer.cancel-button')}
            </Button>
            <Button
              disabled={isDisabled}
              onClick={onSave}
              textTransform='uppercase'
            >
              {t('edit-customer.save-changes-button')}
            </Button>
          </div>
        </div>
        <div className={styles['left-column']}>
          <div className={styles['input-wrapper']}>
            {leftColumn.map(info => (
              <InputLabel
                className={styles.input}
                key={info.name}
                label={info.label}
              >
                <Input
                  type={info?.type}
                  value={typeof info.value === 'string' ? info.value : ''}
                  focused={info?.focused}
                  onChange={val => handleInputChange(val, info.name)}
                />
              </InputLabel>
            ))}
          </div>
          <div>
            {rightColumn.map(info => (
              <InputLabel
                className={styles.input}
                key={info.name}
                label={info.label}
              >
                <Input
                  value={typeof info.value === 'string' ? info.value : ''}
                  focused={info?.focused}
                  onChange={val => handleInputChange(val, info.name)}
                />
              </InputLabel>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomer;
