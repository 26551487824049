import ArrowIcon from '@/components/icons/ArrowIcon';
import classNames from 'classnames';
import styles from './DropdownArrow.module.scss';

export default function DropdownArrow({ opened }: Readonly<{ opened: boolean }>): React.JSX.Element {
  const arrowClasses = classNames(styles.arrow, {
    [styles['arrow--opened']]: opened,
  });
  return (
    <span className={arrowClasses}>
      <ArrowIcon />
    </span>
  );
}
