import { useEffect, useState } from 'react';
import Spinner from '../spinners/Spinner';
import authAxios from '@/auth/axios';
import type { AxiosRequestConfig } from 'axios';

export interface RemoteImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  url: string;
  config?: AxiosRequestConfig;
}

export default function RemoteImage({ url, config, ...rest }: Readonly<RemoteImageProps>): React.JSX.Element | null {
  const [imageData, setImageData] = useState<{ data: string; type: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImage = async (): Promise<void> => {
      setLoading(true);
      try {
        const { data } = await authAxios.get<{ data: { image: string; imageContentType: string } }>(url, config);
        setImageData({ data: data.data.image, type: data.data.imageContentType.toLowerCase() });
      } catch (_) {}
      setLoading(false);
    };
    void fetchImage();
  }, [url, config]);

  if (loading) {
    return <Spinner />;
  } else if (imageData !== undefined) {
    return (
      <img
        src={`data:image/${imageData.type};base64,${imageData.data}`}
        {...rest}
      />
    );
  } else {
    return null;
  }
}
