import authAxios from '@/auth/axios';
import type { PaymentCardInfo, PaymentCardResponse, PaymentCardsInfo, PaymentCardsResponse } from '@/types';
import { PaymentCardStatus } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorToast, successToast } from '@/utils/toastMessage';

interface UpdateCardProps {
  customerId: string;
  cardId: string;
  status: string;
  comment: string;
}

export const fetchPaymentCards = createAsyncThunk(
  'cards.fetchPaymentCards',
  async ({ customerId }: { customerId: string }, { rejectWithValue }) => {
    try {
      const response = await authAxios.get<PaymentCardsResponse>('/cards/cards?lastOfType=true', {
        headers: { 'x-customer-id': customerId },
      });
      const cardCustomerId = customerId;
      const paymentCards: PaymentCardsInfo = {
        customerId: cardCustomerId,
        physicalCard: getPaymentCardInfoFromResponse(getFirstCardOfType('Physical', response.data)),
        virtualCard: getPaymentCardInfoFromResponse(getFirstCardOfType('Virtual', response.data)),
      };
      return paymentCards;
    } catch (err: any) {
      return rejectWithValue(customerId);
    }
  },
);

export const updatePaymentCard = createAsyncThunk(
  'cards.updatePaymentCard',
  async ({ customerId, cardId, status, comment }: UpdateCardProps, { rejectWithValue }) => {
    try {
      await authAxios.patch(
        `/cards/cards/${cardId}`,
        { status, comment },
        { headers: { 'x-customer-id': customerId } },
      );
      successToast('toast-messages.card-status-change');
      return { status: getPaymentCardStatus(status), cardId };
    } catch (err: any) {
      errorToast();
      return rejectWithValue(customerId);
    }
  },
);

function getFirstCardOfType(cardType: string, response: PaymentCardsResponse): PaymentCardResponse | undefined {
  cardType = cardType.toLowerCase();
  return response.data.find(card => card.cardType.toLowerCase() === cardType) ?? undefined;
}

function getPaymentCardInfoFromResponse(response?: PaymentCardResponse): PaymentCardInfo | undefined {
  if (response === undefined) {
    return undefined;
  }

  const result: PaymentCardInfo = {
    id: response.id,
    providerCardId: response.providerCardId,
    status: getPaymentCardStatus(response.status),
    cardType: response.cardType,
    lastFour: response.lastFour,
    activatedAt: response.activatedAt,
    termsAndConditions: response.termsAndConditions,
  };

  return result;
}

// Possible response statuses as of 15.04.24:
// Unset, Active, BlockedByUser, BlockedByCs, BlockedByRisk, CancelledByUser, CancelledByCs, CancelledByRisk, Created, Failed, NotRecognized, CardDoesNotExist
function getPaymentCardStatus(responseStatus: string): PaymentCardStatus {
  const statusString = responseStatus.toLowerCase();

  if (statusString.includes('active')) {
    return PaymentCardStatus.Active;
  }

  if (statusString.includes('blocked')) {
    return PaymentCardStatus.Blocked;
  }

  if (statusString.includes('cancelled')) {
    return PaymentCardStatus.Cancelled;
  }

  if (statusString.includes('created')) {
    return PaymentCardStatus.Created;
  }

  if (statusString.includes('closed')) {
    return PaymentCardStatus.Closed;
  }

  return PaymentCardStatus.Unset;
}
