enum LimitType {
  Amount = 'Amount',
}

enum MoneyFlowType {
  MoneyIn = 'MoneyIn',
  MoneyOut = 'MoneyOut',
}

interface Limit {
  limitType: LimitType;
  moneyFlowType: MoneyFlowType;
  value: number;
}

interface LimitData {
  limits: Limit[];
  currency: string;
}

export interface LimitsResponse {
  data: LimitData;
}

export { LimitType, MoneyFlowType };
