import { useState } from 'react';
import classNames from 'classnames';
import { ClickAwayListener } from '@mui/base';
import DropdownOption from '../filters/DropdownOption';

import type { DisplayType, Option } from '@/utils/interfaces';
import { white, type Color } from '@/utils/colors';

import styles from './SearchDropdown.module.scss';
import DropdownArrow from '../dropdown-arrow/DropdownArrow';

function SearchDropdown({
  primaryColor = white,
  options,
  onSelect,
  selectedOption,
  setSelectedOption,
  displayType = 'block',
}: Readonly<{
  primaryColor?: Color;
  options: Option[];
  onSelect: () => void;
  selectedOption: Option;
  setSelectedOption: (selectedOption: Option) => void;
  displayType?: DisplayType;
}>): React.JSX.Element {
  const [opened, setOpened] = useState(false);

  const dropdownClasses = classNames(
    styles.dropdown__dropdown,
    styles[`dropdown__dropdown${primaryColor.modifier}`],
    styles[`dropdown__dropdown--${displayType}`],
    {
      [styles['dropdown__dropdown--active']]: opened,
    },
  );

  const selectedValueClasses = classNames(
    styles['dropdown__selected-value'],
    styles[`dropdown__selected-value${primaryColor.modifier}`],
  );

  const onOptionClick = (option: Option): void => {
    setSelectedOption(option);
    setOpened(!opened);
    onSelect();
  };

  return (
    <ClickAwayListener onClickAway={() => setOpened(false)}>
      <div className={styles.dropdown}>
        <button
          className={styles.dropdown__button}
          onClick={() => setOpened(!opened)}
        >
          <span className={selectedValueClasses}>{selectedOption.text}</span>
          <DropdownArrow opened={opened} />
        </button>
        <ul className={dropdownClasses}>
          {options.map(option => (
            <DropdownOption
              primaryColor={primaryColor}
              key={option.text}
              text={option.text}
              active={selectedOption.value === option.value}
              onOptionClick={() => onOptionClick(option)}
              displayType={displayType}
            />
          ))}
        </ul>
      </div>
    </ClickAwayListener>
  );
}

export default SearchDropdown;
