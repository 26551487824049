import { createAsyncThunk } from '@reduxjs/toolkit';
import { type RootState } from '@/lib/redux/store';
import type { CountriesEnum, CurrencyEnum, TransactionBA } from '@/types';
import authAxios from '@/auth/axios';

export interface TransactionHistoryBAResponse {
  transactions: TransactionBA[];
  page: number;
  count: number;
  itemsPerPage: number;
}

interface TransactionHistoryBARequest {
  page?: number;
  itemsPerPage: number;
  countryCode?: CountriesEnum;
  currencyCode?: CurrencyEnum;
  dateFrom?: string;
  dateTo?: string;
  phoneNumber?: string;
  transactionTypes?: string[];
  transactionStatus?: string[];
  transactionIds?: string[];
  pspTransactionId?: string;
  customerId?: string;
}

export const fetchTransactions = createAsyncThunk<TransactionHistoryBAResponse, TransactionHistoryBARequest>(
  'transactionHistoryBA/fetch',
  async (
    {
      page,
      itemsPerPage,
      dateFrom,
      dateTo,
      countryCode,
      currencyCode,
      phoneNumber,
      transactionTypes,
      transactionStatus,
      transactionIds,
      pspTransactionId,
      customerId,
    },
    thunkApi,
  ) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const currentPage = rootState.transactionHistoryBA.page;
      if (page === currentPage) {
        page = 1;
      }
      if (page === undefined) {
        page = rootState.transactionHistory.page;
      }

      const offset = (page - 1) * itemsPerPage;
      const response = await authAxios.post('/payments/search', {
        offset,
        limit: itemsPerPage,
        includeTotal: true,
        dateFrom,
        dateTo,
        countryCode,
        currencyCode,
        phoneNumber,
        transactionTypes,
        status: transactionStatus,
        transactionIds,
        pspTransactionId,
        customerId,
      });

      const data = response.data;

      return {
        transactions: data.data ?? [],
        count: data.total,
        page,
        itemsPerPage,
      };
    } catch (err: any) {
      return thunkApi.rejectWithValue(err);
    }
  },
);
