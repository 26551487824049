import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button<{ $height?: string; $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  padding: 0rem 0.781rem;
  border-radius: 1.25rem;
  height: ${({ $height }) => $height ?? '1.875rem'};
  align-items: center;
`;

interface StyledIconButtonProps {
  onClick: () => void;
  icon: React.ReactNode;
  disabled?: boolean;
  height?: string;
}

const StyledIconButton = ({ onClick, icon, disabled, height }: Readonly<StyledIconButtonProps>): JSX.Element => {
  return (
    <StyledButton
      $disabled={disabled}
      onClick={onClick}
      $height={height}
    >
      {icon}
    </StyledButton>
  );
};

export default StyledIconButton;
