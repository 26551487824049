import { useRef } from 'react';
import styled from 'styled-components';

import CopyIcon from '@/components/icons/CopyIcon';
import TickIcon from '@/components/icons/TickIcon';

interface CopyButtonProps {
  textToCopy?: string;
}

const CopyIconWrapper = styled.div`
  cursor: pointer;
`;

const TickIconWrapper = styled.div`
  display: none;
`;

export default function CopyButton({ textToCopy }: Readonly<CopyButtonProps>): React.JSX.Element {
  const copyIconWrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const tickIconWrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const onCopyClick = (data: string): void => {
    void navigator.clipboard.writeText(data);

    copyIconWrapperRef.current.style.animation = 'fade-out 0.5s';
    tickIconWrapperRef.current.style.animation = 'fade-in 0.5s';
    copyIconWrapperRef.current.style.display = 'none';
    tickIconWrapperRef.current.style.display = 'block';

    const timeout = setTimeout(() => {
      copyIconWrapperRef.current.style.animation = 'fade-in 1s';
      tickIconWrapperRef.current.style.animation = 'fade-out 1s';
      tickIconWrapperRef.current.style.display = 'none';
      copyIconWrapperRef.current.style.display = 'block';

      return () => clearTimeout(timeout);
    }, 1000);
  };

  return (
    <>
      {textToCopy && (
        <>
          <CopyIconWrapper
            onClick={() => onCopyClick(textToCopy)}
            ref={copyIconWrapperRef}
          >
            <CopyIcon height='17' />
          </CopyIconWrapper>
          <TickIconWrapper ref={tickIconWrapperRef}>
            <TickIcon height='17' />
          </TickIconWrapper>
        </>
      )}
    </>
  );
}
