import React from 'react';
import Pagination from '@/components/common/pagination/Pagination';
import { ItemsPerPageLabel } from '@/components/common/pagination/ItemsPerPageLabel';
import styles from './TablePagination.module.scss';
import ItemsPerPage from '../pagination/ItemsPerPage';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';

export interface TablePaginationProps {
  count: number;
  page: number;
  setPage: (page: number) => void;
}

const TablePagination: React.FC<TablePaginationProps> = ({ count, page, setPage }) => {
  const [itemsPerPage] = useItemsPerPage();
  return (
    <div className={styles['pagination-wrapper']}>
      <ItemsPerPageLabel
        count={count}
        itemsPerPage={itemsPerPage}
        page={page}
      />
      <Pagination
        setPage={setPage}
        count={Math.ceil(count / itemsPerPage)}
        currentPage={page}
      />
      <div className={styles.ipp}>
        <ItemsPerPage />
      </div>
    </div>
  );
};

export default TablePagination;
