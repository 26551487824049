import authAxios from '@/auth/axios';
import { type CustomerIdentityDoc } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchIdentityDocuments = createAsyncThunk(
  'fetch-id-docs',
  async ({ docsId, customerId, review }: { docsId: string; customerId: string; review?: boolean }, thunkApi) => {
    try {
      const { data } = await authAxios.get<{ data: CustomerIdentityDoc }>(
        `/identity-docs/${review ? 'review' : 'approved'}/${docsId}`,
        {
          headers: {
            'x-customer-id': customerId,
          },
        },
      );
      return data.data;
    } catch (err: any) {
      return thunkApi.rejectWithValue(err);
    }
  },
);
