import { marine } from '@/utils/colors';

export default function DocumentIcon({
  width = `${19 / 16}rem`,
  height = `${26 / 16}rem`,
  color = marine.cssColor,
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 19 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='k'>
        <g id='Grupo 4039'>
          <path
            id='Trazado 2858'
            d='M14.4029 0.501221H4.59705C3.37791 0.501484 2.20878 1.00808 1.34672 1.90961C0.484663 2.81114 0.000251862 4.03381 0 5.30877V20.6921C0.000251862 21.9671 0.484663 23.1897 1.34672 24.0913C2.20878 24.9928 3.37791 25.4994 4.59705 25.4997H14.4029C15.6221 25.4994 16.7912 24.9928 17.6533 24.0913C18.5153 23.1897 18.9997 21.9671 19 20.6921V5.30877C18.9997 4.03381 18.5153 2.81114 17.6533 1.90961C16.7912 1.00808 15.6221 0.501484 14.4029 0.501221ZM17.158 20.6921C17.1559 21.4556 16.865 22.1872 16.3488 22.7271C15.8326 23.267 15.133 23.5712 14.4029 23.5733H4.59705C3.867 23.5712 3.16741 23.267 2.65119 22.7271C2.13496 22.1872 1.84406 21.4556 1.84205 20.6921V5.30877C1.84406 4.54528 2.13496 3.81367 2.65119 3.2738C3.16741 2.73394 3.867 2.42971 4.59705 2.42762H14.4029C15.133 2.42971 15.8326 2.73394 16.3488 3.2738C16.865 3.81367 17.1559 4.54528 17.158 5.30877V20.6921Z'
            fill={color}
          />
          <path
            id='Trazado 2859'
            d='M6.06675 8.52174H8.91675C9.10572 8.52174 9.28694 8.44324 9.42056 8.3035C9.55418 8.16376 9.62925 7.97424 9.62925 7.77662C9.62925 7.579 9.55418 7.38947 9.42056 7.24974C9.28694 7.11 9.10572 7.03149 8.91675 7.03149H6.06675C5.87778 7.03149 5.69655 7.11 5.56293 7.24974C5.42932 7.38947 5.35425 7.579 5.35425 7.77662C5.35425 7.97424 5.42932 8.16376 5.56293 8.3035C5.69655 8.44324 5.87778 8.52174 6.06675 8.52174Z'
            fill={color}
          />
          <path
            id='Trazado 2860'
            d='M12.35 12.2554H6.65C6.46103 12.2554 6.27981 12.3339 6.14619 12.4736C6.01257 12.6134 5.9375 12.8029 5.9375 13.0005C5.9375 13.1981 6.01257 13.3876 6.14619 13.5274C6.27981 13.6671 6.46103 13.7456 6.65 13.7456H12.35C12.539 13.7456 12.7202 13.6671 12.8538 13.5274C12.9874 13.3876 13.0625 13.1981 13.0625 13.0005C13.0625 12.8029 12.9874 12.6134 12.8538 12.4736C12.7202 12.3339 12.539 12.2554 12.35 12.2554Z'
            fill={color}
          />
          <path
            id='Trazado 2861'
            d='M12.35 17.4792H6.65C6.46103 17.4792 6.27981 17.5578 6.14619 17.6975C6.01257 17.8372 5.9375 18.0268 5.9375 18.2244C5.9375 18.422 6.01257 18.6115 6.14619 18.7513C6.27981 18.891 6.46103 18.9695 6.65 18.9695H12.35C12.539 18.9695 12.7202 18.891 12.8538 18.7513C12.9874 18.6115 13.0625 18.422 13.0625 18.2244C13.0625 18.0268 12.9874 17.8372 12.8538 17.6975C12.7202 17.5578 12.539 17.4792 12.35 17.4792Z'
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
