import countries from 'i18n-iso-countries';
import enCountriesLocale from 'i18n-iso-countries/langs/en.json';
import esCountriesLocale from 'i18n-iso-countries/langs/es.json';
import ptCountriesLocale from 'i18n-iso-countries/langs/pt.json';

countries.registerLocale(enCountriesLocale);
countries.registerLocale(esCountriesLocale);
countries.registerLocale(ptCountriesLocale);

export default countries;
