/* eslint-disable @typescript-eslint/indent */
import { type ReactNode, useState, Fragment } from 'react';
import Menu from '@/components/common/menu/Menu';
import ThreeDotsIcon from '@/components/icons/ThreeDotsIcon';
import styled from 'styled-components';
import Typography from '@/components/common/typography/Typography';
import { purple, boldMarine, marine, mediumGrey, lightShadeGrey } from '@/utils/colors';
import HorizontalLine from '@/components/common/horizontal-line/HorizontalLine';

const StyledIconButton = styled.button<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  padding: 0rem 0.781rem;
  border-radius: 1.25rem;
  height: 1.875rem;
  align-items: center;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'inherit' : `${lightShadeGrey.cssColor}`)};
  }
`;

export interface MenuAction {
  label: string;
  action: () => void;
  icon?: ReactNode;
  disabled?: boolean;
}

const MoreMenu = ({ items, disabled }: Readonly<{ items: MenuAction[]; disabled?: boolean }>): JSX.Element => {
  const [openMenu, setOpenMenu] = useState(false);

  const [isFocused, setIsFocused] = useState(false);

  const handleCloseMenu = (): void => {
    setOpenMenu(false);
    setIsFocused(false);
  };

  const toggleMenu = (): void => {
    setOpenMenu(!openMenu);
    setIsFocused(!isFocused);
  };

  const handleItemAction = (action: () => void): void => {
    handleCloseMenu();
    action();
  };

  const singleChild = items?.length === 1;

  const handleIconColor = (): string => {
    if (isFocused) {
      return boldMarine.cssColor;
    }
    if (disabled) {
      return mediumGrey.cssColor;
    }
    return marine.cssColor;
  };

  return (
    <Menu onClose={handleCloseMenu}>
      <StyledIconButton
        disabled={disabled}
        onClick={toggleMenu}
      >
        <ThreeDotsIcon color={handleIconColor()} />
      </StyledIconButton>
      <Menu.List
        singleChild={singleChild}
        open={openMenu}
      >
        {openMenu
          ? items.map((item, index) => (
              <Fragment key={item.label}>
                {index > 0 && <HorizontalLine $size='medium' />}
                <Menu.Item onClick={() => handleItemAction(item.action)}>
                  {item?.icon}
                  <Typography
                    color={purple.cssColor}
                    variant='p3Medium'
                  >
                    {item.label}
                  </Typography>
                </Menu.Item>
              </Fragment>
            ))
          : null}
      </Menu.List>
    </Menu>
  );
};

export default MoreMenu;
